import { generateId } from "../myOrders/order-config/orderConfig";

// Columns filter listing data
export const waitReconciliationFilterColumns = [
  {
    enable: true,
    id: generateId(),
    value: "Order_id",
    name: "Order ID",
  },
  {
    enable: true,
    id: generateId(),
    name: "Shipment Details",
    value: "item.order.Itemname ",
  },
  {
    enable: true,
    id: generateId(),
    name: "MVikas Docket No.",
    value: "item.order.MvikasDocketNo",
  },
  {
    enable: true,
    id: generateId(),
    value: "articles",
    name: "No. of Boxes",
  },
  {
    enable: true,
    id: generateId(),
    name: "Charged Weight",
    value: "chargable_weight",
  },
  {
    enable: true,
    id: generateId(),
    value: "excess_weight",
    name: "Excess Weight",
  },
  {
    enable: true,
    id: generateId(),
    name: "Service Applied",
    value: "item?.service?.name",
  },
  {
    enable: true,
    id: generateId(),
    name: "New Service Applied",
    value: "item?.serviceNew?.name",
  },
  {
    enable: true,
    id: generateId(),
    value: "new_weight",
    name: "New Applied Weight",
  },
  {
    enable: true,
    id: generateId(),
    value: "totalAmount",
    name: "Total Amount",
  },
  {
    enable: true,
    id: generateId(),
    value: "updatedAt",
    name: "Status Updated On",
  },
  {
    enable: true,
    value: "status",
    name: "Status",
    id: generateId(),
  },
  {
    enable: false,
    name: "Remark",
    value: "remark",
    id: generateId(),
  },

  {
    enable: false,
    name: "Action",
    id: generateId(),
    value: "additionalCharges",
  },
].filter((column) => column.enable);
