import { downloadIcon } from "assets/icons";
import moment from "moment";
import { FaArrowUp } from "react-icons/fa6";

// filterOperators dropdown
export const filterOperatorDropdown = [
  { id: 200, name: "Contains", value: "contains" },
  { id: 201, name: "Equals", value: "equals" },
  { id: 202, name: "Starts With", value: "starts with" },
  { id: 203, name: "Ends With", value: "ends with" },
  { id: 204, name: "Is Empty", value: "is empty" },
  { id: 205, name: "Is Not Empty", value: "is not empty" },
  { id: 206, name: "Is Any Of", value: "is any of" },
];


// paymentListing Filter Column DropDown
export const paymentListingFilterColumns = [
  {
    name: "Type",
    enable: true,
    value: "paymentType",
  },
  {
    enable: true,
    value: "createdAt",
    name: "Payment Date",
  },
  {
    enable: true,
    value: "paymentTo",
    name: "Payment to",
  },
  {
    enable: true,
    value: "serviceFrom",
    name: "Service from",
    actionKey: "Vendor?.name",
  },
  {
    enable: true,
    value: "taxableAmount",
    name: "Taxable amount",
  },
  {
    enable: true,
    value: "totalAmount",
    name: "Total amount",
  },
  {
    enable: true,
    value: "status",
    name: "Status",
  },
  {
    value: "id",
    enable: false,
    name: "Download",
  },
].filter((column) => column.enable);
