import {
  bookingsIcon,
  creditCardIcon,
  dashboardIcon,
  gpsIcon,
  invoiceIcon,
  myOrdersIcon,
  settingIcon,
} from "../assets/icons";

import CryptoJS from "crypto-js";

// Function for set data in to local
export const setDataToLocal = (key, data) => {
  const fineData = JSON.stringify(data);
  return localStorage.setItem(key, fineData);
};

// Function for get data from local
export const getDataToLocal = (key) => {
  const data = localStorage.getItem(key);
  const fineData = JSON.parse(data);
  return fineData;
};

// Side nav bar data
export const sideNavData = [
  {
    id: 101,
    isEnabled: true,
    title: "Dashboard",
    icon: dashboardIcon,
    itemClass: "menu_item",
    route: "/user/dashboard/home",
  },
  {
    id: 102,
    isEnabled: true,
    icon: bookingsIcon,
    title: "Book Orders",
    itemClass: "menu_item",
    route: "/user/dashboard/book-orders",
  },
  {
    id: 103,
    isEnabled: true,
    title: "My Orders",
    icon: myOrdersIcon,
    itemClass: "menu_item",
    route: "/user/dashboard/my-orders",
  },
  {
    id: 108,
    isEnabled: true,
    title: "Weight Discrepancies",
    icon: invoiceIcon,
    itemClass: "menu_item",
    route: "/user/dashboard/Weight-Discrepancies",
  },
  {
    id: 104,
    isEnabled: true,
    title: "Payment",
    icon: creditCardIcon,
    itemClass: "menu_item",
    route: "/user/dashboard/payments",
  },
  {
    id: 105,
    isEnabled: true,
    title: "Invoices",
    icon: invoiceIcon,
    itemClass: "menu_item",
    route: "/user/dashboard/invoice",
  },
  {
    id: 106,
    isEnabled: true,
    title: "Settings",
    icon: settingIcon,
    itemClass: "menu_item",
    route: "/user/dashboard/settings",
  },
  {
    id: 107,
    icon: gpsIcon,
    isEnabled: true,
    itemClass: "menu_item",
    title: "Track Your Order",
    route: "/user/dashboard/track-order",
  },
].filter((nav) => nav?.isEnabled);

// Generate a 256-bit (32 bytes) secret key
const generateSecretKey = () => {
  const array = new Uint8Array(32); // 32 bytes = 256 bits
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
};

// data encrypted Handler
export const encryptDataHandler = (data) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
};

export const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(
    encryptedData,
    process.env.REACT_APP_SECRET_KEY
  );
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
