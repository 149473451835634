import { calendarIcon, gpsIcon } from "assets/icons";
import { Button, Select } from "components/form";
import React, { useEffect, useState } from "react";
import "../../../userDashboard/pages/trackOrder/TrackOrder.scss";
import { FaRegCircleDot } from "react-icons/fa6";
import { GoCheckCircleFill } from "react-icons/go";
import { getOrderDetailsForTrackAPI } from "services/user-controllers/order-controller";
import { toast } from "react-toastify";
import moment from "moment";
import { getOrderDetailsForTrackHomeAPI } from "services/user-controllers/masterController";

const TrackOrderHome = ({ trackResp }) => {
  // Function for generate random Id
  const generateId = () => Math.floor(Math.random() * 10000);

  const statusArray = [
    {
      statusId: 1,
      id: generateId(),
      statusName: "Booked",
      statusLabel: "Your Order is Booked",
    },
    {
      statusId: 2,
      id: generateId(),
      statusName: "PickedUp",
      statusLabel: "Your order is pickedup",
    },
    {
      statusId: 3,
      id: generateId(),
      statusName: "InTransit",
      statusLabel: "Your order is In Transit",
    },
    {
      statusId: 4,
      id: generateId(),
      statusName: "Delivered",
      statusLabel: "Your order is Delivered",
    },
    {
      statusId: 5,
      id: generateId(),
      statusName: "Cancelled",
      statusLabel: "Your order is Cancelled",
    },
    {
      statusId: 6,
      id: generateId(),
      statusName: "RTO",
      statusLabel: "Your order is RTO",
    },
  ];

  //************************* Handle all state *************************//

  const [orderNumber, setOrderNumber] = useState("");
  const [orderDetails, setOrderDetails] = useState({});
  const [orderItemList, setOrderItemList] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [selectedItemDetails, setSelectedItemDetails] = useState({});

  const [remarkArray, setRemarkArray] = useState([]);

  //************************* Handle all function *************************//
  // get orderAndItemDetailsHandler
  const orderAndItemDetailsHandler = async () => {
    try {
      const { data } = await getOrderDetailsForTrackHomeAPI(orderNumber);
      if (data.code === 200) {
        setOrderItemList(data?.data?.list);
        setOrderDetails(data?.data?.details);
        setTotalRecordCount(data?.data?.count);
        let orderItemStatusData = {
          statusId: data?.data?.list[0]?.Itemstatus?.id,
          statusName: data?.data?.list[0]?.Itemstatus?.name,
          statusArray: data?.data?.list[0]?.orderedItemsStatus,
        };
        setSelectedItemDetails(orderItemStatusData);
      } else {
        toast.error(data?.message);
      }
      console.log("I AM ORDER DETAILS DATA", data);
    } catch (err) {
      console.error(err);
    }
  };

  // conditional rendering of status data
  const statusDataHandlerBasedOnStatus = (statusType) => {
    const statusObj =
      selectedItemDetails?.statusArray?.find(
        (el) => el?.StatusType === statusType
      ) || {};
    if (statusObj?.ItemId?.length > 0) {
      return (
        <>
          <p>
            <img src={calendarIcon} alt="" />
            {moment(statusObj?.updatedAt).format("DD MMM, YYYY")}
          </p>
          <strong>Remarks</strong>
          {statusObj?.statusRemark?.map((el) => (
            <span key={el?.id} className="list">
              {el?.remark || "-----"}
            </span>
          ))}
        </>
      );
    } else {
      return (
        <>
          {/* <p>
            <img src={calendarIcon} alt="" /> 22 Mar,2024
            {moment(statusObj?.updatedAt).format("DD MMM, YYYY")}
          </p>
          <strong>Remarks</strong>
          <span className="list">{statusObj?.ExcelRemarks}</span> */}
        </>
      );
    }
  };

  // onChange Handler for item select
  const orderItemOnChangeHandler = (e) => {
    const { value } = e.target;
    console.log("I AM VALUE", value);
    const findOrderItemObj = orderItemList.find((el) => el?.Itemid === value);
    if (findOrderItemObj?.Itemid?.length > 0) {
      let orderItemStatusData = {
        statusId: findOrderItemObj?.Itemstatus?.id,
        statusName: findOrderItemObj?.Itemstatus?.name,
        statusArray: findOrderItemObj?.orderedItemsStatus,
      };
      setSelectedItemDetails(orderItemStatusData);
    }
  };

  // userEffect for update States or track order details
  useEffect(() => {
    if (trackResp.code === 200) {
      const statusRemarkArray = trackResp?.data?.list?.map((el) => {
        return el?.orderedItemsStatus;
      });
      setRemarkArray(statusRemarkArray);

      setOrderItemList(trackResp?.data?.list);
      setOrderDetails(trackResp?.data?.details);
      setTotalRecordCount(trackResp?.data?.count);
      let orderItemStatusData = {
        statusId: trackResp?.data?.list[0]?.Itemstatus?.id,
        statusName: trackResp?.data?.list[0]?.Itemstatus?.name,
        statusArray: trackResp?.data?.list[0]?.orderedItemsStatus,
      };
      setSelectedItemDetails(orderItemStatusData);
    } else {
      toast.error(trackResp?.message);
    }
  }, [trackResp]);

  console.log("REMARK ARRAY =====>>>>>>>>>>>>>", remarkArray);

  //************************* Handle HTML CODE *************************//
  return (
    <>
      <div className="track_order">
        {/* <div class="dashboard_title">
          <h4>Track Your Order</h4>
        </div> */}

        {/* <div className="track_order_wrap">
          <div className="search_wrap">
            <img src={gpsIcon} alt="" />
            <input
              type="search"
              value={orderNumber}
              className="input form-control"
              placeholder="Enter Order Number"
              onChange={(e) => setOrderNumber(e?.target?.value)}
            />

            <Button onClick={orderAndItemDetailsHandler}>Search Now</Button>
          </div>
        </div> */}

        {/*-------------------- Order Details and Status Details ------------------*/}
        {totalRecordCount > 0 ? (
          <>
            <div className="order_detail _page">
              <ul>
                <li>
                  <h4>Order ID: {orderNumber || orderDetails?.orderId}</h4>
                  <h4>Current Status: {orderDetails?.status || "-----"}</h4>
                </li>
                <li>
                  <span>Total Box: {totalRecordCount || 0}</span>
                  <span>
                    Last Update :
                    {moment(orderDetails?.updatedAt).format("MMM DD, YYYY") ||
                      "------"}
                  </span>
                </li>
                <li>
                  <span>
                    Booked on :{" "}
                    {moment(orderDetails?.createdAt).format("MMM DD, YYYY") ||
                      "------"}
                  </span>
                  <span>
                    ETA :{" "}
                    {moment(orderDetails?.ETA).format("MMM DD, YYYY") ||
                      "------"}
                  </span>
                </li>
              </ul>
            </div>

            <div className="order_status _page">
              <div className="has_child">
                <h4>Track Order</h4>
                <Select onChange={orderItemOnChangeHandler}>
                  {orderItemList?.length > 0 &&
                    orderItemList?.map((el) => (
                      <option key={el?.id} value={el?.Itemid}>
                        {el?.Itemid}
                      </option>
                    ))}
                </Select>
              </div>

              <div className="status_progress">
                <ul>
                  {statusArray?.length > 0 &&
                    statusArray.map((el) => (
                      <li
                        key={el.id}
                        className={
                          el.statusId <= selectedItemDetails?.statusId
                            ? "active"
                            : ""
                        }
                      >
                        <span className="icon">
                          <FaRegCircleDot className="onGoing" />
                          <GoCheckCircleFill className="completed" />
                        </span>
                        <b>{el?.statusLabel || "-----"}</b>
                        {statusDataHandlerBasedOnStatus(el?.statusId)}
                      </li>
                    ))}

                  {/* <li className="active">
                    <span className="icon">
                      <FaRegCircleDot className="onGoing" />
                      <GoCheckCircleFill className="completed" />
                    </span>
                    <b>Your order is pickedup</b>
                    <p>
                      <img src={calendarIcon} alt="" /> 22 Mar,2024
                    </p>
                    <strong>Remarks</strong>
                    <span className="list">1. Booked</span>
                  </li>

                  <li>
                    <span className="icon">
                      <FaRegCircleDot className="onGoing" />
                      <GoCheckCircleFill className="completed" />
                    </span>
                    <b>Your order is In Transit</b>
                    <p>
                      <img src={calendarIcon} alt="" /> 22 Mar,2024
                    </p>
                    <strong>Remarks</strong>
                    <span className="list">1. Internist to Navsari</span>
                    <span className="list">2. Shipment Crossed Jaipur</span>
                  </li>

                  <li>
                    <span className="icon">
                      <FaRegCircleDot className="onGoing" />
                      <GoCheckCircleFill className="completed" />
                    </span>
                    <b>Your order is Delivered</b>
                    <p>
                      <img src={calendarIcon} alt="" /> 22 Mar,2024
                    </p>
                    <strong>Remarks</strong>
                    <span className="list">1. Delivered : 26-03-2024</span>
                  </li>

                  <li>
                    <span className="icon">
                      <FaRegCircleDot className="onGoing" />
                      <GoCheckCircleFill className="completed" />
                    </span>
                    <b>Your order is Cancelled</b>
                  </li>

                  <li>
                    <span className="icon">
                      <FaRegCircleDot className="onGoing" />
                      <GoCheckCircleFill className="completed" />
                    </span>
                    <b>Your order is RTO</b>
                  </li> */}
                </ul>
              </div>
            </div>
          </>
        ) : (
          <h4>Search with order number</h4>
        )}
        {/*-------------------- Close Order Details and Status Details ------------------*/}
      </div>
    </>
  );
};

export default TrackOrderHome;
