import React from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";

const TableHead = ({ columnsArray, ...props }) => {
  return (
    <thead>
      <tr>
        {columnsArray?.length > 0 &&
          columnsArray?.map(
            (col) =>
              !col.isHidden && (
                <>
                  <th key={col.key} className="nowrap">
                    {col.name}
                    <button
                      type="button"
                      onClick={() => props.sortHandler(col.key, col)}
                    >
                      {!col?.sort ? (
                        ""
                      ) : col?.sort === "DESC" ? (
                        <FaArrowUp />
                      ) : (
                        <FaArrowDown />
                      )}
                    </button>
                  </th>
                </>
              )
          )}
      </tr>
    </thead>
  );
};

export default TableHead;
