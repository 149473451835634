import { NonotificationIcon } from "assets/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoNotificationsOffSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { getNotificationAPI } from "services/user-controllers/masterController";

const NotificationList = () => {
  //****************************** Handle all state ***************************//

  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  //**************************** Handle all function **************************//
  // get notification list handler
  const getNotificationListHandler = async () => {
    try {
      const { data } = await getNotificationAPI();
      if (data?.code === 200) {
        setNotificationList(data?.data?.list);
        // setNotificationCount(data?.data?.count);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect for get notificationlist
  useEffect(() => {
    getNotificationListHandler();

    // eslint-disable-next-line
  }, []);

  //****************************** Handle HTML CODE ***************************//

  return (
    <>
      <h3>Notification</h3>

      {/* <div className="notification_wrap">
        <div className="_notification">
          <p>
            <span className="tag tag_new">New</span> <span>June 28, 2024</span>
          </p>
          <h5>Important Notice: "Not Found" to Be Upgraded to "Pending"</h5>
          <p>Dear customer,</p>
          <p>
            <b>if you signed up before May 17, 2024, please note:</b>
          </p>

          <p>
            We've noticed that our previous "Not Found" status, indicating "No
            tracking information yet," may result from various issues, such as
            incorrect tracking numbers, delays in scanning by carriers, or
            mismatches with carrier information. Some of them require your
            special attention, while others do not.
          </p>
        </div>
      </div> */}

      {notificationCount > 0 ? (
        notificationList?.map((item) => (
          <div className="notification_wrap">
            <div className="_notification">
              <p>
                {!item?.isRead && <span className="tag tag_new">New</span>}
                <span>{moment(item?.createdAt).format("DD MMM, YYYY")}</span>
              </p>
              <h5>{item?.title || "----"}</h5>
              {/* <p>Dear customer,</p>
              <p>
                <b>if you signed up before May 17, 2024, please note:</b>
              </p> */}

              <p>{item?.message || "----"}</p>
            </div>
          </div>
        ))
      ) : (
        <div className="wrap_no_notification">
          <figure>
              <img src={NonotificationIcon} alt="" />
              <p>No Notification</p>
          </figure>
          
        </div>
      )}
    </>
  );
};

export default NotificationList;
