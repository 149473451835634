import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CommonSelect,
  DatePicker,
  FileUpload,
  Input,
  Select,
} from "../../../../../../components/form";
import { GlobalOrderContext } from "../../../../../../context/OrderContext";
import { getDataToLocal, setDataToLocal } from "../../../../../../config/utils";
import moment from "moment";

const ProductDetails = ({ prev, next }) => {
  const itemCategoryArray = [
    { value: "electronics", label: "Electronics" },
    { value: "industrial goods", label: "Industrial Goods" },
    { value: "chemicals", label: "Chemicals" },
    { value: "Others", label: "Others" },
  ];

  const itemTypeArray = [
    { value: "Item-packaging type", label: "Item-packaging type" },
    { value: "Wooden Boxes", label: "Wooden Boxes" },
    { value: "Steel/Fiber/Plastic Drum", label: "Steel/Fiber/Plastic Drum" },
    { value: "Cartoon", label: "Cartoon" },
    { value: "Corrugated Cartoon Box", label: "Corrugated Cartoon Box" },
    { value: "Gunny Bag", label: "Gunny Bag" },
    { value: "Paperboard Box", label: "Paperboard Box" },
    { value: "Bubble Wrap", label: "Bubble Wrap" },
    { value: "Steel and Tin Cans", label: "Steel and Tin Cans" },
    { value: "Thermocol Box", label: "Thermocol Box" },
  ];

  //************************ Handle all state ***********************//
  const {
    vendorDetail,
    setOrderDetailsForm,
    orderDetailsForm,
    orderSearchFieldData,
  } = useContext(GlobalOrderContext);

  console.log("ORDER SEARCH DATA----", orderSearchFieldData);

  const [inVoiceFile, setInVoiceFile] = useState();
  const [AddNewAddress, setAddNewAddress] = useState();
  const [eWayBillExpiry, setEWayBillExpiry] = useState("");
  const [selectedItemType, setSelectedItemType] = useState([]);
  const [selectedItemCategory, setSelectedItemCategory] = useState([]);
  const [orderDetailsFormFields, setOrderDetailsFormFields] = useState({
    EWayBillNo: "",
    EWayBillExpDate: "", //2024-09-30
    ItemCategory: "",
    ItemType: "",
    iteminvoice: "",
    Itemname: "",
    OtherInfromation: "",
    invoiceImage: "",
  });

  const [
    orderDetailsFormFieldsValidationError,
    setOrderDetailsFormFieldsValidationError,
  ] = useState({
    ItemCategory: "",
    Itemname: "",
    ItemType: "",
    iteminvoice: "",
    invoiceImage: "",
    EWayBillNo: "",
    EWayBillExpDate: "",
  });

  //************************ Handle all functions ***********************//

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setOrderDetailsFormFields({ ...orderDetailsFormFields, [name]: value });
  };

  // fileOnChangeHandler
  const fileOnChangeHandler = (e) => {
    const file = e.target.files[0];
    setInVoiceFile(file);
    console.log("I am file", file);
  };

  // errorHandler
  const errorHandler = () => {
    let errorField = {};
    let formIsValid = true;

    if (selectedItemCategory?.length === 0) {
      formIsValid = false;
      errorField["ItemCategory"] = "Please Select Item Category*";
      setOrderDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!orderDetailsFormFields.Itemname) {
      formIsValid = false;
      errorField["Itemname"] = "Please Enter Item's Name*";
      setOrderDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (selectedItemType?.length === 0) {
      formIsValid = false;
      errorField["ItemType"] = "Please Select Item's Type*";
      setOrderDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!orderDetailsFormFields.iteminvoice) {
      formIsValid = false;
      errorField["iteminvoice"] = "Please Enter Invoice Number*";
      setOrderDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (
      orderSearchFieldData?.shipmentAmount > 50000 &&
      !orderDetailsFormFields.EWayBillNo
    ) {
      formIsValid = false;
      errorField["EWayBillNo"] = "Please Enter E-way Bill Number*";
      setOrderDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (orderSearchFieldData?.shipmentAmount > 50000 && !eWayBillExpiry) {
      formIsValid = false;
      errorField["EWayBillExpDate"] = "Please Enter E-way Bill Expiry Date*";
      setOrderDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    // if (!inVoiceFile) {
    //   formIsValid = false;
    //   errorField["invoiceImage"] = "Please Choose Invoice Image*";
    //   setOrderDetailsFormFieldsValidationError(errorField);
    //   return formIsValid;
    // }

    setOrderDetailsFormFieldsValidationError(errorField);
    return formIsValid;
  };

  // onSubmitHandler
  const onSubmitHandler = () => {
    if (errorHandler()) {
      // let base64String;

      // const reader = new FileReader();
      // reader.readAsDataURL(inVoiceFile);
      // reader.onloadend = function () {
      //   base64String = reader.result;
      // };

      const orderData = {
        ...orderDetailsFormFields,
        ItemCategory: selectedItemCategory,
        ItemType: selectedItemType,
        EWayBillExpDate: !eWayBillExpiry
          ? ""
          : moment(eWayBillExpiry).format("YYYY-MM-DD"),
        invoiceImage: inVoiceFile,
      };

      console.log("I AM ORDER DATA FROM SUBMIT HANDLER", orderData);

      const dataForGlobalState = {
        ...orderDetailsForm,
        orderDetailsFormData: { ...orderData },
      };
      setOrderDetailsForm(dataForGlobalState);
      next();
      // setDataToLocal("orderFormFields", { ...dataForGlobalState });
    }
  };

  // useEffect for setPickUpDetails form value if got
  useEffect(() => {
    if (orderDetailsForm?.orderDetailsFormData) {
      setOrderDetailsFormFields({ ...orderDetailsForm?.orderDetailsFormData });
      setSelectedItemCategory(
        orderDetailsForm?.orderDetailsFormData?.ItemCategory
      );
      setSelectedItemType(orderDetailsForm?.orderDetailsFormData?.ItemType);
      setEWayBillExpiry(
        orderDetailsForm?.orderDetailsFormData?.EWayBillExpDate
      );

      setInVoiceFile(orderDetailsForm?.orderDetailsFormData?.invoiceImage);

      // // Retrieve the Base64 string from local storage
      // let base64String = orderDetailsForm?.orderDetailsFormData?.iteminvoice;

      // // Check if the Base64 string exists
      // if (base64String) {
      //   console.log("Retrieved Base64 string from localStorage:", base64String);

      //   // Use it for image rendering, for example:
      //   const img = document.createElement("img");
      //   img.src = base64String;
      //   document.body.appendChild(img); // This will append the image to the body

      //   // You can also set the src of an existing <img> element like this:
      //   // document.getElementById('yourImageElementId').src = base64String;
      // } else {
      //   console.log("No file found in localStorage");
      // }
    }
  }, [orderDetailsForm?.orderDetailsFormData]);

  // useEffect for update order form details global state from local
  // useEffect(() => {
  //   const orderData = getDataToLocal("orderFormFields");
  //   if (
  //     !orderDetailsForm?.orderDetailsFormData &&
  //     orderData?.orderDetailsFormData
  //   ) {
  //     setOrderDetailsForm({
  //       ...orderData,
  //       orderDetailsFormData: { ...orderData?.orderDetailsFormData },
  //     });
  //   }
  // }, []);

  console.log("I AM selected Item Category", selectedItemCategory);
  //************************ Handle HTML CODE ***********************//
  return (
    <>
      <h4>Details</h4>
      <form>
        <div className="form_field_wrap">
          <div className="form_field">
            <CommonSelect
              isMulti={true}
              label="Item Category*"
              options={itemCategoryArray}
              value={selectedItemCategory}
              error={orderDetailsFormFieldsValidationError?.ItemCategory}
              onChange={(selectedItem) => setSelectedItemCategory(selectedItem)}
            />
          </div>

          <div className="form_field">
            <Input
              type="text"
              name="Itemname"
              label="Item Name*"
              onChange={onChangeHandler}
              value={orderDetailsFormFields?.Itemname}
              error={orderDetailsFormFieldsValidationError?.Itemname}
              placeholder={
                "You can enter multiple item's name with comma (,) separated"
              }
            />
          </div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field">
            <CommonSelect
              isMulti={true}
              label="Item Type*"
              options={itemTypeArray}
              value={selectedItemType}
              error={orderDetailsFormFieldsValidationError?.ItemType}
              onChange={(selectedItem) => setSelectedItemType(selectedItem)}
            />
          </div>

          <div className="form_field">
            <Input
              type="text"
              name="iteminvoice"
              label="Invoice Number*"
              onChange={onChangeHandler}
              placeholder={"Enter invoice number"}
              value={orderDetailsFormFields?.iteminvoice}
              error={orderDetailsFormFieldsValidationError?.iteminvoice}
            />
          </div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field">
            <FileUpload
              id={"invoiceImage"}
              value={inVoiceFile}
              label={"Upload Invoice "}
              onChange={fileOnChangeHandler}
              accept={"image/png, image/jpeg"}
              error={orderDetailsFormFieldsValidationError?.invoiceImage}
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              maxLength={12}
              name="EWayBillNo"
              label={`E-way Bill Number ${
                orderSearchFieldData?.shipmentAmount > 50000 ? "*" : ""
              }`}
              placeholder="Enter E-way Bill Number"
              onChange={onChangeHandler}
              value={orderDetailsFormFields?.EWayBillNo}
              error={orderDetailsFormFieldsValidationError?.EWayBillNo}
            />
          </div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field">
            <DatePicker
              value={eWayBillExpiry}
              placeholder="dd/mm/yyyy"
              isClearable={eWayBillExpiry ? true : false}
              label={`E-way Bill Expiry Date ${
                orderSearchFieldData?.shipmentAmount > 50000 ? "*" : ""
              }`}
              onChange={(date) => setEWayBillExpiry(date)}
              error={orderDetailsFormFieldsValidationError?.EWayBillExpDate}
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              name="OtherInfromation"
              label="Other Information"
              placeholder={"Enter here"}
              onChange={onChangeHandler}
              value={orderDetailsFormFields?.OtherInfromation}
            />
          </div>
        </div>

        {/* <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              placeholder={"Enter here"}
              label="Other Information"
            />
          </div>
        </div> */}

        <div className="button_wrap">
          <Button className={"light black"} onClick={prev}>
            Back
          </Button>
          <Button onClick={onSubmitHandler}>Continue</Button>
        </div>
      </form>
    </>
  );
};

export default ProductDetails;
