import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const PaiChart = ({ dataArray }) => {
  const chartData = {
    series: dataArray,
    options: {
      chart: {
        type: "donut",
      },
      // colors: ["#198754", "#FFD200", "#344BFD", "#008FFB", "#FC5EF0"],
      colors: ["#198754", "orange", "#FFD200", "#344BFD", "#FC5EF0", "008FFB"],
      labels: [
        "Booked",
        "Picked-up",
        "In_Transit",
        "Delivered",
        "Cancelled",
        "RTO",
      ],
      // labels: ["Booked", "In_Transit", "Delivered", "RTO", "Cancelled"],
      legend: {
        position: "bottom", // Legend position at the bottom
        horizontalAlign: "center", // Align the legend items centrally
        fontSize: "15px", // Adjust the font size for the legend labels
        itemMargin: {
          horizontal: 18, // Add horizontal padding between legend items
          vertical: 6, // Add vertical padding between rows of legend items
        },
        markers: {
          radius: 12, // Increase the size of the color markers next to each label
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "88%",
            // background: "red",
            labels: {
              show: true,

              total: {
                show: true,
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                label: "Booking",
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="donut"
          height={350} // Optional height adjustment
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default PaiChart;
