import { colorCardImg } from "assets/images";
import { Button, LoadingButton } from "components/form";
import React, { useEffect, useState } from "react";
import { useRazorpay } from "react-razorpay";
import { toast } from "react-toastify";
import {
  getGateWayIdAPI,
  getWalletDetailsAPI,
} from "services/user-controllers/masterController";

const WalletPayHandler = ({ orderAmount, onSubmitHandler, loadingWallet }) => {
  //*********** Handle all state **********//
  const [walletAmount, setWalletAmount] = useState(0);
  const [addMoneyButtonLoading, setAddMoneyButtonLoading] = useState(false);

  const { error, isLoading, Razorpay } = useRazorpay();

  //***************************** Hanle all function ***********************//

  //get wallet amount handler
  const getWalletAmountHandler = async () => {
    try {
      const { data } = await getWalletDetailsAPI();
      if (data?.code === 200) {
        setWalletAmount(Number(data?.data?.availableWalletAmount));
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Razor pay Payment Function
  const displayRazorpay = async (data) => {
    console.log("RECharge data ===>>> ", data);
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
      amount: data?.amount,
      currency: data?.currency,
      name: "Test Company",
      description: "Test Transaction",
      order_id: data?.gatewayOrderId,

      prefill: {
        name: "John Doe",
        email: "john.doe@example.com",
        contact: "9999999999",
        method: "card|upi|wallet", //card|upi|wallet
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#F37254",
      },

      handler: function (response) {
        // Hit the API to create the order in the backend
        setAddMoneyButtonLoading(false);
        // setWalletAmount(20)
        getWalletAmountHandler();
        toast.success("Money added successfully !");
      },
    };

    const razorpayInstance = new Razorpay(options);
    razorpayInstance.open();
  };

  // get gateWayOrderIdForRazorPayHandler
  const getGatewayOrderIdHandler = async () => {
    try {
      setAddMoneyButtonLoading(true);
      const addBalance = Number(orderAmount - walletAmount) + 10;
      const { data } = await getGateWayIdAPI({ totalAmount: addBalance });
      if (data?.code === 200) {
        // setRazorPayDetailsForWallet({
        //   gatewayOrderId: data?.data?.gatewayOrderId,
        //   amount: data?.data?.amount,
        //   currency: data?.data?.currency,
        // });
        console.log("I Am gateWay response", data?.data);
        if (data?.data?.gatewayOrderId) {
          displayRazorpay(data?.data);
        }
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect for get wallet amount
  useEffect(() => {
    getWalletAmountHandler();

    // eslint-disable-next-line
  }, []);

  //***************************** Hanle HTML CODE ***********************//
  return (
    <>
      <img src={colorCardImg} alt="" />
      <h4>₹{walletAmount}</h4>
      <p>Wallet Balance</p>

      <ul>
        <li>
          <b>{orderAmount}</b>
          {walletAmount < orderAmount && (
            <span>
              <LoadingButton
                loading={addMoneyButtonLoading}
                disabled={addMoneyButtonLoading}
                onClick={getGatewayOrderIdHandler}
                className={addMoneyButtonLoading ? "disabled" : ""}
              >
                Add
              </LoadingButton>
            </span>
          )}
        </li>

        {orderAmount > walletAmount ? (
          <li>
            <p>
              You need to add ₹{Number(orderAmount) - Number(walletAmount)} in
              your wallet to pay for this order{" "}
            </p>
            {/* <span>TRANSACTION AMOUNT</span> */}
            {/* <b>₹839.39</b> */}
          </li>
        ) : (
          <></>
        )}
      </ul>

      {walletAmount > orderAmount && (
        <div className="button_wrap">
          <LoadingButton
            loading={loadingWallet}
            disabled={loadingWallet}
            onClick={onSubmitHandler}
            className={loadingWallet ? "w-100 disabled" : "w-100"}
          >
            Continue
          </LoadingButton>
        </div>
      )}
    </>
  );
};

export default WalletPayHandler;
