import React, { useEffect, useState } from "react";
import { GoTrash } from "react-icons/go";
import { RiAddCircleLine, RiEdit2Line } from "react-icons/ri";
import Placeholder from "../../../../../../components/placeholder/Placeholder";
import { Button, Input, TextArea } from "../../../../../../components/form";
import DeleteModal from "../../../../../../components/modals/DeleteModal";
import CustomModal from "../../../../../../components/modals/CustomModal";
import { IoIosArrowForward } from "react-icons/io";
import { toast } from "react-toastify";
import {
  bankListingApi,
  bankStatusTogglerAPI,
  deleteBankAPI,
} from "../../../../../../services/user-controllers/bankCont";
import BankDetailHandler from "./BankDetailHandler";

const UserBankListing = () => {
  //******************************** Handle all state *************************//
  const [addRule, setAddRule] = useState(false);
  const [bankListing, setBankListing] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  //------------ Bank Details Handler states -------------//

  const [bankRecordId, setBankRecordId] = useState("");
  const [modalCallType, setModalCallType] = useState("add");
  const [bankDetailsState, setBankDetailsState] = useState({});
  const [modalTitle, setModalTitle] = useState("Add Bank Details");
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

  //******************************** Handle All Functions ************************//

  // get addressList handler
  const getBankListHandler = async () => {
    try {
      const { data } = await bankListingApi();
      if (data?.code === 200) {
        setBankListing(data.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect it run only once
  useEffect(() => {
    getBankListHandler();
  }, []);

  // Edit Bank details handler
  const editBankDetailsHandler = (item) => {
    setAddRule(true);
    setBankRecordId(item?.id);
    setModalCallType("edit");
    setBankDetailsState(item);
    setModalTitle("Bank Detail");
  };

  // Delete bank detail handler
  const deleteBankDetailHandler = async () => {
    try {
      setDeleteButtonLoading(true);
      const { data } = await deleteBankAPI(bankRecordId);
      if (data?.code === 200) {
        setDeleteModal(false);
        getBankListHandler();
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteButtonLoading(false);
    }
  };

  // Active/Inactive bank toggle handler
  const bankStatusToggleHandler = async (recordId) => {
    try {
      setDeleteButtonLoading(true);
      const { data } = await bankStatusTogglerAPI(recordId);
      if (data.code === 200) {
        getBankListHandler();
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteButtonLoading(false);
    }
  };


  //******************************** Handle HTML CODE *************************//
  return (
    <>
      <div className="rules">
        <h5>Bank Details</h5>
        <div className="dataListing">
          <div className="card_wrap">
            {bankListing?.length > 0 &&
              bankListing?.map((el, i) => (
                <div key={i} className="item">
                  <div className="bank_card">
                    <div className="content">
                      <h5>{el?.bankName}</h5>
                      <p>A/c no - {el?.accountNumber}</p>
                      {el?.isDefault ? (
                        <button
                          type="button"
                          className="disabled"
                          style={{ backgroundColor: "green" }}
                          // onClick={() => bankStatusToggleHandler(el?.id)}
                        >
                          Enable
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => bankStatusToggleHandler(el?.id)}
                        >
                          Disable
                        </button>
                      )}

                      <p>IFSC - {el?.IFSCCode}</p>
                    </div>
                    <div className="bottom">
                      {el?.beneficiaryName}
                      <button
                        type="button"
                        onClick={() => editBankDetailsHandler(el)}
                      >
                        <IoIosArrowForward />
                      </button>
                    </div>
                  </div>
                </div>
              ))}

          </div>
        </div>

        {/*------------- Data not Found placeholder ---------------*/}
        {bankListing?.length === 0 && (
          <Placeholder content={"Bank details are not available"} />
        )}

        {/*--------------- Add Bank Button ---------------*/}

        <div className="button_wrap button_right">
          <Button
            className={"has_icon"}
            onClick={() => {
              setAddRule(true);
              setModalCallType("add");
              setModalTitle("Add Bank Details");
            }}
          >
            <RiAddCircleLine />
            Add Bank
          </Button>
        </div>

      </div>

      {/*------------------------ Add New Bank Modal-----------------------*/}
      <CustomModal
        className={"sm"}
        show={addRule}
        handleClose={() => setAddRule(false)}
      >
        <BankDetailHandler
          title={modalTitle}
          callRole={modalCallType}
          bankList={getBankListHandler}
          bankDetails={bankDetailsState}
          deleteModalHandler={setDeleteModal}
          closeBankModal={() => setAddRule(false)}
        />
      </CustomModal>

      {/*------------------- Delete Modal Modal---------------------*/}

      <DeleteModal
        show={deleteModal}
        buttonLoading={deleteButtonLoading}
        handleSubmit={deleteBankDetailHandler}
        handleClose={() => setDeleteModal(false)}
        content={"Are you sure you want to delete Bank Account?"}
      />
    </>
  );
};

export default UserBankListing;
