import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { columnIcon } from "../../assets/icons";

const ColumnFilterDropdown = ({
  columnsArray,
  onToggleColumn,
  hideAllColumns,
  showAllColumns,
}) => {
  //********************** Handle all states **********************//
  const [searchTerm, setSearchTerm] = useState("");

  const filteredColumns = columnsArray?.filter((col) =>
    col?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  //***************************** Handle HTML CODE *****************************//
  return (
    <Dropdown className="columnsSearch">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        <img src={columnIcon} alt="Column Icon" />
        <span>Columns</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <p>Find Column</p>
        <input
          type="search"
          value={searchTerm}
          className="form-control input"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {filteredColumns?.map((col) => (
          <Dropdown.Item as="label" key={col.key}>
            <span>{col.name}</span>
            <input
              type="checkbox"
              checked={!col.isHidden}
              onChange={() => onToggleColumn(col.key)}
            />
          </Dropdown.Item>
        ))}
        <Dropdown.Item as="label">
          <button onClick={hideAllColumns}>Hide All</button>
          <button onClick={showAllColumns}>Show All</button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ColumnFilterDropdown;
