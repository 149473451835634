import "../../Settings.scss";
import React, { useEffect, useState } from "react";
import CompanyKYC from "./section/CompanyKYC";
import IndividualKYC from "./section/IndividualKYC";
import SelectKYCType from "./section/SelectKYCType";
import IndividualKycGetDetail from "./section/IndividualKycGetDetail";
import { getKYCDetailAPI } from "../../../../../../services/user-controllers/kycCont";
import { toast } from "react-toastify";
import CompanyKycDetails from "./section/CompanyKycDetails";
const UserKYC = () => {
  //**************************** Handle all state *************************//
  const [steps, setSteps] = useState("step-1");
  const [personType, setPersonType] = useState("");
  const [getKYCDetails, setGetKYCDetails] = useState(null);
  const [isKYCUploaded, setIsKYCUploaded] = useState(false);

  //**************************** Handle all Functions *************************//
  // get kyc detail handler
  const getKYCDetailsHandler = async () => {
    try {
      const { data } = await getKYCDetailAPI();
      console.log("KYC DETAILS", data);
      if (data?.code === 200) {
        if (!data?.data) {
          return;
        } else {
          setIsKYCUploaded(true);
          setGetKYCDetails(data.data);
        }
      } else {
        toast.error(data?.message);
      }
      console.log("GET DETAILS", data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // if (personType?.length > 0) {
    getKYCDetailsHandler();
    // }
  }, [personType, isKYCUploaded]);

  console.log("KYC TOGGLE SWITCH", isKYCUploaded);

  //**************************** Handle HTML CODE *************************//
  return (
    <>
      <div className="user_kyc">
        {isKYCUploaded && getKYCDetails?.id ? (
          getKYCDetails?.businessType === "individual" ? (
            <IndividualKycGetDetail
              detailsData={getKYCDetails}
              nextStep={() => setSteps("step-3")}
              setGetKYCDetails={setGetKYCDetails}
              getDetailsHandler={getKYCDetailsHandler}
            />
          ) : (
            <CompanyKycDetails
              detailsData={getKYCDetails}
              setGetKYCDetails={setGetKYCDetails}
              getDetailsHandler={getKYCDetailsHandler}
            />
          )
        ) : (
          <>
            {steps === "step-1" && !getKYCDetails?.businessType && (
              <SelectKYCType
                userType={personType}
                setUserType={setPersonType}
                nextStep={() => setSteps("step-2")}
              />
            )}
          </>
        )}

        {steps === "step-2" &&
          personType === "individual" &&
          !getKYCDetails && (
            <>
              <IndividualKYC
                kycDetailsData={getKYCDetails}
                nextStep={() => setSteps("step-3")}
                loadUploadedKycPage={setIsKYCUploaded}
              />
            </>
          )}

        {steps === "step-2" && personType === "company" && !getKYCDetails && (
          <CompanyKYC
            nextStep={() => setSteps("step-4")}
            loadUploadedKycPage={setIsKYCUploaded}
          />
        )}

        {/* {steps === "step-2" && <KYCStep2 nextStep={() => setSteps("step-3")} />} */}

        {/* {steps === "step-4" && <KYCStep4 />} */}
      </div>
    </>
  );
};

export default UserKYC;
