import React, { useState } from "react";
import { Button, Input, Select } from "../form";
import { toast } from "react-toastify";

const FilterModal = ({
  columnArray,
  filterFields,
  operatorArray,
  setFilterFields,
  setModalVisibility,
}) => {
  //********************* Handle all state *******************//

  const [columnsValue, setColumnsValue] = useState("");
  const [operatorValue, setOperatorValueValue] = useState("");
  const [searchFilterValue, setSearchFilterValue] = useState("");

  //********************** Handle all function ******************//

  // errorHandler
  const errorHandler = () => {
    let formIsValid = true;

    if (!columnsValue) {
      formIsValid = false;
      toast.error("Please Select Column*");
      return formIsValid;
    }

    if (!operatorValue) {
      formIsValid = false;
      toast.error("Please Select Operator*");
      return formIsValid;
    }

    if (!searchFilterValue) {
      formIsValid = false;
      toast.error("Please Enter Search Value*");
      return formIsValid;
    }

    return formIsValid;
  };

  //   applyFilterHandler
  const applyFilterHandler = () => {
    if (errorHandler()) {
      setFilterFields({
        ...filterFields,
        columnFilterName: columnsValue,
        operatorFilterName: operatorValue,
        filterValue: searchFilterValue,
        isApplied: !filterFields?.isApplied,
      });
      setModalVisibility(false);
    }
  };

  //***************************** Handle HTML CODE ************************//
  return (
    <div className="form_field_wrap">
      <div className="form_field">
        <Select
          label="Columns*"
          value={columnsValue}
          onChange={(e) => setColumnsValue(e.target.value)}
        >
          <option>Select</option>
          {columnArray?.length > 0 &&
            columnArray.map((el) => (
              <option key={el.id} value={el.value}>
                {el.name}
              </option>
            ))}
        </Select>
      </div>
      <div className="form_field">
        <Select
          label="Operators*"
          value={operatorValue}
          onChange={(e) => setOperatorValueValue(e.target.value)}
        >
          <option>Select</option>
          {operatorArray?.length > 0 &&
            operatorArray.map((el) => (
              <option key={el.id} value={el.value}>
                {el.name}
              </option>
            ))}
        </Select>
      </div>
      <div className="form_field">
        <Input
          type="text"
          label="Value*"
          placeholder="filter value"
          value={searchFilterValue}
          onChange={(e) => setSearchFilterValue(e.target.value)}
        />
      </div>

      <div className="form_field">
        <label className="label dummy">dummy</label>
        <Button onClick={applyFilterHandler}>Apply</Button>
      </div>
    </div>
  );
};

export default FilterModal;
