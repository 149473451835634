import React from "react";
import { letterImg } from "../../../assets/images";
import { Button } from "../../../components/form";

const MailSuccess = () => {
  return (
    <>
      <div className="mail_success">
        <img src={letterImg} alt="letter" />
        <h4>Email has been sent.</h4>
        <p>
          Please check your inbox. We have sent a new password to your email
          address.
        </p>
        <div className="button_wrap button_center">
          <Button>Login</Button>
        </div>
        <p className="resent_button">
          Didn’t receive the email? <button type="button">Resend</button>
        </p>
      </div>
    </>
  );
};

export default MailSuccess;
