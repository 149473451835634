import React, { useEffect } from "react";

import { blog1 } from "../../../../../../../assets/images";

const IndividualKycGetDetail = ({
  detailsData,
  getDetailsHandler,
  setGetKYCDetails,
}) => {
  // get data handler
  useEffect(() => {
    if (!detailsData) {
      getDetailsHandler();
    }
  }, [detailsData?.id]);

  // handle all functions //

  const docTypeNameHandler = (type) => {
    if (type === "panFront") {
      return "Pan Card";
    } else if (type === "dlFront") {
      return "Driving License";
    } else if (type === "passportFront") {
      return "Passport";
    } else {
      return "not found";
    }
  };

  const updateKycStatusHandler = (status) => {
    if (status === 0) {
      return "Rejected";
    } else if (status === 1) {
      return "Pending";
    } else {
      return "Approved";
    }
  };

  //*************** Handle HTML CODE ****************//
  return (
    <div>
      <h5>KYC</h5>
      <p>KYC Details {updateKycStatusHandler(detailsData?.isApproved)}</p>
      <form className="upload_doc">
        {/*-- Aadhar card --*/}
        <div className="form_field_wrap">
          <div className="form_field">
            <label className="label">Aadhar Card</label>
          </div>
          <div className="form_field"></div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field">
            <div className="preview">
              <img src={detailsData?.document1FrontSide} alt="" />
            </div>
          </div>

          <div className="form_field">
            <div className="preview">
              <img src={detailsData?.document1BackSide} alt="" />
            </div>
          </div>
        </div>

        {/* Select any doc */}
        <div className="form_field_wrap">
          <div className="form_field">
            <label className="label">
              {docTypeNameHandler(detailsData?.document2Type)}
            </label>
          </div>
        </div>
        {/* Other Doc image */}
        <div className="form_field_wrap">
          <div className="form_field">
            <div className="preview">
              <img src={detailsData?.document2FrontSide} alt="" />
            </div>
          </div>
          <div className="form_field">
            <div className="preview">
              <img src={detailsData?.document2BackSide} alt="" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default IndividualKycGetDetail;
