import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import HomePage from "../pages/websitePages/homePage/HomePage";
import UserNav from "../pages/userDashboard/components/SIdeNav";
import UserHeader from "../pages/userDashboard/components/DashboardHeader";
import ResetPassword from "../pages/auth/resetPassword/ResetPassword";
import { userRoutes } from "./UserRoutes";
import { getDataToLocal } from "../config/utils";

const Routing = () => {
  const CommonHeader = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    );
  };

  // User Dashboard //
  const UserDashboard = () => {
    return (
      <>
        <div className="dashboard_wrap">
          <div className="sidebar_wrap">
            <UserNav />
          </div>

          <div className="dashboard_content_wrap">
            <div className="dashboard_header">
              <UserHeader />
            </div>
            <div className="dashboard_content">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    );
  };

  // User Protected Route
  const ProtectedRoute = ({ children }) => {
    const localData = getDataToLocal("authDetail");
    const token = !localData?.token ? null : localData?.token;
    if (!token) {
      return <Navigate to="/" replace />;
    } else {
      return children;
    }
    // return children;
  };

  return (
    <>
      {/*--------------- Auth Routes for both --------------*/}
      <Routes>
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Common Routes */}
        <Route path="/" element={<CommonHeader />}>
          <Route path="/" element={<HomePage />} />
        </Route>

        {/* *****************************************  User Dashboard ***************************************** */}
        <Route path="/user" element={<UserDashboard />}>
          {userRoutes?.map(({ id, path, element }) => {
            return (
              <Route
                key={id}
                path={path}
                element={<ProtectedRoute>{element}</ProtectedRoute>}
              />
            );
          })}
        </Route>
      </Routes>
    </>
  );
};

export default Routing;
