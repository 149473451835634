import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
  SwitchButton,
  TextArea,
  Tooltip,
} from "../../../../../components/form";
import {
  cityListingApi,
  stateListingApi,
} from "../../../../../services/user-controllers/addressCont";
import { toast } from "react-toastify";
import {
  getProfileDetailsAPI,
  updateProfileAPI,
} from "../../../../../services/user-controllers/profileCont";
import { GlobalUserContext } from "../../../../../context/GlobalContext";

const UserEditProfile = () => {
  //********************************* Handle all state **************************//

  const [cityListing, setCityListing] = useState([]);
  const [stateListing, setStateListing] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [isCityDisable, setIsCityDisable] = useState(true);
  const [billingCityListing, setBillingCityListing] = useState([]);
  const [billingSelectedState, setBillingSelectedState] = useState("");
  const [isBillingCityDisable, setIsBillingCityDisable] = useState(true);
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false);

  const [profileFields, setProfileFields] = useState({
    name: "",
    email: "",
    mobile: "",
    secondEmail: "",
    GSTNo: "",
    panNo: "",
    dealItem: "",
    noOfShipment: "",
    avgShipmentWeight: "",
    companyAddressLine1: "",
    companyAddressLine2: "",
    companyAddressPincode: "",
    companyAddressState: "",
    companyAddressCity: "",
    companyAddressCountry: "",
    companyDescription: "",
    billingAddressLine1: "",
    billingAddressLine2: "",
    billingAddressPincode: "",
    billingAddressState: "",
    billingAddressCity: "",
    billingAddressCountry: "",
  });

  const [profileFieldsValidationError, setProfileFieldsValidationError] =
    useState({
      name: "",
      email: "",
      mobile: "",
      secondEmail: "",
      GSTNo: "",
      panNo: "",
      dealItem: "",
      noOfShipment: "",
      avgShipmentWeight: "",
      companyAddressLine1: "",
      companyAddressLine2: "",
      companyAddressPincode: "",
      companyAddressState: "",
      companyAddressCity: "",
      companyAddressCountry: "",
      companyDescription: "",
      billingAddressLine1: "",
      billingAddressLine2: "",
      billingAddressPincode: "",
      billingAddressState: "",
      billingAddressCity: "",
      billingAddressCountry: "",
    });

  const [extraErrorField, setExtraErrorField] = useState({
    email: false,
    secondEmail: false,
  });

  // address should be same as company address handler function
  const sameAsCompanyAddressHandler = (isChecked) => {
    if (isChecked) {
      setBillingSelectedState(selectedState);
      setBillingCityListing(cityListing);
      setProfileFields({
        ...profileFields,
        billingAddressLine1: profileFields?.companyAddressLine1,
        billingAddressLine2: profileFields?.companyAddressLine2,
        billingAddressPincode: profileFields?.companyAddressPincode,
        billingAddressState: profileFields?.companyAddressState,
        billingAddressCity: profileFields?.companyAddressCity,
        billingAddressCountry: profileFields?.companyAddressCountry,
      });
    } else {
      setBillingSelectedState("");
      setBillingCityListing([]);
      setProfileFields({
        ...profileFields,
        billingAddressLine1: "",
        billingAddressLine2: "",
        billingAddressPincode: "",
        billingAddressState: "",
        billingAddressCity: "",
        billingAddressCountry: "",
      });
    }
  };

  console.log("PROFILE FIELDS", profileFields);

  const { userProfileData, setUserprofileData } = useContext(GlobalUserContext);

  //********************************* Handle all Functions **************************//

  // get profile details handler
  const getProfileDetailsHandler = async () => {
    try {
      const { data } = await getProfileDetailsAPI();
      if (data?.code === 200) {
        setUserprofileData({
          ...userProfileData,
          name: data?.data?.name,
          email: data?.data?.email,
        });
        console.log("USer data from api", data);

        if (data?.data?.companyAddressState?.length > 0) {
          fetchCityListingHandler(
            data?.data?.companyAddressState,
            setCityListing
          );
          setIsCityDisable(false);
        }

        if (data?.data?.billingAddressState?.length > 0) {
          fetchCityListingHandler(
            data?.data?.billingAddressState,
            setBillingCityListing
          );
          setIsBillingCityDisable(false);
        }

        setProfileFields(data.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect for get profile details
  useEffect(() => {
    getProfileDetailsHandler();
  }, []);

  // get state listing handler
  const getStateListingHandler = async () => {
    try {
      const { data } = await stateListingApi();
      if (data?.code === 200) {
        setStateListing(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get city listing handler
  const fetchCityListingHandler = async (state, setCityList) => {
    try {
      const { data } = await cityListingApi(state);
      if (data?.code === 200) {
        setCityList(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!stateListing.length) {
      getStateListingHandler();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedState?.length > 0) {
      fetchCityListingHandler(selectedState, setCityListing);
    }
    // eslint-disable-next-line
  }, [selectedState]);

  useEffect(() => {
    if (billingSelectedState?.length > 0) {
      fetchCityListingHandler(billingSelectedState, setBillingCityListing);
    }
    // eslint-disable-next-line
  }, [billingSelectedState]);

  // ErrorHandler
  const errorHandler = () => {
    let errorField = {};
    let extraError = {};

    let formIsValid = true;
    let numberFormat = /^\d{1,12}$/;
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let gstFormat = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

    if (!profileFields.name) {
      formIsValid = false;
      errorField["name"] = "Please Enter Name *";
    }

    if (!profileFields.email) {
      formIsValid = false;
      errorField["email"] = "Please Enter Email *";
    } else if (!profileFields?.email?.match(mailFormat)) {
      formIsValid = false;
      errorField["email"] = "Invalid Email";
      extraError["email"] = true;
    }

    if (!profileFields.secondEmail) {
      formIsValid = false;
      errorField["secondEmail"] = "Please Enter Secondary Email *";
    } else if (!profileFields?.secondEmail?.match(mailFormat)) {
      formIsValid = false;
      errorField["secondEmail"] = "Invalid Secondary Email";
      extraError["secondEmail"] = true;
    }

    if (
      profileFields?.GSTNo?.length > 0 &&
      !profileFields?.GSTNo?.match(gstFormat)
    ) {
      formIsValid = false;
      errorField["GSTNo"] = "Invalid GST Number";
      extraError["GSTNo"] = true;
    }

    if (!profileFields.companyAddressLine1) {
      formIsValid = false;
      errorField["companyAddressLine1"] = "Please Enter Address *";
    }

    // if (!profileFields.companyAddressLine2) {
    //   formIsValid = false;
    //   errorField["companyAddressLine2"] = "Required *";
    // }

    if (!profileFields.companyAddressPincode) {
      formIsValid = false;
      errorField["companyAddressPincode"] = "Please Enter Pincode *";
    }

    if (!profileFields.companyAddressState) {
      formIsValid = false;
      errorField["companyAddressState"] = "Please Select State *";
    }

    if (!profileFields.companyAddressCity) {
      formIsValid = false;
      errorField["companyAddressCity"] =
        "City is mandatory so please select state first *";
    }

    if (!profileFields.companyAddressCountry) {
      formIsValid = false;
      errorField["companyAddressCountry"] = "Please Select Country *";
    }

    if (!profileFields.companyDescription) {
      formIsValid = false;
      errorField["companyDescription"] = "Please Enter Description *";
    }

    if (!profileFields.billingAddressLine1) {
      formIsValid = false;
      errorField["billingAddressLine1"] = "Please Enter Address *";
    }

    // if (!profileFields.billingAddressLine2) {
    //   formIsValid = false;
    //   errorField["billingAddressLine2"] = "Required *";
    // }

    // if (!profileFields.billingAddressPincode) {
    //   formIsValid = false;
    //   errorField["billingAddressPincode"] = "Required *";
    // }

    if (!profileFields.billingAddressPincode) {
      formIsValid = false;
      errorField["billingAddressPincode"] = "Please Enter Pincode *";
    }

    if (!profileFields.billingAddressState) {
      formIsValid = false;
      errorField["billingAddressState"] = "Please Select State *";
    }

    if (!profileFields.billingAddressCity) {
      formIsValid = false;
      errorField["billingAddressCity"] =
        "City is mandatory so please select state first *";
    }

    if (!profileFields?.billingAddressCountry) {
      formIsValid = false;
      errorField["billingAddressCountry"] = "Please Select Country *";
    }

    setExtraErrorField(extraError);
    setProfileFieldsValidationError(errorField);

    return formIsValid;
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "billingAddressPincode") {
      const zipCodeValue = value.replace(/[^0-9]/g, "");
      setProfileFields({
        ...profileFields,
        billingAddressPincode: zipCodeValue,
      });
    } else if (name === "companyAddressPincode") {
      const zipCodeValue = value.replace(/[^0-9]/g, "");
      setProfileFields({
        ...profileFields,
        companyAddressPincode: zipCodeValue,
      });
    } else {
      setProfileFields({ ...profileFields, [name]: value });
    }
  };

  // onSubmitHandler
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!errorHandler()) return;
    try {
      setUpdateButtonLoading(true);
      const { isActive, createdAt, id, mobile, email, updatedAt, ...rest } =
        profileFields;
      const sendData = rest;
      const { data } = await updateProfileAPI(sendData);

      if (data?.code === 200) {
        getProfileDetailsHandler();
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setUpdateButtonLoading(false);
    }
  };

  console.log("VALIDATION ERROR", profileFieldsValidationError);

  //********************************* Handle HTML CODE **************************//
  return (
    <>
      <div className="editProfile">
        <h5>Basic Details</h5>

        <form>
          {/*--------------------- Handle personal Details ------------------*/}
          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="text"
                placeholder="Enter Personal Name"
                label="Person Name*"
                name="name"
                value={profileFields?.name}
                onChange={onChangeHandler}
                error={profileFieldsValidationError?.name}
              />
            </div>
            <div className="form_field">
              <Input
                type="email"
                name="email"
                label="Email*"
                placeholder="Enter Email Id"
                isDisabled={true}
                value={profileFields?.email}
                onChange={onChangeHandler}
                error={profileFieldsValidationError?.email}
                extraError={extraErrorField?.email}
              />
            </div>
          </div>
          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="tel"
                name="mobile"
                maxLength={10}
                placeholder="Enter Mobile Number"
                isDisabled={true}
                label="Mobile Number"
                onChange={onChangeHandler}
                value={profileFields?.mobile}
                error={profileFieldsValidationError?.mobile}
              />
            </div>
            <div className="form_field">
              <Input
                type="email"
                placeholder="Enter Secondary Email Id"
                name="secondEmail"
                label="Secondary Email*"
                onChange={onChangeHandler}
                value={profileFields?.secondEmail}
                extraError={extraErrorField?.secondEmail}
                error={profileFieldsValidationError?.secondEmail}
              />
            </div>
          </div>
          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                label="GST"
                type="text"
                name="GSTNo"
                placeholder="Enter GST No."
                onChange={onChangeHandler}
                value={profileFields?.GSTNo}
                extraError={extraErrorField?.GSTNo}
                error={profileFieldsValidationError?.GSTNo}
              />
            </div>
            <div className="form_field">
              <Input
                label="PAN"
                type="text"
                name="panNo"
                placeholder="Enter PAN No."
                onChange={onChangeHandler}
                value={profileFields?.panNo}
              />
            </div>
          </div>
          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="text"
                name="dealItem"
                label="Deal Item"
                onChange={onChangeHandler}
                placeholder="Enter Deal Item"
                value={profileFields?.dealItem}
              />
            </div>
            <div className="form_field">
              <Input
                type="number"
                name="avgShipmentWeight"
                onChange={onChangeHandler}
                label="Average Shipment Weight"
                value={profileFields?.avgShipmentWeight}
                placeholder="Enter Average Shipment Weight"
              />
            </div>
          </div>
          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="number"
                name="noOfShipment"
                onChange={onChangeHandler}
                label="Number of shipment"
                placeholder="Enter Number of shipment"
                value={
                  !profileFields?.noOfShipment
                    ? "------"
                    : profileFields?.noOfShipment
                }
              />
            </div>
          </div>
          {/*--------------------- Handle company Details ------------------*/}
          <h5 className="form_divider">COMPANY ADDRESS</h5>
          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="text"
                label="Address Line 1*"
                name="companyAddressLine1"
                onChange={onChangeHandler}
                placeholder="Enter Address Line 1"
                value={profileFields?.companyAddressLine1}
                error={profileFieldsValidationError?.companyAddressLine1}
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                label="Address Line 2"
                name="companyAddressLine2"
                onChange={onChangeHandler}
                placeholder="Enter Address Line 2"
                value={profileFields?.companyAddressLine2}
                // error={profileFieldsValidationError?.companyAddressLine2}
              />
            </div>
          </div>
          <div className="form_field_wrap">
            <div className="form_field">
              <Select
                label="Country*"
                value={profileFields?.companyAddressCountry}
                onChange={(e) =>
                  setProfileFields({
                    ...profileFields,
                    companyAddressCountry: e.target.value,
                  })
                }
                className={
                  !profileFieldsValidationError?.companyAddressCountry
                    ? ""
                    : "is-invalid"
                }
                error={profileFieldsValidationError?.companyAddressCountry}
              >
                <option value="">Select Country</option>
                <option value="India">India</option>
              </Select>
            </div>

            <div className="form_field">
              <Select
                label="State*"
                value={profileFields?.companyAddressState}
                onChange={(e) => {
                  setSelectedState(e?.target?.value);
                  setProfileFields({
                    ...profileFields,
                    companyAddressState: e.target.value,
                  });
                  setIsCityDisable(false);
                }}
              >
                <option value="">Select state</option>
                {stateListing?.length > 0 &&
                  stateListing?.map((el, i) => (
                    <option key={i}>{el?.state}</option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="form_field_wrap">
            <div className="form_field">
              <Select
                label="City*"
                disabled={isCityDisable}
                value={profileFields?.companyAddressCity}
                onChange={(e) => {
                  setSelectedCity(e.target.value);
                  setProfileFields({
                    ...profileFields,
                    companyAddressCity: e.target.value,
                  });
                }}
                className={
                  !profileFieldsValidationError?.companyAddressCity
                    ? ""
                    : "is-invalid"
                }
              >
                {cityListing?.length <= 0 ? (
                  <option>Please select state first</option>
                ) : (
                  <>
                    <option value="">Select City</option>
                    {cityListing?.map((el, i) => (
                      <option key={i}>{el?.city}</option>
                    ))}
                  </>
                )}
              </Select>

              {profileFieldsValidationError?.companyAddressCity?.length > 0 && (
                <span className={`invalid-feedback`}>
                  {profileFieldsValidationError?.companyAddressCity}
                </span>
              )}
            </div>

            <div className="form_field">
              <Input
                type="text"
                maxLength={6}
                placeholder="Enter Zip Code"
                label="Zip Code*"
                onChange={onChangeHandler}
                name="companyAddressPincode"
                value={profileFields?.companyAddressPincode}
                error={profileFieldsValidationError?.companyAddressPincode}
              />
            </div>
          </div>
          <div className="form_field">
            <TextArea
              placeholder="Description"
              name="companyDescription"
              onChange={onChangeHandler}
              label="Company Description*"
              value={profileFields?.companyDescription}
              error={profileFieldsValidationError?.companyDescription}
            />
          </div>
          {/*--------------------- Handle billing Details ------------------*/}
          <h5 className="form_divider">BILLING ADDRESS</h5>
          <input
            type="checkbox"
            onChange={(e) => sameAsCompanyAddressHandler(e.target.checked)}
          />{" "}
          same as Company
          <div className="form_field_wrap my-3">
            <div className="form_field">
              <Input
                type="text"
                placeholder="Address Line 1"
                label="Address Line 1*"
                name="billingAddressLine1"
                onChange={onChangeHandler}
                value={profileFields?.billingAddressLine1}
                error={profileFieldsValidationError?.billingAddressLine1}
              />
            </div>
            <div className="form_field">
              <Input
                type="text"
                placeholder="Address Line 2"
                label="Address Line 2*"
                name="billingAddressLine2"
                onChange={onChangeHandler}
                value={profileFields?.billingAddressLine2}
                error={profileFieldsValidationError?.billingAddressLine2}
              />
            </div>
          </div>
          <div className="form_field_wrap">
            <div className="form_field">
              <Select
                label="Country*"
                onChange={(e) =>
                  setProfileFields({
                    ...profileFields,
                    billingAddressCountry: e.target.value,
                  })
                }
                value={profileFields?.billingAddressCountry}
                className={
                  !profileFieldsValidationError?.billingAddressCountry
                    ? ""
                    : "is-invalid"
                }
                error={profileFieldsValidationError?.billingAddressCountry}
              >
                <option value="">Select Country</option>
                <option value={"India"}>India</option>
              </Select>
            </div>

            <div className="form_field">
              <Select
                label="State*"
                value={profileFields?.billingAddressState}
                onChange={(e) => {
                  setBillingSelectedState(e?.target?.value);
                  setProfileFields({
                    ...profileFields,
                    billingAddressState: e.target.value,
                  });
                  setIsBillingCityDisable(false);
                }}
              >
                <option value="">Select state</option>
                {stateListing?.length > 0 &&
                  stateListing?.map((el, i) => (
                    <option key={i}>{el?.state}</option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="form_field_wrap">
            <div className="form_field">
              <Select
                label="City*"
                disabled={isBillingCityDisable}
                value={profileFields?.billingAddressCity}
                onChange={(e) => {
                  setSelectedCity(e.target.value);
                  setProfileFields({
                    ...profileFields,
                    billingAddressCity: e.target.value,
                  });
                }}
                className={
                  !profileFieldsValidationError?.billingAddressCity
                    ? ""
                    : "is-invalid"
                }
              >
                {cityListing?.length <= 0 ? (
                  <option>Please select state first</option>
                ) : (
                  <>
                    <option value="">Select City</option>
                    {billingCityListing?.map((el, i) => (
                      <option key={i}>{el?.city}</option>
                    ))}
                  </>
                )}
              </Select>

              {profileFieldsValidationError?.billingAddressCity?.length > 0 && (
                <span className={`invalid-feedback`}>
                  {profileFieldsValidationError?.billingAddressCity}
                </span>
              )}
            </div>

            <div className="form_field">
              <Input
                type="text"
                maxLength={6}
                placeholder="Enter Zip Code"
                label="Zip Code*"
                onChange={onChangeHandler}
                name="billingAddressPincode"
                value={profileFields?.billingAddressPincode}
                error={profileFieldsValidationError?.billingAddressPincode}
              />
            </div>
          </div>
          <div className="button_wrap button_right">
            {updateButtonLoading ? (
              <LoadingButton disabled loading={true} className={"disabled"} />
            ) : (
              <Button onClick={onSubmitHandler}>Save Changes</Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default UserEditProfile;
