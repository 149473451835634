import React, { useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
} from "../../../../../../../components/form";
import { cameraImg, placeholder2 } from "../../../../../../../assets/images";
import { MdOutlineModeEdit, MdOutlineModeEditOutline } from "react-icons/md";
import { toast } from "react-toastify";
import {
  companyKYCUploadAPI,
  individualKYCUploadAPI,
} from "../../../../../../../services/user-controllers/kycCont";

const CompanyKYC = ({ nextStep, loadUploadedKycPage }) => {
  //*************************** Handle all state *************************//

  const [selectedBack, setSelectedBack] = useState("");
  const [selectedFront, setSelectedFront] = useState("");
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const documentPairs = {
    "": "",
    panFront: "panBack",
    tanFront: "tanBack",
  };

  const [kycExtraError, setKycExtraError] = useState({
    GSTNo: false,
    tanNo: false,
  });

  const [kycFields, setKycFields] = useState({
    customerName: "",
    companyType: "",
    businessType: "company",
    GSTNo: "",
    tanNo: "",
  });

  const [kycFieldsValidationError, setKycFieldsValidationError] = useState({
    customerName: "",
    companyType: "",
    GSTNo: "",
    tanNo: "",
  });

  const [gstDocImage, setGstDocImage] = useState({
    frontImage: [],
    previewFrontImage: "",
  });

  const [otherDocImage, setOtherDocImage] = useState({
    backImage: [],
    frontImage: [],
    previewBackImage: "",
    previewFrontImage: "",
  });

  //*************************** Handle all functions *************************//

  const handleFrontChange = (event) => {
    const selectedFrontValue = event.target.value;
    setSelectedFront(selectedFrontValue);
    setSelectedBack(documentPairs[selectedFrontValue]);
    setOtherDocImage({
      backImage: [],
      frontImage: [],
      previewBackImage: "",
      previewFrontImage: "",
    });
  };

  const handleBackChange = (event) => {
    const selectedBackValue = event.target.value;
    const frontValue = Object.keys(documentPairs).find(
      (key) => documentPairs[key] === selectedBackValue
    );
    setSelectedBack(selectedBackValue);
    setSelectedFront(frontValue);
    setOtherDocImage({
      backImage: [],
      frontImage: [],
      previewBackImage: "",
      previewFrontImage: "",
    });
  };

  // onChangeImageHandler
  const onChangeImageHandler = (e, name) => {
    const data = e.target.files[0];
    let url = URL.createObjectURL(data);
    if (name === "gstDoc") {
      setGstDocImage({
        ...gstDocImage,
        frontImage: data,
        previewFrontImage: url,
      });
    }

    if (name === "otherDocFront") {
      setOtherDocImage({
        ...otherDocImage,
        frontImage: data,
        previewFrontImage: url,
      });
    }

    if (name === "otherDocBack") {
      setOtherDocImage({
        ...otherDocImage,
        backImage: data,
        previewBackImage: url,
      });
    }
  };

  // Error Handler
  const errorHandler = () => {
    let formFiled = {};
    let extraError = {
      GSTNo: false,
      tanNo: false,
    };
    let formIsValid = true;
    let gstFormat =
      /^([0][1-9]|[1-2][0-9]|3[0-7])[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

    let tanNoFormat = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;

    if (!kycFields?.customerName) {
      formFiled["customerName"] = "Please Enter Name*";
      setKycFieldsValidationError(formFiled);
      formIsValid = false;
      return formIsValid;
    }
    if (!kycFields?.companyType) {
      formFiled["companyType"] = "Please Select Company Type*";
      setKycFieldsValidationError(formFiled);
      formIsValid = false;
      return formIsValid;
    }
    if (!kycFields?.GSTNo) {
      formFiled["GSTNo"] = "Please Enter GST Number*";
      setKycFieldsValidationError(formFiled);
      formIsValid = false;
      return formIsValid;
    } else if (!kycFields?.GSTNo.match(gstFormat)) {
      extraError["GSTNo"] = true;
      formFiled["GSTNo"] = "Invalid GST Number*";
      setKycExtraError(extraError);
      setKycFieldsValidationError(formFiled);
      formIsValid = false;
      return formIsValid;
    }

    if (!kycFields?.tanNo) {
      formFiled["tanNo"] = "Please Enter Tan Number*";
      setKycFieldsValidationError(formFiled);
      formIsValid = false;
      return formIsValid;
    } else if (!kycFields?.tanNo.match(tanNoFormat)) {
      extraError["tanNo"] = true;
      formFiled["tanNo"] = "Invalid Tan Number*";
      setKycExtraError(extraError);
      setKycFieldsValidationError(formFiled);
      formIsValid = false;
      return formIsValid;
    }

    if (!gstDocImage.frontImage?.name) {
      formIsValid = false;
      toast.error("Please Upload GST Document's Image");
      return formIsValid;
    }

    if (!otherDocImage.frontImage?.name) {
      formIsValid = false;
      toast.error("Please Upload Selected Document's Front Image");
      return formIsValid;
    }

    if (!otherDocImage.backImage?.name) {
      formIsValid = false;
      toast.error("Please Upload Selected Document's Back Image");
      return formIsValid;
    }

    setKycExtraError(extraError);
    setKycFieldsValidationError(formFiled);
    return formIsValid;
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setKycFields({ ...kycFields, [name]: value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (errorHandler()) {
      try {
        setSubmitButtonLoading(true);
        let sendData = new FormData();
        sendData.append("customerName", kycFields?.customerName);
        sendData.append("companyType", kycFields?.companyType);
        sendData.append("businessType", "company");
        sendData.append("GSTNo", kycFields?.GSTNo);
        sendData.append("tanNo", kycFields?.tanNo);
        sendData.append("document1Type", "gst");
        sendData.append("document1FrontSide", gstDocImage?.frontImage);
        sendData.append("document2Type", selectedFront);
        sendData.append("document2FrontSide", otherDocImage?.frontImage);
        sendData.append("document2BackSide", otherDocImage?.backImage);
        console.log("I AM SEND DATA", sendData);
        const { data } = await companyKYCUploadAPI(sendData);
        if (data?.code === 200) {
          toast.success(data?.message);
          loadUploadedKycPage(true);
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitButtonLoading(false);
      }
    }
  };

  console.log("FORM FIELDS", kycFields);
  console.log("VALIDATION ERROR", kycFieldsValidationError);

  //*************************** Handle HTML CODE *************************//
  return (
    <>
      <h5>KYC</h5>
      <p>Enter KYC Details</p>
      <form className="upload_doc">
        <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              label="Name*"
              placeholder=""
              name="customerName"
              onChange={onChangeHandler}
              value={kycFields?.customerName}
              error={kycFieldsValidationError?.customerName}
            />
          </div>
          <div className="form_field">
            <Select
              name="companyType"
              onChange={onChangeHandler}
              label="Select Company Type*"
              value={kycFields?.companyType}
              error={kycFieldsValidationError?.companyType}
            >
              <option value="">Select </option>
              <option value="Partnership">Partnership</option>
              <option value="Limited Liability Partnership">
                Limited Liability Partnership
              </option>
              <option value="Public Limited Company">
                Public Limited Company
              </option>
              <option value="Private Limited Company">
                Private Limited Company
              </option>
              <option value="Sole proprietorship">Sole proprietorship</option>
            </Select>
          </div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              isDisabled={true}
              label="Business Type"
              placeholder="Fruitholic"
              value={kycFields?.businessType}
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              name="GSTNo"
              placeholder=""
              label="GST No*"
              value={kycFields?.GSTNo}
              onChange={onChangeHandler}
              extraError={kycExtraError?.GSTNo}
              error={kycFieldsValidationError?.GSTNo}
            />
          </div>
        </div>
        <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              name="tanNo"
              placeholder=""
              label="Tan No*"
              value={kycFields?.tanNo}
              onChange={onChangeHandler}
              extraError={kycExtraError?.tanNo}
              error={kycFieldsValidationError?.tanNo}
            />
          </div>
          <div className="form_field"></div>
        </div>
        <h5 className="form_divider">Upload Docs</h5>

        <>
          {/*-- GST Doc Image --*/}
          <div className="form_field_wrap">
            <div className="form_field">
              <label className="label">GST Doc*</label>
            </div>
            <div className="form_field"></div>
          </div>

          <div className="form_field_wrap">
            <div className="form_field file_upload">
              <input
                type="file"
                id="gstImg"
                onChange={(e) => {
                  onChangeImageHandler(e, "gstDoc");
                }}
                accept="image/png, image/gif, image/jpeg"
              />

              {!gstDocImage?.frontImage?.name ? (
                <label htmlFor="gstImg" className="upload_label">
                  <img src={cameraImg} alt="" />
                  <h5>Front Image</h5>
                </label>
              ) : (
                <div className="preview">
                  <label htmlFor="gstImg" className="edit_doc">
                    <MdOutlineModeEdit />
                  </label>
                  <img src={gstDocImage?.previewFrontImage} alt="" />
                </div>
              )}
            </div>
          </div>

          {/* Select any doc */}
          <div className="form_field_wrap">
            <div className="form_field">
              <Select
                label="Select Document*"
                value={selectedFront}
                onChange={handleFrontChange}
              >
                <option value="">Select Any one Pan/Tan number (Front)</option>
                <option value="panFront">Pan Card (Front)</option>
                <option value="tanFront">Tan Card (Front)</option>
              </Select>
            </div>

            <div className="form_field">
              <Select
                label="Select Document*"
                value={selectedBack}
                onChange={handleBackChange}
              >
                <option value="">Select Any one Pan/Tan number (Back)</option>
                <option value="panBack">Pan Card (Back)</option>
                <option value="tanBack">Tan Card (Back)</option>
              </Select>
            </div>
          </div>

          {/* Other Doc image */}

          <div className="form_field_wrap">
            <div className="form_field file_upload">
              <input
                type="file"
                id="otherDocImg"
                onChange={(e) => {
                  onChangeImageHandler(e, "otherDocFront");
                }}
                accept="image/png, image/gif, image/jpeg"
                disabled={!selectedFront || !selectedBack ? true : false}
                className={!selectedFront || !selectedBack ? "disabled" : ""}
              />

              {!otherDocImage?.frontImage?.name ? (
                <label
                  htmlFor="otherDocImg"
                  className={
                    !selectedFront || !selectedBack
                      ? "upload_label disabled"
                      : "upload_label"
                  }
                >
                  <img src={cameraImg} alt="" />
                  <h5>Front Image</h5>
                </label>
              ) : (
                <div className="preview">
                  <label htmlFor="otherDocImg" className="edit_doc">
                    <MdOutlineModeEdit />
                  </label>
                  <img src={otherDocImage?.previewFrontImage} alt="" />
                </div>
              )}
            </div>

            <div className="form_field file_upload">
              <input
                type="file"
                id="otherDocBackImg"
                onChange={(e) => {
                  onChangeImageHandler(e, "otherDocBack");
                }}
                accept="image/png, image/gif, image/jpeg"
                disabled={!selectedFront || !selectedBack ? true : false}
                className={!selectedFront || !selectedBack ? "disabled" : ""}
              />

              {!otherDocImage?.backImage?.name ? (
                <label
                  htmlFor="otherDocBackImg"
                  className={
                    !selectedFront || !selectedBack
                      ? "upload_label disabled"
                      : "upload_label"
                  }
                >
                  <img src={cameraImg} alt="" />
                  <h5>Back Image</h5>
                </label>
              ) : (
                <div className="preview">
                  <label htmlFor="otherDocBackImg" className="edit_doc">
                    <MdOutlineModeEditOutline />
                  </label>
                  <img src={otherDocImage?.previewBackImage} alt="" />
                </div>
              )}
            </div>
          </div>
        </>

        {/* 
        <div className="form_field_wrap id_images">
          <div className="form_field">
            <img src={placeholder2} alt="" />
          </div>
          <div className="form_field">
            <img src={placeholder2} alt="" />
          </div>
        </div> */}

        <div className="button_wrap button_right">
          {!submitButtonLoading ? (
            <Button type="button" onClick={onSubmitHandler}>
              Submit
            </Button>
          ) : (
            <LoadingButton
              disabled
              className={"disabled"}
              loading={submitButtonLoading}
            />
          )}
        </div>
      </form>
    </>
  );
};

export default CompanyKYC;
