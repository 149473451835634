import { Instance as axios, setToken } from "../instance";

// Person change password API function
export const changePasswordAPI = async (data) => {
  return axios.post(`api/changePassword`, data, setToken());
};

// Person get profile details api function
export const getProfileDetailsAPI = async () => {
  return axios.get(`api/userDetails`, setToken());
};

// Person update profile api function
export const updateProfileAPI = async (data) => {
  return axios.put(`api/editProfile`, data, setToken());
};

// M-Vikas account get details API
export const getAccountDetailsAPI = async () => {
  return axios.get(`api/getMvikasAccount`, setToken());
};
