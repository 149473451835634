import React, { useState } from "react";
import { Button, Input, LoadingButton } from "../../../../../components/form";
import { lockIcon } from "../../../../../assets/icons";
import usePasswordToggle from "../../../../../hooks/usePasswordToggle";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { changePasswordAPI } from "../../../../../services/user-controllers/profileCont";
import { toast } from "react-toastify";

const ChangePassword = () => {
  //***************************** Handle all Hooks **************************//
  const [passwordType, showIcon] = usePasswordToggle();
  const [passwordType1, showIcon1] = usePasswordToggle();
  const [passwordType2, showIcon2] = usePasswordToggle();

  //***************************** Handle all state **************************//
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [passwordFields, setPasswordFields] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordFieldValidationError, setPasswordFieldValidationError] =
    useState({
      oldPassword: "",
      password: "",
      confirmPassword: "",
    });

  const [extraErrorFiled, setExtraErrorField] = useState({
    password: false,
    confirmPassword: false,
  });

  // reset all handler
  const resetAllStateHandler = () => {
    setPasswordFields({
      oldPassword: "",
      password: "",
      confirmPassword: "",
    });

    // setPasswordFieldValidationError({
    //   oldPassword: "",
    //   password: "",
    //   confirmPassword: "",
    // });

    // setExtraErrorField({})
  };

  //************************* Handel All Functions ************************//

  // Error Handler
  const errorHandler = () => {
    let errorField = {};
    let extraError = {
      password: false,
      confirmPassword: false,
    };
    let formIsValid = true;
    let passwordFormat =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%*~^&+=!]).{8,}$/;

    if (!passwordFields.oldPassword) {
      formIsValid = false;
      errorField["oldPassword"] = "Required *";
    }

    if (!passwordFields.password) {
      formIsValid = false;
      errorField["password"] = "Required *";
    } else if (!passwordFields?.password?.match(passwordFormat)) {
      formIsValid = false;
      errorField["password"] =
        "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number, and 1 special character in (!@#$%^&*)";
      extraError["password"] = true;
    }

    if (!passwordFields?.confirmPassword) {
      formIsValid = false;
      errorField["confirmPassword"] = "Required *";
    } else if (passwordFields.confirmPassword !== passwordFields?.password) {
      formIsValid = false;
      errorField["confirmPassword"] =
        "Confirm password should be equal to password!";
      extraError["confirmPassword"] = true;
    }

    setExtraErrorField(extraError);
    setPasswordFieldValidationError(errorField);

    return formIsValid;
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setPasswordFields({ ...passwordFields, [name]: value });
  };

  // onSubmitHandler
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (errorHandler()) {
      try {
        setSubmitButtonLoading(true);
        const { data } = await changePasswordAPI(passwordFields);
        if (data.code === 200) {
          resetAllStateHandler();
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitButtonLoading(false);
      }
    }
  };

  //****************************** Handle HTML CODE ***********************//
  return (
    <>
      <div className="change_password">
        <h5>Change Password</h5>
        <form>
          <div className="form_field password">
            <Input
              name="oldPassword"
              type={passwordType}
              onChange={onChangeHandler}
              placeholder="Current password"
              value={passwordFields?.oldPassword}
              error={passwordFieldValidationError?.oldPassword}
            />
            <img src={lockIcon} alt="" className="icon" />
            <span className="password_icons">{showIcon}</span>
          </div>
          <div className="form_field password">
            <Input
              name="password"
              type={passwordType1}
              placeholder="New Password"
              onChange={onChangeHandler}
              value={passwordFields?.password}
              extraError={extraErrorFiled?.password}
              error={passwordFieldValidationError?.password}
            />
            <img src={lockIcon} alt="" className="icon" />
            <span className="password_icons">{showIcon1}</span>
          </div>

          <div className="form_field password">
            <Input
              type={passwordType2}
              name="confirmPassword"
              onChange={onChangeHandler}
              placeholder="Confirm New Password"
              value={passwordFields?.confirmPassword}
              extraError={extraErrorFiled?.confirmPassword}
              error={passwordFieldValidationError?.confirmPassword}
            />
            <img src={lockIcon} alt="" className="icon" />
            <span className="password_icons">{showIcon2}</span>
          </div>
          <p>
            {" "}
            <IoMdInformationCircleOutline /> Please use letters, numbers and
            accepted symbols only, with no spaces, minimum 8, maximum 32
            characters.
          </p>
          <div className="button_wrap button_right">
            {submitButtonLoading ? (
              <LoadingButton
                disabled
                className={"disabled"}
                loading={submitButtonLoading}
              />
            ) : (
              <Button onClick={onSubmitHandler}>Save Changes</Button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
