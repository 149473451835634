import { Instance as axios, setHeaderWithImage, setToken } from "../instance";

// Person get kyc details API
export const getKYCDetailAPI = async () => {
  return axios.get(`api/getKyc`, setToken());
};

// Person individual kyc register api
export const individualKYCUploadAPI = async (data) => {
  return axios.post(`api/kycCreate`, data, setHeaderWithImage());
};

// Person individual kyc Update api
export const individualKYCUpdateAPI = async (data) => {
  return axios.put(`api/kycUpdate`, data, setHeaderWithImage());
};

// Person Company kyc register api
export const companyKYCUploadAPI = async (data) => {
  return axios.post(`api/kycCreate`, data, setHeaderWithImage());
};
