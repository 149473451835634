import React, { useEffect, useState } from "react";
import { IoIosInformationCircle } from "react-icons/io";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import {
  placeholder2,
  placeholder3,
  shieldImg,
} from "../../../../../../../assets/images";

const CompanyKycDetails = ({
  detailsData,
  getDetailsHandler,
  setGetKYCDetails,
}) => {
  // handle all functions //

  // get data handler
  useEffect(() => {
    if (!detailsData) {
      getDetailsHandler();
    }
  }, [detailsData?.id]);

  const docTypeNameHandler = (type) => {
    if (type === "panFront") {
      return "Pan Card";
    } else if (type === "tanFront") {
      return "Tan Card";
    } else {
      return "not found";
    }
  };

  // function for handle kyc status html code
  const kycStatusStringHandler = (status) => {
    if (status === 0) {
      return (
        <p className="pending">
          <IoIosInformationCircle /> <span>Verification Rejected</span>
        </p>
      );
    } else if (status === 1) {
      return (
        <p className="pending">
          <IoIosInformationCircle /> <span>Verification Pending</span>
        </p>
      );
    } else if (status === 2) {
      return (
        <p className="success">
          <RiVerifiedBadgeFill />
          <span>Verified</span>
        </p>
      );
    } else {
      return (
        <p className="pending">
          <IoIosInformationCircle />
          <span>------------</span>
        </p>
      );
    }
  };

  //*************** Handle HTML CODE ****************//

  return (
    <>
      <h5>KYC VERIFICATION</h5>
      <p>KYC Details</p>
      <div className="kyc_details">
        <div className="top_content">
          <h4>
            <img src={shieldImg} alt="" />
            KYC & GST Verfication
          </h4>
          {kycStatusStringHandler(detailsData?.isApproved)}
        </div>
        <p>
          The KYC & GST Verification is in progress. it will take 1-2 days to
          complete
        </p>
        <ul className="user_detail">
          <li>
            <span>Name</span>
            <strong>{detailsData?.customerName}</strong>
          </li>
          <li>
            <span>Company Type</span>
            <strong>{detailsData?.companyType}</strong>
          </li>
          <li>
            <span>Business Type</span>
            <strong>{detailsData?.businessType}</strong>
          </li>
          <li>
            <span>GST No.</span>
            <strong>{detailsData?.GSTNo}</strong>
          </li>
          <li>
            <span>TAN No.</span>
            <strong>{detailsData?.tanNo}</strong>
          </li>
        </ul>
      </div>

      {/* <div className="upload_doc">
        <ul>
          <li>
            <small>Document Type</small>
            <span> Aadhaar card (Front)</span>
            <img src={placeholder3} alt="" />
          </li>
          <li>
            <small>Document Type</small>
            <span> Aadhaar card (Back)</span>
            <img src={placeholder2} alt="" />
          </li>
        </ul>
      </div> */}

      <form className="upload_doc">
        {/*-- Gst Doc --*/}
        <div className="form_field_wrap">
          <div className="form_field">
            <label className="label">GST Doc</label>
          </div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field">
            <div className="preview">
              <img src={detailsData?.document1FrontSide} alt="" />
            </div>
          </div>
        </div>

        {/* Select any doc */}
        <div className="form_field_wrap">
          <div className="form_field">
            <label className="label">
              {docTypeNameHandler(detailsData?.document2Type)}
            </label>
          </div>
        </div>
        {/* Other Doc image */}
        <div className="form_field_wrap">
          <div className="form_field">
            <div className="preview">
              <img src={detailsData?.document2FrontSide} alt="" />
            </div>
          </div>
          <div className="form_field">
            <div className="preview">
              <img src={detailsData?.document2BackSide} alt="" />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CompanyKycDetails;
