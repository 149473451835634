import { GlobalUserContext } from "context/GlobalContext";
import React, { useContext, useEffect } from "react";

const ChatBot = () => {
  const { userProfileData } = useContext(GlobalUserContext);

  useEffect(() => {
    if (userProfileData?.email) {
        
      // Configuration for Hello Chat Widget
      const helloConfig = {
        widgetToken: "1a281",
        hide_launcher: false,
        show_widget_form: true,
        show_close_button: true,
        launch_widget: false,
        show_send_button: true,
        unique_id: userProfileData?.userId || "0",
        name: userProfileData?.name || "",
        //   number: '1234567890',
        mail: userProfileData?.email || "",
        //   country: 'USA', // Optional
        //   city: 'New York', // Optional
        //   region: 'NY', // Optional
      };

      // Dynamically add the MSG91 chat widget script to the DOM
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://control.msg91.com/app/assets/widget/chat-widget.js";
      script.async = true;

      // Once the script loads, initialize the chat widget with the configuration
      script.onload = () => {
        if (window.initChatWidget) {
          window.initChatWidget(helloConfig, 5000);
        }
      };

      // Append the script to the document body
      document.body.appendChild(script);

      // Cleanup script on component unmount to prevent memory leaks
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [userProfileData]); // Empty dependency array means this effect runs once after initial render

  return (
    <div>
      {/* The chat widget will be dynamically loaded here */}
      {/* Optionally, you can add a message or loader here */}
    </div>
  );
};

export default ChatBot;
