import { Instance as axios, setToken } from "../instance";

// get state list api
export const stateListingApi = async () => {
  return axios.get(`api/getState?country=india`, setToken());
};

// get city list api
export const cityListingApi = async (state) => {
  return axios.get(`api/getCity?state=${state}`, setToken());
};

// get address list API function
export const addressListingApi = async () => {
  return axios.get(`api/getAddress`, setToken());
};

// create address API function
export const createAddressAPI = async (data) => {
  return axios.post(`api/createAddress`, data, setToken());
};

// Edit Address APi Function
export const editAddressAPI = async (data) => {
  return axios.put(`api/updateAddress`, data, setToken());
};

// Delete Address APi Function
export const deleteAddressAPI = async (recordId) => {
  return axios.delete(`api/deleteAddress/${recordId}`, setToken());
};
