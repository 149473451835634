import { downloadIcon } from "assets/icons";
import { Button } from "components/form";
import { getDataToLocal } from "config/utils";
import { GlobalOrderContext } from "context/OrderContext";
import moment from "moment";
import { useContext } from "react";
import { FaArrowUp } from "react-icons/fa6";
import { IoMdEyeOff } from "react-icons/io";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadOrderLabelAPI } from "services/user-controllers/order-controller";

export let loadingButton = false;

export const downloadLabelHandler = async (e, itemId) => {
  const labelId = getDataToLocal("labelId");
  try {
    loadingButton = true;
    const { data } = await downloadOrderLabelAPI(labelId, itemId);
    if (data?.code === 200) {
      const orderLabelUrl = data.data.url;
      window.open(orderLabelUrl, "_blank");
    } else {
      toast?.error(data?.message);
    }
  } catch (err) {
    console.error(err);
  } finally {
    loadingButton = false;
  }
};

// filterOperators dropdown
export const filterOperatorDropdown = [
  { id: 200, name: "Contains", value: "contains" },
  { id: 201, name: "Equals", value: "equals" },
  { id: 202, name: "Starts With", value: "starts with" },
  { id: 203, name: "Ends With", value: "ends with" },
  { id: 204, name: "Is Empty", value: "is empty" },
  { id: 205, name: "Is Not Empty", value: "is not empty" },
  { id: 206, name: "Is Any Of", value: "is any of" },
];

// Function for generate random Id
export function generateId() {
  let id = Math.floor(Math.random() * 10000);
  return id;
}



// order filter column dropdown
export const myOrderFilterColumns = [
  {
    enable: true,
    id: generateId(),
    name: "Order ID",
    value: "Order_id",
  },
  {
    enable: true,
    name: "Status",
    id: generateId(),
    value: "OrderStatus",
  },
  {
    enable: true,
    id: generateId(),
    value: "createdAt",
    name: "Booking Date",
  },
  {
    enable: true,
    id: generateId(),
    name: "Payment Mode",
    value: "paymentMode",
  },
  {
    enable: true,
    id: generateId(),
    name: "From Pin",
    value: "Frompincode",
  },
  {
    enable: true,
    name: "To Pin",
    id: generateId(),
    value: "Topincode",
  },
  {
    name: "ETA",
    enable: true,
    id: generateId(),
    value: "ExpectedDelivery",
  },
  {
    enable: true,
    id: generateId(),
    value: "LSPDocketNo",
    name: "LSP Docket No.",
  },
  {
    enable: true,
    id: generateId(),
    value: "chargable_weight",
    name: "Chargeable Weight",
  },
  {
    enable: true,
    id: generateId(),
    value: "Docketcharge",
    name: "Docket Charge",
  },
  {
    enable: true,
    id: generateId(),
    value: "handlingCharge",
    name: "Handling Charge",
  },
  {
    enable: true,
    id: generateId(),
    name: "FOV Amount",
    value: "fov_amount",
  },
  {
    enable: true,
    id: generateId(),
    name: "FSC Amount",
    value: "Fsc_amount",
  },
  {
    enable: true,
    id: generateId(),
    name: "Green Tax",
    value: "Greentax",
  },
  {
    name: "ODA",
    enable: true,
    id: generateId(),
    value: "oda_amount",
  },
  {
    name: "Rate",
    enable: true,
    value: "rate",
    id: generateId(),
  },
  {
    enable: true,
    id: generateId(),
    name: "Taxable Amount",
    value: "taxableAmount",
  },
  {
    enable: true,
    id: generateId(),
    name: "GST Amount",
    value: "gst_Amount",
  },
  {
    enable: true,
    id: generateId(),
    value: "totalAmount",
    name: "Total Amount",
  },

  {
    sort: "ASC",
    enable: true,
    id: generateId(),
    value: "Pickupaddress",
    name: "Pickup Address",
  },
  {
    enable: true,
    id: generateId(),
    name: "Pickup Name",
    value: "Pickuppersonname",
  },
  {
    enable: true,
    id: generateId(),
    value: "PickupCompanyName",
    name: "Pickup Company Name",
  },
  {
    enable: true,
    id: generateId(),
    value: "Pickuppersonmobile",
    name: "Pickup Contact No.",
  },
  {
    enable: true,
    id: generateId(),
    value: "deliveryaddress",
    name: "Delivery Address",
  },
  {
    enable: true,
    id: generateId(),
    value: "deliverypersonname",
    name: "Delivery Person Name",
  },
  {
    enable: true,
    id: generateId(),
    name: "Delivery Contact",
    value: "deliverypersonmobile",
  },
  {
    enable: true,
    id: generateId(),
    value: "OtherInfromation",
    name: "Other Information",
  },
  {
    enable: true,
    id: generateId(),
    value: "Customername",
    name: "Customer Information",
  },
  {
    enable: true,
    id: generateId(),
    value: "Order_id",
    name: "View Details",
  },
  {
    enable: true,
    id: generateId(),
    value: "imagepath",
    name: "Customer Item Invoice",
  },
  {
    name: "POD",
    enable: true,
    value: "PODUrl",
    id: generateId(),
  },
  {
    enable: true,
    id: generateId(),
    value: "Order_id",
    name: "Submit Review",
  },
].filter((column) => column.enable);

//------------------------------------------------- Order Details Page Variables-----------------------------------------------//

// Order details Column listing data
export const myOrderDetailsColumns = [
  {
    sort: "DESC",
    enable: true,
    key: "Orderid",
    id: generateId(),
    name: "Order ID",
    icon: <FaArrowUp />,
  },
  {
    sort: "DESC",
    enable: true,
    key: "Itemid",
    name: "Item ID",
    id: generateId(),
    icon: <FaArrowUp />,
  },
  {
    sort: "DESC",
    enable: true,
    key: "Length",
    name: "Length",
    id: generateId(),
    icon: <FaArrowUp />,
  },
  {
    sort: "DESC",
    enable: true,
    name: "Width",
    key: "Breadth",
    id: generateId(),
    icon: <FaArrowUp />,
  },
  {
    sort: "DESC",
    enable: true,
    key: "Height",
    name: "Height",
    id: generateId(),
    icon: <FaArrowUp />,
  },
  {
    enable: true,
    key: "Itemid",
    actionId: "id",
    id: generateId(),
    name: "Download Label",
    formatHandler: function (item, col) {
      console.log("ARR ID", this?.id);
      console.log("COL  ID", col?.id);
      if (this?.id === col?.id && loadingButton) {
        return (
          <td key={col.key} className="nowrap">
            wait...
            {/* <button
              type="button"
              onClick={(e) => downloadLabelHandler(e, item[col.actionId])}
            >
              <img src={downloadIcon} alt="" />
            </button> */}
          </td>
        );
      } else {
        return (
          <td key={col.key} className="nowrap">
            <button
              type="button"
              onClick={(e) => downloadLabelHandler(e, item[col.actionId])}
            >
              <img src={downloadIcon} alt="" />
            </button>
          </td>
        );
      }
    },
  },
].filter((column) => column.enable);

// Order Details Filter Column DropDown
export const myOrderDetailsFilterColumns = [
  {
    enable: true,
    id: generateId(),
    value: "Orderid",
    name: "Order ID",
  },
  {
    enable: true,
    value: "Itemid",
    name: "Item ID",
    id: generateId(),
  },
  {
    enable: true,
    name: "Length",
    value: "Length",
    id: generateId(),
  },
  {
    enable: true,
    name: "Width",
    value: "Breadth",
    id: generateId(),
  },
  {
    enable: true,
    name: "Height",
    value: "Height",
    id: generateId(),
  },
  {
    enable: false,
    value: "Itemid",
    id: generateId(),
    name: "Download Label",
  },
].filter((column) => column.enable);
