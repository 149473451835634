import React, { useState } from "react";
import CustomModal from "./CustomModal";
import { deleteImg, logOutImg } from "../../assets/images";
import { Button, LoadingButton } from "../form";

const DeleteModal = ({
  show,
  content,
  handleClose,
  handleSubmit,
  buttonLoading = false,
  className,
  type
}) => {
  return (
    <>
      <CustomModal
        show={show}
        handleClose={handleClose}
        className={`deleteModal sm ${className}`}
      >
        <img src={type === "logout" ? logOutImg:deleteImg} alt="" />
        <h4>
          {content ? content : "Are you sure you want to delete this Service?"}
        </h4>
        <div className="button_wrap button_center">
          <Button className={"light"} onClick={handleClose}>
            No
          </Button>

          {/* <Button onClick={handleSubmit}>Yes</Button> */}

          {!buttonLoading ? (
            <Button onClick={handleSubmit}>
              Yes
            </Button>
          ) : (
            <LoadingButton
              disabled
              loading={buttonLoading}
              className={"disabled"}
            />
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default DeleteModal;
