import { Instance as axios } from "../instance";

// Person OTP send API function
export const otpSendApi = async (data) => {
  return axios.post(`/api/sendOtp`, data);
};

// Person Login OTP Send API Function
export const personLoginApi = async (data) => {
  return axios.post(`/api/login`, data);
};

// Person OTP verify and register API function
export const verifyOtpAndRegisterAPI = async (data) => {
  return axios.post(`/api/otpVerifyCreate`, data);
};

// Person OTP verify and Login API function
export const verifyOTPAndLoginAPI = async (data) => {
  return axios.post(`/api/mobileLogin`, data);
};

// Person Forget password link generator API Function
export const forgetPassLinkSendAPI = async (data) => {
  return axios.post(`/api/forgetPassword`, data);
};

// Person Reset-Password API Function
export const resetPasswordAPI = async (token, data) => {
  return axios.post(`/api/resetPassword?token=${token}`, data);
};

// Person Sign up Api Function (we are not using it now)
export const personSignUp = async (data) => {
  return axios.post(`/api/createUser`, data);
};

