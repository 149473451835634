import { TablePlaceholder } from "components/form";
import React from "react";

const TableBody = ({ columnsArray, listingData, ...props }) => {
  return (
    <tbody>
      {listingData.length > 0 ? (
        listingData.map((item, index) => (
          <tr key={index}>
            {columnsArray?.length > 0 &&
              columnsArray
                .filter((col) => !col.isHidden)
                .map((col) => (
                  <>
                    {col.formatHandler ? (
                      col.formatHandler(item, col, index)
                    ) : (
                      <td key={col.key}>{item[col?.key] || "-----"}</td>
                    )}
                  </>
                ))}
          </tr>
        ))
      ) : (
        <TablePlaceholder colSpan={5} />
      )}
    </tbody>
  );
};

export default TableBody;
