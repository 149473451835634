import React, { useEffect, useState } from "react";
import { GrLocation } from "react-icons/gr";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import {
  Button,
  LoadingButton,
  RatingStar,
  SwitchButton,
  Tooltip,
} from "../../../../components/form";
import { Dropdown } from "react-bootstrap";
import { filterIcon, shortingIcon } from "../../../../assets/icons";
import {
  IoIosArrowDown,
  IoMdAdd,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { blog1 } from "../../../../assets/images";
import CustomModal from "../../../../components/modals/CustomModal";
import { Link, useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import {
  getPermissionAPI,
  getVendorForOrderAPI,
} from "../../../../services/user-controllers/order-controller";
import { useContext } from "react";
import { GlobalOrderContext } from "../../../../context/OrderContext";
import { toast } from "react-toastify";
import Placeholder from "../../../../components/placeholder/Placeholder";
import {
  decryptData,
  encryptDataHandler,
  getDataToLocal,
  setDataToLocal,
} from "../../../../config/utils";
import { GlobalUserContext } from "context/GlobalContext";

const BookOrdersListing = () => {
  const redirect = useNavigate();
  const [userID, setUserID] = useState("");

  const { vendorListingForOrder, setVendorListingForOrder } =
    useContext(GlobalUserContext);

  //****************** Handle all state *******************//
  // Function to generate unique IDs
  const generateId = () => Math.floor(Math.random() * 10000);

  const { setVendorDetail, setOrderSearchFieldData } =
    useContext(GlobalOrderContext);

  const [infoModal, setInfoModal] = useState(false);
  const [viewBreakupModal, setViewBreakupModal] = useState(false);
  const [totalShipmentWeight, setTotalShipmentWeight] = useState(0);

  const [rulesList, setRulesList] = useState([]);

  const [orderSearchVendorFields, setOrderSearchVendorFields] = useState({
    sourcePincode: "",
    destinationPincode: "",
    shipmentAmount: "",
    toPay: false,
    isB2C: false,
    items: [],
  });

  const [searchOrderFieldArray, setSearchOrderFieldArray] = useState([
    {
      id: generateId(),
      unit: "CM",
      L: null,
      B: null,
      H: null,
      boxes: null,
      boxWeight: null,
      weightUnit: "",
      isRemovedButton: false,
    },
  ]);

  const [vendorName, setVendorName] = useState("");
  const [vendorListing, setVendorListing] = useState([]);
  const [vendorBreakUps, setVendorBreakUps] = useState();
  const [tempVendorListing, setTempVendorListing] = useState([]);
  const [permissionChecker, setPermissionChecker] = useState("");

  //--------- states for filters and buttons --------//
  const [searchButtonLoading, setSearchButtonLoading] = useState(false);

  //****************** Handle all Function *******************//

  // Function for append row of order details for search
  const appendOrderRowHandler = (item, indx) => {
    if (!item?.unit) {
      toast.error("Please Select Item Unit*");
      return;
    } else if (!item?.L) {
      toast.error("Please Enter Item Length*");
      return;
    } else if (!item?.B) {
      toast.error("Please Enter Item Width*");
      return;
    } else if (!item?.H) {
      toast.error("Please Enter Item Height*");
      return;
    } else if (!item?.boxes) {
      toast.error("Please Enter Number Of Boxes Of Item*");
      return;
    } else if (!item?.boxWeight) {
      toast.error("Please Enter Weight Of Box *");
      return;
    } else {
      let updateItem = { ...item, isRemovedButton: true };
      let searchArrayClone = searchOrderFieldArray?.map((el, i) => {
        if (i === indx) {
          return updateItem;
        } else {
          return el;
        }
      });

      const orderDetailsFields = {
        id: generateId(),
        unit: "CM",
        L: null,
        B: null,
        H: null,
        boxes: null,
        boxWeight: null,
        weightUnit: "",
        isRemovedButton: false,
      };

      // const orderFieldsArray = searchOrderFieldArray.map((el) => {
      //   return {
      //     ...el,
      //     isRemovedButton: true,
      //   };
      // });
      // const newArray = [...searchOrderFieldArray, orderDetailsFields];

      const newArray = [...searchArrayClone, orderDetailsFields];

      setSearchOrderFieldArray(newArray);
    }
  };

  // delete orderDetails Handler
  const removeOrderRowHandler = (id) => {
    const filterArray = searchOrderFieldArray?.filter((el) => el?.id !== id);
    setSearchOrderFieldArray(filterArray);
  };

  const isHighestAvgRating = (arr) => {
    // Use reduce to find the index with the highest avgRating
    return arr.reduce((highestIndex, currentItem, currentIndex, array) => {
      return parseFloat(currentItem.avgRating) >
        parseFloat(array[highestIndex].avgRating)
        ? currentIndex
        : highestIndex;
    }, 0); // Initialize with index 0
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "sourcePincode") {
      const numericZipCodeValue = value.replace(/[^0-9]/g, "");
      setOrderSearchVendorFields({
        ...orderSearchVendorFields,
        sourcePincode: numericZipCodeValue,
      });
    } else if (name === "destinationPincode") {
      const numericZipCodeValue = value.replace(/[^0-9]/g, "");
      setOrderSearchVendorFields({
        ...orderSearchVendorFields,
        destinationPincode: numericZipCodeValue,
      });
    } else {
      setOrderSearchVendorFields({ ...orderSearchVendorFields, [name]: value });
    }
  };

  // orderDetailsOnChange handler for handle value of orderRow onChange
  const orderDetailsOnChangeHandler = (e, id) => {
    const { name, value } = e.target;
    const updatedOrderDetailsArray = searchOrderFieldArray?.map((el) =>
      el.id === id ? { ...el, [name]: value } : el
    );
    setSearchOrderFieldArray(updatedOrderDetailsArray);
  };

  // shipmentWeight total handler
  const shipmentWeightCountHandler = () => {
    let total = 0;
    searchOrderFieldArray.forEach((el) => {
      total = total + Number(el?.boxWeight) * Number(el?.boxes);
    });
    if (total > 0) {
      return total;
    }
  };

  // Filter Handler
  const filterHandler = (keyName, value) => {
    if (keyName === "servicetype") {
      if (!value) {
        setVendorListing(vendorListingForOrder);
      } else {
        let cloneOfVendorList = vendorListingForOrder?.map((el) => el);
        let filterCloneList = cloneOfVendorList.filter(
          (el) => el?.[keyName] === value
        );
        setVendorListing(filterCloneList);
      }
    } else if (keyName === "avgRating") {
      if (!value) {
        setVendorListing(vendorListingForOrder);
      } else {
        let cloneOfVendorList = vendorListingForOrder?.map((el) => el);
        const filteredVendorArray = cloneOfVendorList.filter(
          (el) => el?.[keyName] >= value
        );
        setVendorListing(filteredVendorArray);
      }
    } else if (keyName === "totalAmount") {
      if (!value) {
        setVendorListing(vendorListingForOrder);
      } else {
        if (value === "ASC") {
          let cloneOfVendorList = vendorListingForOrder?.map((el) => el);
          const filteredVendorArray = cloneOfVendorList.sort(
            (a, b) => parseFloat(a.totalAmount) - parseFloat(b.totalAmount)
          );
          setVendorListing(filteredVendorArray);
        }

        if (value === "DESC") {
          let cloneOfVendorList = vendorListingForOrder?.map((el) => el);
          const filteredVendorArray = cloneOfVendorList.sort(
            (a, b) => parseFloat(b.totalAmount) - parseFloat(a.totalAmount)
          );
          setVendorListing(filteredVendorArray);
        }
      }
    } else if (keyName === "tat") {
      if (!value) {
        setVendorListing(vendorListingForOrder);
      } else {
        if (value === "ASC") {
          let cloneOfVendorList = vendorListingForOrder?.map((el) => el);
          const filteredVendorArray = cloneOfVendorList.sort(
            (a, b) => parseFloat(a.tat) - parseFloat(b.tat)
          );
          setVendorListing(filteredVendorArray);
        }

        if (value === "DESC") {
          let cloneOfVendorList = vendorListingForOrder?.map((el) => el);
          const filteredVendorArray = cloneOfVendorList.sort(
            (a, b) => parseFloat(b.tat) - parseFloat(a.tat)
          );
          setVendorListing(filteredVendorArray);
        }
      }
    }

    // tat
  };

  // errorHandler
  const errorHandler = () => {
    return true;
  };

  // reDirectHandler
  const reDirectHandler = (item) => {
    setVendorDetail(item);
    const dataForLocal = item;
    const encryptedData = encodeURIComponent(encryptDataHandler(dataForLocal));
    setDataToLocal("vendorDetailsForOrder", encryptedData);
    redirect("/user/dashboard/book-orders/order-summery");
  };

  // get VendorListing or order handler
  const getVendorListingHandler = async (e) => {
    e.preventDefault();
    if (errorHandler()) {
      setSearchButtonLoading(true);

      const orderFields = {
        ...orderSearchVendorFields,
        items: searchOrderFieldArray?.map((el) => {
          return {
            unit: el?.unit || "",
            L: el?.L || null,
            B: el?.B || null,
            H: el?.H || null,
            boxes: el?.boxes || null,
            boxWeight: el?.boxWeight || null,
            weightUnit: el?.weightUnit || "",
          };
        }),
      };

      setOrderSearchFieldData({
        ...orderFields,
        userId: userID,
        shipmentWeight: shipmentWeightCountHandler(),
        rateType:
          permissionChecker === "perkg"
            ? 1
            : permissionChecker === "both"
            ? 1
            : 2,
      });

      try {
        const sendData = {
          ...orderFields,
          userId: userID,
          shipmentWeight: shipmentWeightCountHandler(),
          rateType:
            permissionChecker === "perkg"
              ? 1
              : permissionChecker === "both"
              ? 1
              : 2,
        };

        const encryptedData = encodeURIComponent(encryptDataHandler(sendData));
        setDataToLocal("orderSearchDetail", encryptedData);
        const { data } = await getVendorForOrderAPI(sendData);
        if (data?.code === 200) {
          if (data?.data?.length > 0) {
            setVendorListing(data?.data);
            setVendorListingForOrder(data?.data);
          } else {
            toast.error("No Vendor Available For These Pincode !");
            setVendorListing([]);
          }
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSearchButtonLoading(false);
      }
    }
  };

  // useEffect for get login user's id
  useEffect(() => {
    const data = getDataToLocal("authDetail");
    const id = data?.userDetails?.userId;
    setUserID(id);

    // eslint-disable-next-line
  }, []);

  // get Vendor listing by Rate Type
  const getVendorListingByRateHandler = async (type) => {
    // e.preventDefault();
    if (errorHandler()) {
      // setSearchButtonLoading(true);

      const orderFields = {
        ...orderSearchVendorFields,
        items: searchOrderFieldArray?.map((el) => {
          return {
            unit: el?.unit || "",
            L: el?.L || null,
            B: el?.B || null,
            H: el?.H || null,
            boxes: el?.boxes || null,
            boxWeight: el?.boxWeight || null,
            weightUnit: el?.weightUnit || "",
          };
        }),
      };

      setOrderSearchFieldData({
        ...orderFields,
        userId: userID,
        shipmentWeight: shipmentWeightCountHandler(),
      });

      try {
        let sendData = {};
        if (!type) {
          sendData = {
            ...orderFields,
            userId: userID,
            shipmentWeight: shipmentWeightCountHandler(),
          };
        } else {
          sendData = {
            ...orderFields,
            userId: userID,
            rateType: type,
            shipmentWeight: shipmentWeightCountHandler(),
          };
        }

        const encryptedData = encodeURIComponent(encryptDataHandler(sendData));
        setDataToLocal("orderSearchDetail", encryptedData);
        const { data } = await getVendorForOrderAPI(sendData);
        if (data?.code === 200) {
          if (data?.data?.length > 0) {
            setVendorListing(data?.data);
            setVendorListingForOrder(data?.data);
          } else {
            toast.error("No Vendor Available For These Pincode !");
            setVendorListing([]);
          }
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        console.error(err);
      }
      // finally {
      //   setSearchButtonLoading(false);
      // }
    }
  };

  // get Permission of per kg or per box handler
  const getPerKgBoxPermissionHandler = async () => {
    try {
      const { data } = await getPermissionAPI();
      console.log("I AM DATA PERMISSION GET RESPONSE", data);
      if (data?.code === 200) {
        if (data?.data?.rateType !== "both") {
          setPermissionChecker(data?.data?.rateType);
        } else {
          setPermissionChecker(data?.data?.rateType);
        }
      } else {
        console.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect for get permission of perKg or perBox
  useEffect(() => {
    getPerKgBoxPermissionHandler();

    // eslint-disable-next-line
  }, []);

  // console.log("ODER Fields ARRay", searchOrderFieldArray);
  console.log("VENDOR LISTING =======>>", vendorListing);

  //****************** Handle HTML CODE *******************//
  return (
    <>
      <div className="book_order_list">
        <div className="dashboard_title">
          <h4>Book Orders</h4>
        </div>

        <div className="fill_order_details _page">
          <div className="location_detail">
            <div className="pinCode">
              <ul>
                <li>
                  <label className="label">Pickup location*</label>
                  <input
                    type="text"
                    maxLength={6}
                    name="sourcePincode"
                    placeholder="Pincode"
                    onChange={onChangeHandler}
                    className="input form-control"
                    value={orderSearchVendorFields?.sourcePincode}
                  />
                  <GrLocation className="icon" />
                </li>
                <li>
                  <label className="label">Destinations Pincode*</label>
                  <input
                    type="text"
                    maxLength={6}
                    placeholder="Pincode"
                    name="destinationPincode"
                    onChange={onChangeHandler}
                    className="input form-control"
                    value={orderSearchVendorFields?.destinationPincode}
                  />
                  <GrLocation className="icon" />
                </li>
              </ul>
            </div>
            <div className="shipmentAmount">
              <label className="label">Shipment Amount*</label>
              <input
                type="number"
                name="shipmentAmount"
                onChange={onChangeHandler}
                className="input form-control"
                value={orderSearchVendorFields?.shipmentAmount}
              />
              <RiMoneyRupeeCircleLine className="icon" />
            </div>
          </div>

          {/* Handle Order row array  */}
          <div className="shipment_box_detail">
            <div className="append_section_wrap">
              {searchOrderFieldArray.map((el, i) => (
                <div className="append_section" key={el?.id}>
                  <div className="form_field">
                    <label className="label">Dimention unit*</label>
                    <select
                      name="unit"
                      value={el?.unit}
                      className="input form-control"
                      onChange={(e) => orderDetailsOnChangeHandler(e, el.id)}
                    >
                      <option value={"CM"}>CM</option>
                      <option value={"INCH"}>INCH</option>
                      <option value={"FEET"}>FEET</option>
                    </select>
                  </div>

                  <ul>
                    <li>
                      <label className="label">length*</label>
                      <input
                        name="L"
                        type="number"
                        value={el?.L}
                        className="input form-control"
                        onChange={(e) => orderDetailsOnChangeHandler(e, el.id)}
                      />
                    </li>
                    <li>
                      <label className="label">Width*</label>
                      <input
                        name="B"
                        type="number"
                        value={el?.B}
                        className="input form-control"
                        onChange={(e) => orderDetailsOnChangeHandler(e, el.id)}
                      />
                    </li>
                    <li>
                      <label className="label">height*</label>
                      <input
                        name="H"
                        type="number"
                        value={el?.H}
                        className="input form-control"
                        onChange={(e) => orderDetailsOnChangeHandler(e, el.id)}
                      />
                    </li>
                    <li>
                      <label className="label">No. of boxes*</label>
                      <input
                        type="number"
                        name="boxes"
                        value={el?.boxes}
                        className="input form-control"
                        onChange={(e) => orderDetailsOnChangeHandler(e, el.id)}
                      />
                    </li>
                    <li>
                      <label className="label">Weight per box*</label>
                      <input
                        type="number"
                        name="boxWeight"
                        value={el?.boxWeight}
                        className="input form-control"
                        onChange={(e) => orderDetailsOnChangeHandler(e, el.id)}
                      />
                    </li>
                  </ul>
                  {el?.isRemovedButton ? (
                    <Button
                      key={el.id}
                      className={"append_button input"}
                      onClick={() => removeOrderRowHandler(el.id)}
                    >
                      <FaTimes className="delete" />
                    </Button>
                  ) : (
                    <Button
                      key={el.id}
                      className={"append_button input"}
                      onClick={() => appendOrderRowHandler(el, i)}
                    >
                      <IoMdAdd />
                    </Button>
                  )}
                </div>
              ))}
            </div>

            <div className="form_field">
              <label className="label">Shipment Weight*</label>
              <input
                disabled
                type="number"
                placeholder="Weight"
                className="input form-control disabled"
                defaultValue={shipmentWeightCountHandler()}
              />
            </div>
            {/* <p><span>Topay</span> <SwitchButton /></p> */}
          </div>
          <div className="button_wrap button_right pt-0">
            <LoadingButton
              disable={searchButtonLoading}
              loading={searchButtonLoading}
              onClick={getVendorListingHandler}
              className={searchButtonLoading ? "disable" : ""}
            >
              Search
            </LoadingButton>
          </div>
        </div>

        {/*------ Handle all filters HTML CODE ------*/}
        {/* {vendorListing?.length > 0 && ( */}
        <div className="table_filter_wrap">
          <div className="filter_buttons">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={filterIcon} alt="" />
                Filters
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("servicetype", "")}
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("servicetype", "air")}
                >
                  Air
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("servicetype", "water")}
                >
                  Water
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("servicetype", "surface")}
                >
                  Surface
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={shortingIcon} alt="" />
                Short by Price
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("totalAmount", "")}
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("totalAmount", "ASC")}
                >
                  Price Low to Hight
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("totalAmount", "DESC")}
                >
                  Price High to Low
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={shortingIcon} alt="" />
                TAT
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("tat", "")}
                >
                  All
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("tat", "ASC")}
                >
                  Low to Hight
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("tat", "DESC")}
                >
                  High to Low
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Customer Ratings <IoIosArrowDown />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("avgRating", "")}
                >
                  All
                </Dropdown.Item>

                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("avgRating", Number(5))}
                >
                  5 Start & Above
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("avgRating", Number(3))}
                >
                  3 Start & Above
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("avgRating", Number(2))}
                >
                  2 Start & Above
                </Dropdown.Item>
                <Dropdown.Item
                  as={"span"}
                  onClick={() => filterHandler("avgRating", Number(1))}
                >
                  1 Start & Above
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {permissionChecker === "both" && (
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Rate Type
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item
                    as={"span"}
                    onClick={() => getVendorListingByRateHandler(0)}
                  >
                    All
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    as={"span"}
                    onClick={() => getVendorListingByRateHandler(1)}
                  >
                    Per Kg
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={"span"}
                    onClick={() => getVendorListingByRateHandler(2)}
                  >
                    Per Box
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        {/* )} */}

        {/*------------ Handle Vendor Listing HTML CODE ----------*/}
        <div className="orderListing">
          {vendorListing?.length > 0 ? (
            vendorListing.map((item, i) => (
              <div className="order_details _page" key={i}>
                {i === isHighestAvgRating(vendorListing) && (
                  <span className="recommend">RECOMMENDED BY MVIKAS</span>
                )}

                {/* {i === 0 && (
                  <span className="recommend">RECOMMENDED BY MVIKAS</span>
                )} */}
                <div className="content_top">
                  <div className="content_left">
                    {item?.imagePath?.length > 0 ? (
                      <img src={item?.imagePath} alt="not-found" />
                    ) : (
                      <img src={blog1} alt="not-found" />
                    )}
                    <div className="content">
                      <h4>
                        {item?.vendorName}{" "}
                        <button
                          type="button"
                          onClick={() => {
                            setRulesList(item?.rules);
                            setInfoModal(true);
                          }}
                        >
                          <IoMdInformationCircleOutline />
                        </button>
                      </h4>
                      <RatingStar
                        readonly
                        initialRating={item?.rating}
                        user={`${item?.rating} Rating`}
                      />
                    </div>
                  </div>
                  <div className="price">
                    <span>Total Amount</span>
                    <h3>{item?.totalAmount || "----"}</h3>
                  </div>
                </div>
                <div className="content_bottom">
                  <ul>
                    <li>
                      <span>Service name</span>
                      <b>{item?.serviceName || "-----"}</b>
                    </li>
                    <li>
                      <span>Service Type</span>
                      <b>{item?.servicetype || "------"}</b>
                    </li>
                    <li>
                      <span>Taxable Amount</span>
                      <b>{item?.taxableAmount || "-----"}</b>
                    </li>
                    <li>
                      <span>GST Amount</span>
                      <b>{item?.gst_Amount || "-------"}</b>
                    </li>
                    <li>
                      <span>ETA</span>
                      <b>{item?.ExpectedDelivery || "N/A"} </b>
                    </li>
                  </ul>
                  <div className="button_wrap">
                    <Button
                      className={"light"}
                      onClick={() => {
                        setVendorName(item?.vendorName);
                        setVendorBreakUps(item?.breakups);
                        setViewBreakupModal(true);
                      }}
                    >
                      View Breakups
                    </Button>
                    <span
                      className="button"
                      onClick={() => reDirectHandler(item)}
                      // to="/user/dashboard/book-orders/order-summery"
                    >
                      Book
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Placeholder content={"Data Not Found"} />
          )}
        </div>
      </div>

      {/* View breakup modal */}
      <CustomModal
        show={viewBreakupModal}
        handleClose={() => setViewBreakupModal(false)}
        className={"view_breakup_charges_modal"}
      >
        <h3>{vendorName}</h3>
        <ul>
          {vendorBreakUps &&
            Object.keys(vendorBreakUps).map((keyName, i) => (
              <li key={i}>
                <h5>{keyName}</h5>
                <h5>{vendorBreakUps[keyName]}</h5>
              </li>
            ))}
        </ul>
      </CustomModal>

      {/* Tooltip modal */}
      <CustomModal show={infoModal} handleClose={() => setInfoModal(false)}>
        <h5>Rules</h5>

        <ul>
          {rulesList?.length > 0 &&
            rulesList?.map((el, i) => (
              <li className="mb-2">
                {i + 1}. {el?.value || "----"}
              </li>
            ))}
        </ul>
      </CustomModal>
    </>
  );
};

export default BookOrdersListing;
