import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
} from "../../../../../../components/form";
import {
  createBankApi,
  updateBankApi,
} from "../../../../../../services/user-controllers/bankCont";
import { toast } from "react-toastify";

const BankDetailHandler = ({
  title,
  bankList,
  callRole,
  bankDetails,
  closeBankModal,
  deleteModalHandler,
}) => {
  console.log("BANK DATA FROM DB", bankDetails);
  //*************************** Handle all state ************************//
  const [bankDetailFelids, setBankDetailFields] = useState({
    accountNumber: "",
    IFSCCode: "",
    bankName: "",
    beneficiaryName: "",
  });

  const [bankDetailValidationError, setBankDetailValidationError] = useState({
    accountNumber: "",
    IFSCCode: "",
    bankName: "",
    beneficiaryName: "",
  });

  const [extraErrorField, setExtraErrorFiled] = useState({
    accountNumber: false,
    IFSCCode: false,
  });

  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  //*********************************** Handle all functions **********************************//

  // useEffect for set previous bank detail
  useEffect(() => {
    if (callRole === "edit") {
      setBankDetailFields(bankDetails);
    }
  }, [callRole]);

  // ErrorHandler
  const errorHandler = () => {
    let errorField = {};
    let formIsValid = true;
    let extraError = {
      accountNumber: false,
      IFSCCode: false,
    };
    const bankAccountNumberFormat = /^[0-9]{9,18}$/;
    const ifscCodeFormat = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if (!bankDetailFelids.accountNumber) {
      formIsValid = false;
      errorField["accountNumber"] = "Required *";
    } else if (!bankAccountNumberFormat.test(bankDetailFelids?.accountNumber)) {
      formIsValid = false;
      extraError["accountNumber"] = true;
      errorField["accountNumber"] = "Invalid bank account number";
    }

    if (!bankDetailFelids.IFSCCode) {
      formIsValid = false;
      errorField["IFSCCode"] = "Required *";
    } else if (!ifscCodeFormat.test(bankDetailFelids?.IFSCCode)) {
      formIsValid = false;
      extraError["IFSCCode"] = true;
      errorField["IFSCCode"] = "Invalid IFSC code";
    }

    if (!bankDetailFelids.bankName) {
      formIsValid = false;
      errorField["bankName"] = "Required *";
    }

    if (!bankDetailFelids.beneficiaryName) {
      formIsValid = false;
      errorField["beneficiaryName"] = "Required *";
    }
    setExtraErrorFiled(extraError);
    setBankDetailValidationError(errorField);

    return formIsValid;
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setBankDetailFields({ ...bankDetailFelids, [name]: value });
  };

  // onSubmitHandler
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (!errorHandler()) return;

    try {
      setSubmitButtonLoading(true);

      const sendData =
        callRole === "add"
          ? bankDetailFelids
          : (({ isDefault, ...rest }) => rest)(bankDetailFelids);

      const apiCall = callRole === "add" ? createBankApi : updateBankApi;
      const { data } = await apiCall(sendData);

      if (data?.code === 200) {
        toast.success(data?.message);
        closeBankModal();
        bankList();
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitButtonLoading(false);
    }
  };

  // Delete bank record handler
  const deleteBankRecord = () => {
    closeBankModal();
    deleteModalHandler(true);
  };

  //******************************** Handle HTML CODE *************************//

  return (
    <div>
      <h3>{title} </h3>
      <div className="form_field">
        <Input
          type="text"
          name="accountNumber"
          onChange={onChangeHandler}
          Placeholder="Account Number*"
          value={bankDetailFelids?.accountNumber}
          extraError={extraErrorField?.accountNumber}
          error={bankDetailValidationError?.accountNumber}
        />
      </div>
      <div className="form_field">
        <Input
          type="text"
          name="IFSCCode"
          Placeholder="IFSC Code*"
          onChange={onChangeHandler}
          value={bankDetailFelids?.IFSCCode}
          extraError={extraErrorField?.IFSCCode}
          error={bankDetailValidationError?.IFSCCode}
        />
      </div>
      <div className="form_field">
        <Input
          type="text"
          name="bankName"
          Placeholder="Bank Name*"
          onChange={onChangeHandler}
          value={bankDetailFelids?.bankName}
          error={bankDetailValidationError?.bankName}
        />
      </div>
      <div className="form_field">
        <Input
          type="text"
          name="beneficiaryName"
          onChange={onChangeHandler}
          Placeholder="Beneficiary Name*"
          value={bankDetailFelids?.beneficiaryName}
          error={bankDetailValidationError?.beneficiaryName}
        />
      </div>
      <div className="button_wrap">
        {callRole === "edit" ? (
          <>
            <Button
              onClick={deleteBankRecord}
              className={"w-100 light black"}
            >
              Delete Account
            </Button>

            {!submitButtonLoading ? (
              <Button className={"w-100"} onClick={onSubmitHandler}>
                Save
              </Button>
            ) : (
              <LoadingButton
                disabled
                className={"w-100 disabled"}
                loading={submitButtonLoading}
              />
            )}
          </>
        ) : (
          <>
            {!submitButtonLoading ? (
              <Button className={"w-100"} onClick={onSubmitHandler}>
                Add Bank Account
              </Button>
            ) : (
              <LoadingButton
                disabled
                className={"w-100 disabled"}
                loading={submitButtonLoading}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BankDetailHandler;
