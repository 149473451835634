import React, { useState } from "react";
import {
  logisticsProblemsImg1,
  logisticsProblemsImg2,
  Logistics1Img,
  Logistics2Img,
  Logistics3Img,
  Logistics4Img,
  Logistics5Img
} from "../../../../assets/images";

const ProblemSolutionSection = () => {
  const [tab, setTab] = useState("instantQuote");
  return (
    <>
      <div className="logistic_problems home_spacing">
        <div className="container">
          <div className="tab_section">
            <div className="page_title">
              <p>Solutions</p>
              <h3>Your Logistics Problem, Our Innovative</h3>
            </div>
            <ul>
              <li>
                <button
                  type="button"
                  onClick={() => setTab("instantQuote")}
                  className={tab === "instantQuote" ? "active" : ""}
                >
                  Instant quote
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setTab("comparePrice")}
                  className={tab === "comparePrice" ? "active" : ""}
                >
                  Compare prices
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setTab("reach")}
                  className={tab === "reach" ? "active" : ""}
                >
                  Reach
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setTab("deliveryStandards")}
                  className={tab === "deliveryStandards" ? "active" : ""}
                >
                  Delivery standards
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setTab("competitivePrice")}
                  className={tab === "competitivePrice" ? "active" : ""}
                >
                  Competitive prices
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => setTab("superCenter")}
                  className={tab === "superCenter" ? "active" : ""}
                >
                  Supper center
                </button>
              </li>
            </ul>
          </div>
          <div className="tab_content">
            <div className="fixed_content">
              <p>
                Get in touch with us to resolve all your logistics challenges
                with our \industrial-standard solutions tailored to your needs.
              </p>
              <img src={logisticsProblemsImg2} alt="" className="fixed_img" />
            </div>
            <div className="content">
              {tab === "instantQuote" && (
                <>
                  <img src={Logistics1Img} alt="" />
                  <p>
                    With just a few taps, feel the ease of obtaining accurate
                    instant quotes on the products and delivery costs for all
                    your shipping needs.
                  </p>
                </>
              )}

              {tab === "comparePrice" && (
                <>
                  <img src={Logistics2Img} alt="" />
                  <p>
                    Make informed decisions on all your shipments by comparing
                    the prices from different delivery agents and logistics
                    partners.
                  </p>
                </>
              )}
              {tab === "reach" && (
                <>
                  <img src={logisticsProblemsImg1} alt="" />
                  <p>
                    Enjoy a fast delivery experience across PAN India with us!
                    Be sure of efficient and reliable delivery at every corner
                    of our country.
                  </p>
                </>
              )}
              {tab === "deliveryStandards" && (
                <>
                  <img src={Logistics3Img} alt="" />
                  <p>
                    We prioritize your logistics needs with speedy and efficient
                    delivery across PAN India in just 12 hours and 48 hours
                    across the globe.
                  </p>
                </>
              )}
              {tab === "competitivePrice" && (
                <>
                  <img src={Logistics4Img} alt="" />
                  <p>
                    Get budget-friendly PAN India delivery without having to
                    compromise on the quality at just INR 39* and at INR 450*
                    for international delivery.
                  </p>
                </>
              )}
              {tab === "superCenter" && (
                <>
                  <img src={Logistics5Img} alt="" />
                  <p>
                    For queries call 011-46039993 Contact our experts 24/7 for
                    any queries or assistance for a seamless shipping
                    experience. Feel free to dial us at 011-46039993.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProblemSolutionSection;
