
// filterOperators dropdown
export const filterOperatorDropdown = [
  { id: 200, name: "Contains", value: "contains" },
  { id: 201, name: "Equals", value: "equals" },
  { id: 202, name: "Starts With", value: "starts with" },
  { id: 203, name: "Ends With", value: "ends with" },
  { id: 204, name: "Is Empty", value: "is empty" },
  { id: 205, name: "Is Not Empty", value: "is not empty" },
  { id: 206, name: "Is Any Of", value: "is any of" },
];



// Invoice Listing Filter Column DropDown
export const invoiceListingFilterColumns = [
  {
    name: "S. No",
    enable: false,
    value: "srNo",
  },
  {
    enable: true,
    name: "Invoice No.",
    value: "invoiceNumber",
  },
  {
    enable: true,
    value: "createdAt",
    name: "Invoice Date",
  },
  {
    enable: true,
    value: "serviceFrom",
    name: "Service Form",
    actionKey: "Vendor.name",
  },
  {
    enable: false,
    value: "paymentTo",
    name: "Payment to",
  },
  {
    enable: true,
    value: "taxableAmount",
    name: "Taxable Amount",
  },
  {
    name: "GST",
    enable: true,
    value: "gst_Amount",
  },
  {
    enable: true,
    value: "totalAmount",
    name: "Total Amount",
  },
  {
    enable: true,
    name: "Status",
    value: "paymentStatus",
  },
  {
    value: "id",
    enable: false,
    name: "Download",
  },
].filter((column) => column.enable);
