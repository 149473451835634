import React, { useState } from "react";
import "./Settings.scss";

import SettingSlots from "./section/SettingSlots";
import UserBankListing from "./section/bank-detail/UserBankListing";
import UserEditProfile from "./section/UserEditProfile";
import UserAccountType from "./section/UserAccountType";
import UserAddressManagement from "./section/address-management/UserAddressManagement";
import UserKYC from "./section/userKYC/UserKYC";
import ChangePassword from "./section/ChangePassword";

const UserSettings = () => {
  const [tabChange, setTabChange] = useState("profile");
  return (
    <>
      <div className="dashboard_settings dashboard_page">
        <div className="sideMenu">
          <h3 className="title_text">Settings</h3>
          <ul>
            <li>
              <button
                type="button"
                className={tabChange === "profile" ? "active" : ""}
                onClick={() => setTabChange("profile")}
              >
                Edit Profile
              </button>
            </li>
            <li>
              <button
                type="button"
                className={tabChange === "accountType" ? "active" : ""}
                onClick={() => setTabChange("accountType")}
              >
                MVIKAS Account Type
              </button>
            </li>
            <li>
              <button
                type="button"
                className={tabChange === "changePassword" ? "active" : ""}
                onClick={() => setTabChange("changePassword")}
              >
                Change Password
              </button>
            </li>
            <li>
              <button
                type="button"
                className={tabChange === "bankDetail" ? "active" : ""}
                onClick={() => setTabChange("bankDetail")}
              >
                Bank Details
              </button>
            </li>
            <li>
              <button
                type="button"
                className={tabChange === "addressManagement" ? "active" : ""}
                onClick={() => setTabChange("addressManagement")}
              >
                Address Management
              </button>
            </li>
            <li>
              <button
                type="button"
                className={tabChange === "kyc" ? "active" : ""}
                onClick={() => setTabChange("kyc")}
              >
                KYC
              </button>
            </li>
          </ul>
        </div>
        <div className="sideMenuContent _page">
          {tabChange == "profile" && <UserEditProfile />}
          {tabChange == "accountType" && <UserAccountType />}
          {tabChange == "changePassword" && <ChangePassword />}
          {tabChange == "bankDetail" && <UserBankListing />}
          {tabChange == "addressManagement" && <UserAddressManagement />}
          {tabChange == "kyc" && <UserKYC />}
         
        </div>
      </div>
    </>
  );
};

export default UserSettings;
