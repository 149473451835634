import { createContext, useEffect, useState } from "react";
import { getDataToLocal } from "../config/utils";

export const GlobalUserContext = createContext();

const GlobalContext = ({ children }) => {
  const [token, setToken] = useState("");
  const [userProfileData, setUserprofileData] = useState({});
  const [orderWalletToggle, setOrderWalletToggle] = useState(false);
  const [vendorListingForOrder, setVendorListingForOrder] = useState([]);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  //   Function for set user data
  const setUserDataHandler = () => {
    const data = getDataToLocal("authDetail");
    console.log("I AM LOCAL DATA FROM GLOBAL", data);
    const token = data?.token;
    if (token) {
      setToken(token);
      setUserprofileData(data?.userDetails);
    }
  };

  useEffect(() => {
    setUserDataHandler();

    // eslint-disable-next-line
  }, []);

  //*********************** Handle HTML CODE *********************//
  return (
    <GlobalUserContext.Provider
      value={{
        userProfileData,
        setUserprofileData,
        orderWalletToggle,
        setOrderWalletToggle,
        vendorListingForOrder,
        setVendorListingForOrder,
        isLoginModalOpen,
        setIsLoginModalOpen,
      }}
    >
      {children}
    </GlobalUserContext.Provider>
  );
};

export default GlobalContext;
