import { Instance as axios, setToken } from "services/instance";

// get dashboard details api
export const getDashboardDetailsAPI = async () => {
  return axios.get(`api/user/dashboard`, setToken());
};

// get notification list api
export const getNotificationAPI = async () => {
  return axios.get(`api/user/notification/list`, setToken());
};

// get Wallet Details api
export const getWalletDetailsAPI = async () => {
  return axios.get(`api/user/wallet/amount`, setToken());
};

// get gateway Id details for Wallet Recharge api
export const getGateWayIdAPI = async (data) => {
  return axios.post(`api/user/wallet/recharge`, data, setToken());
};

// Get Vendor List For Book Order On Home
export const getVendorForHomeAPI = async (data) => {
  return axios.post(`api/user/home/service/list`, data);
};

//--------------------- API Logics For Track Order ------------------------//
export const getOrderDetailsForTrackHomeAPI = (orderID) => {
  return axios.get(`api/user/order/track?orderId=${orderID}`);
};

//---------------------- API logics for switching functionality ----------------------//
export const switchToVendorAPI = async (data) => {
  return axios.post(`api/switch/user`, data, setToken());
};
