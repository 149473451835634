import { Instance as axios, setHeaderWithImage, setToken } from "../instance";

//------------ API Logics For Create order ---------------//

// get Vendor Listing For Order
export const getVendorForOrderAPI = (data) => {
  return axios.post(`api/user/vendor/list`, data, setToken());
};

// get Pickup slot Listing For Order
export const getPickUpListingAPI = (vendorId) => {
  return axios.get(`api/pickup/slot?vendorId=${vendorId}`, setToken());
};

// get Delivery slot Listing For Order
export const getDeliveryListingAPI = (vendorId) => {
  return axios.get(`api/delivery/slot?vendorId=${vendorId}`, setToken());
};

// Crete Order API Function
export const createOrderAPI = (data) => {
  return axios.post(`api/user/order/create`, data, setHeaderWithImage());
};

// Function for get order Id for payment
export const getOrderIdForPaymentAPI = async (data) => {
  return axios.post(`api/user/get/razorpay/orderId`, data, setToken());
};

// get permission of perKg and perBox API function
export const getPermissionAPI = async () => {
  return axios.get(`api/rate/type`, setToken());
};

//--------------------- API Logics For My Order Pages (get listing) ------------------------//

// get order listing api
export const getOrderListAPI = (
  page = 1,
  search = "",
  status = "",
  fromDate = "",
  toDate = "",
  limit = 10,
  isSortApply = 0,
  sortColumnName = "",
  sortingOrder = "",
  column = "",
  operator = "",
  value = ""
) => {
  // return axios.get(
  //   `api/user/order/list?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`,
  //   setToken()
  // );

  if (isSortApply === 1 || isSortApply === 2) {
    return axios.get(
      `api/user/order/list?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&sortByColumn=${sortColumnName}&sortBy=${sortingOrder}&column=${column}&operator=${operator}&value=${value}`,
      setToken()
    );
  } else {
    return axios.get(
      `api/user/order/list?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&column=${column}&operator=${operator}&value=${value}`,
      setToken()
    );
  }
};

// Export Order List API
export const exportOrderAPI = async () => {
  return axios.get(`api/user/order/export`, {
    ...setToken(), // Assuming setToken adds your auth headers
    responseType: "blob", // This is important to get the response as a blob
  });
};

export const getOrderDetailAPI = (
  id,
  page = 1,
  limit = 10,
  isSortApply = 0,
  sortColumnName = "",
  sortingOrder = "",
  column = "",
  operator = "",
  value = ""
) => {
  // return axios.get(
  //   `api/user/order/details?orderId=${id}&page=${page}&limit=${limit}`,
  //   setToken()
  // );
  if (isSortApply === 1 || isSortApply === 2) {
    return axios.get(
      `api/user/order/details?orderId=${id}&page=${page}&limit=${limit}&sortByColumn=${sortColumnName}&sortBy=${sortingOrder}&column=${column}&operator=${operator}&value=${value}`,
      setToken()
    );
  } else {
    return axios.get(
      `api/user/order/details?orderId=${id}&page=${page}&limit=${limit}&column=${column}&operator=${operator}&value=${value}`,
      setToken()
    );
  }
};

// Function for download the docket file api
export const downloadDocketAPI = async (id) => {
  return axios.get(`api/user/order/docket/download?orderId=${id}`, setToken());
};

// Function for download the master docket file api
export const downloadMasterDocketAPI = async (id) => {
  return axios.get(
    `api/user/order/master/docket/download?orderId=${id}`,
    setToken()
  );
};

// Function for download the label file api
export const downloadOrderLabelAPI = async (id, itemID) => {
  return axios.get(
    `api/user/order/label/download?orderId=${id}&itemId=${itemID}`,
    setToken()
  );
};

// get order status list api
export const getOrderStatusAPI = async () => {
  return axios.get(`api/order/status/type`, setToken());
};

//--------------------- API Logics For Submit Review In My Order Listing (get listing) ------------------------//
// get vendor Details
export const getVendorDetailsAPI = async (id) => {
  return axios.get(`api/user/order/review/details?orderId=${id}`, setToken());
};

// get vendor Details
export const getReviewTypeAPI = async () => {
  return axios.get(`api/order/review/type`, setToken());
};

// submit Review Data
export const submitReviewAPI = async (data) => {
  return axios.post(`api/user/order/review/submit`, data, setToken());
};

//--------------------- API Logics For Track Order ------------------------//
export const getOrderDetailsForTrackAPI = (orderID) => {
  return axios.get(`api/user/order/track?orderId=${orderID}`, setToken());
};

// get applies wait reconciliation list api
export const getWaitReconciliationAPI = (
  page = 1,
  search = "",
  status = "",
  fromDate = "",
  toDate = "",
  limit = 10,
  isSortApply = 0,
  sortColumnName = "",
  sortingOrder = "",
  column = "",
  operator = "",
  value = ""
) => {
  if (isSortApply === 1 || isSortApply === 2) {
    return axios.get(
      `api/user/order/rate/reconcilation/list?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&sortByColumn=${sortColumnName}&sortBy=${sortingOrder}&column=${column}&operator=${operator}&value=${value}`,
      setToken()
    );
  } else {
    return axios.get(
      `api/user/order/rate/reconcilation/list?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&column=${column}&operator=${operator}&value=${value}`,
      setToken()
    );
  }
};

// get cards data api
export const getCardDataAPI = () => {
  return axios.get(`api/user/order/rate/reconcilation/dashboard`, setToken());
};
