import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Select } from "../../../../../../components/form";
import CustomModal from "../../../../../../components/modals/CustomModal";
import { GlobalOrderContext } from "../../../../../../context/OrderContext";
import { addressListingApi } from "../../../../../../services/user-controllers/addressCont";
import { toast } from "react-toastify";
import AddressHandler from "../../../settings/section/address-management/AddressHandler";
import { getDataToLocal, setDataToLocal } from "../../../../../../config/utils";

const OrderReturnDetails = ({ prev, next }) => {
  const { vendorDetail, orderDetailsForm, setOrderDetailsForm } =
    useContext(GlobalOrderContext);
  //************************* Handle all state **********************//
  const [AddNewAddress, setAddNewAddress] = useState();
  const [addressListing, setAddressListing] = useState([]);
  const [isSameAsPickup, setIsSameAsPickup] = useState(false);

  const [tempStoreOfReturnDetails, setTempStoreOfReturnDetails] = useState({});

  const [returnDetailsFormFields, setReturnDetailsFormFields] = useState({
    returnaddress: "",
    returnpersonname: "",
    returnpersonmobile: "",
    returnremarks: "",
  });

  const [
    returnDetailsFormFieldsValidationError,
    setReturnDetailsFormFieldsValidationError,
  ] = useState({
    returnaddress: "",
    returnpersonname: "",
    returnpersonmobile: "",
    returnremarks: "",
  });

  //*********************** Handle all function ********************//
  // get addressList handler
  const getAddressListHandler = async () => {
    try {
      const { data } = await addressListingApi();
      console.log("ADDRESS LISTING API RES ====>>>>", data);
      if (data?.code === 200) {
        setAddressListing(data.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "returnpersonmobile") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setReturnDetailsFormFields({
        ...returnDetailsFormFields,
        returnpersonmobile: numericValue,
      });
      setTempStoreOfReturnDetails({
        ...tempStoreOfReturnDetails,
        returnpersonmobile: numericValue,
      });
    } else {
      setReturnDetailsFormFields({ ...returnDetailsFormFields, [name]: value });
      setTempStoreOfReturnDetails({
        ...tempStoreOfReturnDetails,
        [name]: value,
      });
    }
  };

  // sameAsPickupDetails Handler
  const sameAsPickupCheckHandler = (e) => {
    const { checked } = e.target;
    console.log("I AM CHECKED VALUE", checked);
    console.log("TEMP STORE VARIABLE", tempStoreOfReturnDetails);
    if (checked) {
      setReturnDetailsFormFields({
        ...returnDetailsFormFields,
        returnpersonname: orderDetailsForm?.pickupDetailsForm?.Pickuppersonname,
        returnpersonmobile:
          orderDetailsForm?.pickupDetailsForm?.Pickuppersonmobile,
        returnaddress: orderDetailsForm?.pickupDetailsForm?.Pickupaddress,
      });
    } else {
      setReturnDetailsFormFields({});
    }
  };

  // ErrorHandler
  const errorHandler = () => {
    let errorField = {};
    let formIsValid = true;

    if (!returnDetailsFormFields.returnpersonname) {
      formIsValid = false;
      errorField["returnpersonname"] = "Please Enter Return Person Name*";
      setReturnDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!returnDetailsFormFields.returnpersonmobile) {
      formIsValid = false;
      errorField["returnpersonmobile"] = "Please Enter Return Person Mobile*";
      setReturnDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    if (!returnDetailsFormFields.returnaddress) {
      formIsValid = false;
      errorField["returnaddress"] = "Please Select Return Address*";
      setReturnDetailsFormFieldsValidationError(errorField);
      return formIsValid;
    }

    setReturnDetailsFormFieldsValidationError(errorField);
    return formIsValid;
  };

  // onSubmitHandler
  const onSubmitHandler = () => {
    if (errorHandler()) {
      const orderData = { ...returnDetailsFormFields };
      const dataForGlobalState = {
        ...orderDetailsForm,
        returnDetailsForm: orderData,
      };
      setOrderDetailsForm(dataForGlobalState);
      next();
      // setDataToLocal("orderFormFields", dataForGlobalState);
    }
  };

  // useEffect for get address listing
  useEffect(() => {
    getAddressListHandler();

    // eslint-disable-next-line
  }, []);

  // useEffect for set default address and delivery slot value from listing
  useEffect(() => {
    if (addressListing?.length > 0 && !orderDetailsForm?.returnDetailsForm) {
      setReturnDetailsFormFields({
        ...returnDetailsFormFields,
        returnaddress: addressListing[0]?.fullAddress,
      });
    }

    // eslint-disable-next-line
  }, [addressListing]);

  // useEffect for set Return Details form value if got
  useEffect(() => {
    if (orderDetailsForm?.returnDetailsForm) {
      setReturnDetailsFormFields({ ...orderDetailsForm?.returnDetailsForm });
    }
  }, [orderDetailsForm?.returnDetailsForm]);

  // useEffect for update order form details global state from local
  // useEffect(() => {
  //   const orderData = getDataToLocal("orderFormFields");
  //   if (!orderDetailsForm?.returnDetailsForm && orderData) {
  //     setOrderDetailsForm({
  //       ...orderData,
  //       returnDetailsForm: { ...orderData?.returnDetailsForm },
  //     });
  //   }
  // }, []);

  //************************ Handle HTML CODE *********************//
  return (
    <>
      <div className="has_child">
        <h4>Return Details</h4>
        <label>
          <input
            type="checkbox"
            className="checkbox"
            checked={isSameAsPickup}
            onChange={(e) => {
              setIsSameAsPickup(e.target.checked);
              sameAsPickupCheckHandler(e);
            }}
          />{" "}
          Same as pickup address
        </label>
      </div>
      <form>
        <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              name="returnpersonname"
              onChange={onChangeHandler}
              placeholder="Enter Return Person Name"
              label="Return Person Name*"
              value={returnDetailsFormFields?.returnpersonname}
              error={returnDetailsFormFieldsValidationError?.returnpersonname}
            />
          </div>
          <div className="form_field">
            <Input
              type="text"
              maxLength={11}
              name="returnpersonmobile"
              onChange={onChangeHandler}
              placeholder="Enter Return Person Contact"
              label="Return Person Contact*"
              value={returnDetailsFormFields?.returnpersonmobile}
              error={returnDetailsFormFieldsValidationError?.returnpersonmobile}
            />
          </div>
        </div>
        <div className="form_field_wrap">
          <div className="form_field">
            <Input
              type="text"
              label="Remark"
              name="returnremarks"
              placeholder="Enter Remark"
              onChange={onChangeHandler}
              value={returnDetailsFormFields?.returnremarks}
              error={returnDetailsFormFieldsValidationError?.returnremarks}
            />
          </div>
          <div className="form_field">
            <Select
              name="returnaddress"
              label="Return Address*"
              onChange={(e) => {
                setReturnDetailsFormFields({
                  ...returnDetailsFormFields,
                  returnaddress: e.target.value,
                });
                setTempStoreOfReturnDetails({
                  ...tempStoreOfReturnDetails,
                  returnaddress: e.target.value,
                });
              }}
              value={returnDetailsFormFields?.returnaddress}
              error={returnDetailsFormFieldsValidationError?.returnaddress}
            >
              {addressListing?.length > 0 &&
                addressListing?.map((el) => (
                  <option key={el.id} value={el?.fullAddress}>
                    {el?.fullAddress}
                  </option>
                ))}
            </Select>
          </div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field">
            <label className="label dummy">Add new address</label>
            <button
              type="button"
              className="input"
              onClick={() => setAddNewAddress(true)}
            >
              + Add new address
            </button>
          </div>
          <div className="form_field"></div>
        </div>
        <div className="button_wrap">
          <Button className={"light black"} onClick={prev}>
            Back
          </Button>
          <Button onClick={onSubmitHandler}>Continue</Button>
        </div>
      </form>

      <CustomModal
        show={AddNewAddress}
        handleClose={() => setAddNewAddress(false)}
      >
        <AddressHandler
          callRole={"add"}
          title={"Add New Address"}
          addressList={getAddressListHandler}
          closeAddressModal={() => setAddNewAddress(false)}
        />
      </CustomModal>
    </>
  );
};

export default OrderReturnDetails;
