import React, { useEffect, useState } from "react";

import Placeholder from "../../../../../components/placeholder/Placeholder";
import { Input } from "../../../../../components/form";
import { getAccountDetailsAPI } from "../../../../../services/user-controllers/profileCont";
import { toast } from "react-toastify";

const UserAccountType = () => {
  //************************** Handle all state ***********************//
  const [getAccountDetails, setAccountDetails] = useState({});

  //************************ Handle all functions *********************//
  // get account details handler
  const getAccountDetailsHandler = async () => {
    try {
      const { data } = await getAccountDetailsAPI();
      console.log("ACCOUNT DETAILS", data);
      if (data.code === 200) {
        setAccountDetails(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect for get account details
  useEffect(() => {
    getAccountDetailsHandler();

    // eslint-disable-next-line
  }, []);

  //************************* Handle HTML CODE ***********************//
  return (
    <>
      <div className="rules">
        <h5>MVIKAS Account Details</h5>
        <div className="dataListing">
          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="text"
                placeholder=""
                isDisabled={true}
                label="Account Type"
                value={
                  !getAccountDetails?.billingType
                    ? "Prepaid"
                    : getAccountDetails?.billingType
                }
              />
            </div>
            <div className="form_field">
              <Input
                type="email"
                placeholder=""
                isDisabled={true}
                label="Credit limit"
                value={getAccountDetails?.creditLimit || "-----"}
              />
            </div>
          </div>

          <div className="form_field_wrap">
            <div className="form_field">
              <Input
                type="tel"
                placeholder=""
                isDisabled={true}
                label="Billing Cycle*"
                value={getAccountDetails?.billingCycle || "-----"}
              />
            </div>
            <div className="form_field">
              <Input
                type="email"
                placeholder=""
                isDisabled={true}
                label="Payment Cycle*"
                value={getAccountDetails?.paymentCycle || "-----"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAccountType;
