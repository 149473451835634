import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import { BrowserRouter, HashRouter } from "react-router-dom";
import ScrollToTop from "./routing/ScrollTop";
import Routing from "./routing";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalContext from "./context/GlobalContext";
import OrderContext from "./context/OrderContext";
import ChatBot from "components/chat-bot/ChatBot";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
      {/* <HashRouter> */}
        <GlobalContext>
          <OrderContext>
            <ScrollToTop />
            <Routing />
            <ChatBot />
            <ToastContainer />
          </OrderContext>
        </GlobalContext>
      {/* </HashRouter> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
