import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoImg } from "../../assets/images";
import "./Header.scss";
import CustomModal from "../modals/CustomModal";
import LoginModal from "../../pages/auth/login/Login";
import SignUp from "../../pages/auth/signup/SignUp";
import MailSuccess from "../../pages/auth/mailSuccess/MailSuccess";
import ForgetPassword from "../../pages/auth/forgoPassword/ForgotPassword";
import { getDataToLocal } from "../../config/utils";
import CustomDrawer from "components/drawer/Drawer";
import { IoReorderThreeOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { GlobalUserContext } from "context/GlobalContext";

const Header = () => {
  const {
    setOrderWalletToggle,
    orderWalletToggle,
    isLoginModalOpen,
    setIsLoginModalOpen,
  } = useContext(GlobalUserContext);

  const router = useNavigate();

  const [loginModal, setLoginModal] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [authentication, setAuthentication] = useState("login");
  const [isScroll, setIsScroll] = useState(false);

  const loginFlow = () => {
    setIsLoginModalOpen(true);
    setAuthentication("login");
  };

  const signUpFlow = () => {
    setIsLoginModalOpen(true);
    setAuthentication("signUp");
  };

  // Scroll header
  useEffect(() => {
    const handleScroll = () => {
      setIsScroll(window.scrollY > 30);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const localData = getDataToLocal("authDetail") || {};
  const token = !localData?.token ? null : localData?.token;

  useEffect(() => {
    if (!token) {
      return;
    } else {
      router("/user/dashboard/home");
    }
  }, [token]);

  const navigation = () => {
    document.body.classList.add("innerside_toogle");
  };
  const navigations = () => {
    document.body.classList.remove("innerside_toogle");
  };

  return (
    <>
      <header className={`header ${isScroll ? "scrollHeader" : ""}`}>
        <div className="container">
          <div className="header_content">
            <div className="logo">
              <Link to="/">
                <img src={logoImg} alt=""></img>
              </Link>
            </div>
            <div className="header_ul_btn_wrap">
              <nav className="header_menu">
                <ul>
                  <li>
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#about">About Us</a>
                  </li>
                  <li>
                    <a href="#services">Services</a>
                  </li>
                  <li>
                    <a href="#team">Team</a>
                  </li>
                  <li>
                    <a href="#why">Why us</a>
                  </li>
                  <li>
                    <Link to="javascript:void(0)">Contact Us</Link>
                  </li>
                </ul>
              </nav>
              <div className="button_wrap">
                <button className="button light" onClick={loginFlow}>
                  Login
                </button>
                <button className="button" onClick={signUpFlow}>
                  Sign Up
                </button>
              </div>
            </div>
            <nav className="header_menu">
              <ul>
                <li>
                  <a href="#home">Home</a>
                </li>
                <li>
                  <a href="#about">About Us</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <a href="#team">Team</a>
                </li>
                <li>
                  <a href="#why">Why us</a>
                </li>
                <li>
                  <Link to="javascript:void(0)">Contact Us</Link>
                </li>
              </ul>
            </nav>
            <div className="button_wrap">
              <button className="button light" onClick={loginFlow}>
                Login
              </button>
              <button className="button" onClick={signUpFlow}>
                Sign Up
              </button>
            </div>
            <div className="menu_button">
              <IoReorderThreeOutline
                className="three_line"
                onClick={navigation}
              />
              <RxCross2 className="cross_icon" onClick={navigations} />
            </div>
          </div>
        </div>
      </header>

      {/*----------------- Auth Modal (Login/SignUp) ----------------*/}
      <CustomModal
        show={isLoginModalOpen}
        className={"authModal"}
        handleClose={() => setIsLoginModalOpen(false)}
      >
        {authentication === "login" && (
          <LoginModal
            switchSingUp={() => setAuthentication("signUp")}
            switchForget={() => setAuthentication("forgetPass")}
          />
        )}
        {authentication === "signUp" && (
          <SignUp switchLogin={() => setAuthentication("login")} />
        )}

        {authentication === "forgetPass" && (
          <ForgetPassword switchLogin={() => setAuthentication("login")} />
        )}
      </CustomModal>

      {/*-------------------- Mail send Successfully Modal -----------------*/}
      <CustomModal
        show={sentSuccess}
        handleClose={() => setSentSuccess(false)}
        className={"successModal"}
      >
        <MailSuccess />
      </CustomModal>
    </>
  );
};

export default Header;
