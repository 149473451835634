import React from "react";
import "./Footer.scss";
import { whiteLogo } from "../../assets/images";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import {
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { Button } from "../form";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer_content">
            <div className="logo_section">
              <img src={whiteLogo} alt="" />
              <p>
                Our comprehensive logistics services ensure timely and secure
                delivery for your business, providing the coverage you need for
                all your shipping needs.
              </p>
              <div className="social_logo">
                <h5>Follow us:</h5>
                <ul>
                  <li>
                    <Link
                      to="https://twitter.com/mvikaslogistics"
                      target="_blank"
                    >
                      <FaXTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.facebook.com/mvikassuperlogistics"
                      target="_blank"
                    >
                      <FaFacebookF />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/mvikassuperlogistics/"
                      target="_blank"
                    >
                      <FaInstagram />
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="https://www.linkedin.com/company/mvikas-super-logistics/"
                      target="_blank"
                    >
                      <FaLinkedinIn />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="useful_links">
              <ul>
                <li>
                  <h4>Useful Links</h4>
                </li>
                <li>
                  <a href="#home">Home</a>
                </li>
                <li>
                  <a href="#about">About us</a>
                </li>
                <li>
                  <a href="#why">Why us</a>
                </li>
                <li>
                  <a href="#services">Our Services</a>
                </li>
                <li>
                  <a href="javascript:void(0)">FAQ</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Contact </a>
                </li>
                <li>
                  <a href="#blog">Blog</a>
                </li>
              </ul>
              <ul>
                <li>
                  <h4>Our Policies</h4>
                </li>
                <li>
                  <Link to="">Return, Refund & Cancellation</Link>
                </li>
                <li>
                  <Link to="">Shipping Policy</Link>
                </li>
                <li>
                  <Link to="">Pricing for Product</Link>
                </li>
                <li>
                  <Link to="">Complaints & Grievance</Link>
                </li>
              </ul>
            </div>
            <div className="subscription">
              <h4>Subscribe</h4>
              <p>
                Don't miss to subscribe to our new feeds, kindly fill the form
                below.
              </p>
              <input
                type="email"
                className="form-control input"
                placeholder="Enter Your Email"
              />
              <Button type={"button"}>Subscribe Now</Button>
            </div>
          </div>
        </div>
        <div className="content_bottom">
          <div className="container">
            <p>
              Copyright © {new Date().getFullYear()} MVIKAS Super Logistics. All
              Right Reserved
            </p>
            <ul>
              <li>
                <Link to="">Terms & Condition </Link>
              </li>
              <li>
                <Link to="">Privacy Policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
