import "../auth.scss";
import React, { useState } from "react";
import {
  callIcon,
  lockIcon,
  messageIcon,
  profileIcon,
} from "../../../assets/icons";
import { authImg } from "../../../assets/images";
import { Button, Input, LoadingButton } from "../../../components/form";
import usePasswordToggle from "../../../hooks/usePasswordToggle";
import { LuUsers } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { forgetPassLinkSendAPI } from "../../../services/auth-controllers/authCont";
import { toast } from "react-toastify";

const ForgetPassword = ({ switchLogin }) => {
  //*----------------- Handle hooks ----------------*//

  const navigate = useNavigate();

  //********************************** Handle all state ***********************************//

  const [passwordType, showIcon] = usePasswordToggle();
  const [forgetPassEmail, setForgetPassEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  // handle functions
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (
      !forgetPassEmail?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setEmailValidationError("Invalid Email");
      return;
    } else {
      try {
        setSubmitButtonLoading(true);
        const { data } = await forgetPassLinkSendAPI({
          email: forgetPassEmail,
        });
        if (data?.code === 200) {
          // navigate("/");
          setForgetPassEmail("");
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitButtonLoading(false);
      }
    }
  };

  //******************************** Handle HTML CODE ******************************//
  return (
    <>
      <div className="auth login">
        <div className="image_wrap">
          <img src={authImg} alt="" />
        </div>

        <div className="form_wrap">
          <h3>Forgot password</h3>

          <p>No worries, We’ll send you instructions for reset</p>

          <form>
            <div className="form_field">
              <Input
                type="email"
                name="email"
                value={forgetPassEmail}
                placeholder="Enter email"
                error={emailValidationError}
                className="form-control input"
                onChange={(e) => setForgetPassEmail(e.target.value)}
                extraError={emailValidationError?.length > 0 ? true : false}
              />
              <img src={messageIcon} alt="" className="icon" />
            </div>

            <div className="button_wrap">
              {forgetPassEmail?.length > 0 ? (
                submitButtonLoading ? (
                  <LoadingButton
                    disabled
                    className={"w-100 disabled"}
                    loading={submitButtonLoading}
                  />
                ) : (
                  <Button className={"w-100"} onClick={onSubmitHandler}>
                    Request reset link
                  </Button>
                )
              ) : (
                <Button isDisabled={true} className={"w-100 disabled"}>
                  Request reset link
                </Button>
              )}
            </div>

            <p className="move_singUp">
              Back to login
              <button type="button" onClick={switchLogin}>
                Log In
              </button>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
