import React from "react";
import { placeholderImg } from "../../assets/images";

const Placeholder = ({ className, image, content }) => {
  return (
    <>
      <div className={`common_placeholder ${className}`}>
        <img src={image ? image : placeholderImg} alt="" />

        <h5>{content ? content : "No Data Available"}</h5>
      </div>
    </>
  );
};

export default Placeholder;
