import React, { useEffect, useState } from "react";
import { GoTrash } from "react-icons/go";
import { RiAddCircleLine, RiEdit2Line } from "react-icons/ri";
import Placeholder from "../../../../../../components/placeholder/Placeholder";
import {
  Button,
  Input,
  Select,
  TextArea,
} from "../../../../../../components/form";
import DeleteModal from "../../../../../../components/modals/DeleteModal";
import CustomModal from "../../../../../../components/modals/CustomModal";
import { callIcon } from "../../../../../../assets/icons";
import { SlLocationPin } from "react-icons/sl";
import { LuPhoneCall } from "react-icons/lu";
import AddressHandler from "./AddressHandler";
import { setToken } from "../../../../../../services/instance";
import {
  addressListingApi,
  deleteAddressAPI,
} from "../../../../../../services/user-controllers/addressCont";
import { toast } from "react-toastify";

const UserAddressManagement = () => {
  //***************************** Handle All State ******************************//
  const [addRule, setAddRule] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [addressListing, setAddressListing] = useState([]);
  const [addressModalTitle, setAddressModalTitle] = useState(
    "Address information"
  );
  const [editAddressDetails, setEditAddressDetails] = useState({});
  const [addressModalCallRole, setAddressModalCallRole] = useState("add");
  const [deleteButtonLoading, setDeleteLoading] = useState(false);

  //***************************** Handle all functions ***********************************//

  // get addressList handler
  const getAddressListHandler = async () => {
    try {
      const { data } = await addressListingApi();
      if (data?.code === 200) {
        setAddressListing(data.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Edit address handler
  const editAddressHandler = (data) => {
    setEditAddressDetails(data);
    setAddressModalCallRole("edit");
    setAddressModalTitle("Edit Address information");
    setAddRule(true);
  };

  // Delete address handler
  const deleteAddressHandler = async () => {
    // e.preventDefault()
    try {
      setDeleteLoading(true);
      const { data } = await deleteAddressAPI(addressId);
      if (data?.code === 200) {
        setDeleteModal(false);
        getAddressListHandler();
        toast.success(data?.message);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  // useEffect it run only once
  useEffect(() => {
    getAddressListHandler();
  }, []);

  //****************************** Handle HTML CODE ***************************//
  return (
    <>
      <div className="rules ">
        <h5>Address Management</h5>
        <div className="dataListing addressList">
          <ul>
            {addressListing?.length > 0 &&
              addressListing?.map((el, i) => (
                <li key={i}>
                  <div className="content">
                    <p>
                      <span className="tag">{el?.name}</span> <LuPhoneCall />
                      <b>{el?.mobile}</b>
                    </p>
                    <p>
                      <SlLocationPin />
                      <span>{el?.fullAddress}</span>
                    </p>
                  </div>
                  <div className="action_button">
                    <button
                      type="button"
                      onClick={() => editAddressHandler(el)}
                    >
                      <RiEdit2Line />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setAddressId(el?.id);
                        setDeleteModal(true);
                      }}
                    >
                      <GoTrash />
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        </div>

        {/*------------- Data not Found placeholder ---------------*/}
        {addressListing?.length === 0 && (
          <Placeholder Placeholder="Rules are not available" />
        )}

        {/*--------------- Add address Button ---------------*/}
        <div className="button_wrap button_right">
          <Button
            className={"has_icon"}
            onClick={() => {
              setAddressModalCallRole("add");
              setEditAddressDetails({});
              setAddressModalTitle("Address information");
              setAddRule(true);
            }}
          >
            <RiAddCircleLine />
            Add Address
          </Button>
        </div>
      </div>

      {/*---------------------------- Handle All Modal ----------------------*/}

      {/* Add New Rules */}
      <CustomModal
        className={"md"}
        show={addRule}
        handleClose={() => setAddRule(false)}
      >
        <AddressHandler
          title={addressModalTitle}
          callRole={addressModalCallRole}
          addressDetail={editAddressDetails}
          addressList={getAddressListHandler}
          closeAddressModal={() => setAddRule(false)}
        />
      </CustomModal>

      {/* Delete Modal */}
      <DeleteModal
        show={deleteModal}
        handleSubmit={deleteAddressHandler}
        buttonLoading={deleteButtonLoading}
        handleClose={() => setDeleteModal(false)}
        content={"Are you sure you want to delete this Address?"}
      />
    </>
  );
};

export default UserAddressManagement;
