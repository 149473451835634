import React, { useContext, useEffect, useState } from "react";
import OrderPickupDetails from "./section/OrderPickupDetails";
import { Link, useNavigate } from "react-router-dom";
import {
  completedIcon,
  currentIcon,
  defaultIcon,
} from "../../../../../assets/icons";
import OrderDeliveryDetails from "./section/OrderDeliveryDetails";
import OrderReturnDetails from "./section/OrderReturnDetails";
import ProductDetails from "./section/ProductDetails";
import OrderPaymentDetails from "./section/OrderPaymentDetail";
import CustomModal from "../../../../../components/modals/CustomModal";
import { colorCardImg, colorWalletImg } from "../../../../../assets/images";
import { Button, Input } from "../../../../../components/form";
import { GlobalOrderContext } from "../../../../../context/OrderContext";
import { decryptData, getDataToLocal } from "../../../../../config/utils";
import { Accordion } from "react-bootstrap";

const OrderSummary = () => {
  const navigate = useNavigate();

  //********************** Handle all state *****************//
  const {
    vendorDetail,
    setVendorDetail,
    orderSearchFieldData,
    orderDetailsForPayment,
    setOrderSearchFieldData,
    setOrderDetailsForPayment,
  } = useContext(GlobalOrderContext);

  const [stepper, setStepper] = useState("default");
  const [orderSteps, setOrderStep] = useState("pickup");
  // const [orderSteps, setOrderStep] = useState("payment");

  const [payNowModal, setPayNowModal] = useState(false);

  const [orderFormSteppers, setOrderFormSteppers] = useState({
    prevKey: [],
    PrevStatus: "",
    currentKey: "pickup",
    currentStatus: "current", //-------- default/current/completed -------//
  });

  //********************** Handle all functions *****************//

  // Function for handler status bar or order form
  const statusBarHandler = (callRole) => {
    let updateStatusState = {
      currentKey: "",
      currentStatus: "",
      prevKey: [],
    };

    let prevKeyClone = orderFormSteppers?.prevKey?.map((el) => el);

    if (callRole === "pickup") {
      updateStatusState = {
        currentKey: "delivery",
        currentStatus: "current",
        prevKey: ["pickup"],
      };
      setOrderStep("delivery");
    }

    if (callRole === "delivery") {
      updateStatusState = {
        currentKey: "return",
        currentStatus: "current",
        prevKey: [...prevKeyClone, "delivery"],
      };
      setOrderStep("return");
    }

    if (callRole === "return") {
      updateStatusState = {
        currentKey: "details",
        currentStatus: "current",
        prevKey: [...prevKeyClone, "return"],
      };
      setOrderStep("details");
    }

    if (callRole === "details") {
      updateStatusState = {
        currentKey: "payment",
        currentStatus: "current",
        prevKey: [...prevKeyClone, "details"],
      };
      setOrderStep("payment");
    }

    setOrderFormSteppers({ ...updateStatusState });
  };

  // useEffect for set order and vendor details global state if refresh
  useEffect(() => {
    const setGlobalDetailsHandler = () => {
      let vendorData = {};
      if (getDataToLocal("vendorDetailsForOrder")) {
        const vendorDecryptedData =
          getDataToLocal("vendorDetailsForOrder") || {};
        vendorData = decryptData(decodeURIComponent(vendorDecryptedData));

        if (!vendorDetail?.vendorId || !orderSearchFieldData?.userId) {
          const orderDecryptedData = getDataToLocal("orderSearchDetail");
          const orderData = decryptData(decodeURIComponent(orderDecryptedData));

          setVendorDetail(vendorData);
          setOrderSearchFieldData(orderData);
        }
        const dataFromLocal = getDataToLocal("authDetail");
        const userDetails = dataFromLocal?.userDetails;
        setOrderDetailsForPayment({
          name: userDetails?.name,
          email: userDetails?.email,
          // mobile: 9897490141,
          orderAmount: vendorData?.totalAmount || 0,
        });
      }
      setGlobalDetailsHandler();
    };

    // eslint-disable-next-line
  }, [getDataToLocal("vendorDetailsForOrder")]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     // Prevent the default behavior (optional, for legacy browsers)
  //     event.preventDefault();
  //     event.returnValue = ""; // Required for modern browsers to trigger the event
  //     // setShowModal(true); // Show the modal when the refresh attempt is detected
  //   };

  //   // Add the beforeunload event listener
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup the event listener
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Trigger the browser's confirmation dialog
      event.preventDefault();
      event.returnValue = ""; // Required for the confirmation dialog

      // Save the intent to reload in localStorage
      localStorage.setItem("reloadRedirect", "true");
    };

    // Check on page load if a redirect is needed
    if (localStorage.getItem("reloadRedirect") === "true") {
      localStorage.removeItem("reloadRedirect"); // Clear the flag
      navigate("/user/dashboard/book-orders"); // Redirect after reload
    }

    // Add the beforeunload event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  console.log("ORDER FORM STEPPER ---->>> ", orderFormSteppers);

  console.log("I AM VENDOR DETAILS ====>>>>>>>---->>>", vendorDetail);

  //********************** Handle HTML CODE *****************//
  return (
    <>
      <div className="order_pickup_details">
        <ul className="bread_crumb">
          <li>
            <Link to={-1}>Book Orders</Link>
          </li>
          <li>Order Summary</li>
        </ul>
        <div className="stepper">
          <ul>
            <li>
              <span>Pickup details</span>
              <b>
                {orderFormSteppers?.currentKey === "pickup" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("pickup") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
            <li>
              <span>Delivery Details</span>
              <b>
                {/* {stepper === "completed" && <img src={completedIcon} alt="" />}
                {stepper === "current" && <img src={currentIcon} alt="" />}
                {stepper === "default" && <img src={defaultIcon} alt="" />} */}

                {orderFormSteppers?.currentKey === "delivery" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("delivery") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
            <li>
              <span>Return Details</span>
              <b>
                {/* {stepper === "completed" && <img src={completedIcon} alt="" />}
                {stepper === "current" && <img src={currentIcon} alt="" />}
                {stepper === "default" && <img src={defaultIcon} alt="" />} */}

                {orderFormSteppers?.currentKey === "return" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("return") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
            <li>
              <span>Details</span>
              <b>
                {/* {stepper === "completed" && <img src={completedIcon} alt="" />}
                {stepper === "current" && <img src={currentIcon} alt="" />}
                {stepper === "default" && <img src={defaultIcon} alt="" />} */}

                {orderFormSteppers?.currentKey === "details" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("details") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
            <li>
              <span>Payment Options</span>
              <b>
                {/* {stepper === "completed" && <img src={completedIcon} alt="" />}
                {stepper === "current" && <img src={currentIcon} alt="" />}
                {stepper === "default" && <img src={defaultIcon} alt="" />} */}

                {orderFormSteppers?.currentKey === "payment" &&
                orderFormSteppers?.currentStatus === "current" ? (
                  <img src={currentIcon} alt="" />
                ) : orderFormSteppers?.prevKey?.includes("payment") ? (
                  <img src={completedIcon} alt="" />
                ) : (
                  <img src={defaultIcon} alt="" />
                )}
              </b>
            </li>
          </ul>
        </div>

        <div className="order_summery_wrap">
          <div className="orderDetails _page">
            {orderSteps === "pickup" && (
              <OrderPickupDetails next={() => statusBarHandler("pickup")} />
            )}
            {orderSteps === "delivery" && (
              <OrderDeliveryDetails
                prev={() => setOrderStep("pickup")}
                next={() => statusBarHandler("delivery")}
              />
            )}
            {orderSteps === "return" && (
              <OrderReturnDetails
                prev={() => setOrderStep("delivery")}
                next={() => statusBarHandler("return")}
              />
            )}
            {orderSteps === "details" && (
              <ProductDetails
                prev={() => setOrderStep("return")}
                next={() => statusBarHandler("details")}
              />
            )}
            {orderSteps === "payment" && (
              <OrderPaymentDetails
                prev={() => setOrderStep("details")}
                // onClick={() => setOrderStep("return")}
              />
            )}
          </div>

          <div className="order_summery">

            {/* <div className="_page">
              <h4>Additional Services</h4>
              <hr />
              <ul className="cstm_addi_services">
                <li>
                  <span>Mall Delivery</span>
                  <Input type="checkbox" />
                </li>
                <li>
                  <span>CSD Delivery</span>
                  <Input type="checkbox" />
                </li>
                <li>
                  <span>Sunday Delivery</span>
                  <Input type="checkbox" />
                </li>
              </ul>
              <hr />
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Floor Delivery <span>(Ground Floor)</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="accordian_ul">
                      <li>
                        <span>Ground Floor</span>
                        <label htmlFor="">
                          Rs. 180
                          <input type="radio" />
                        </label>
                      </li>
                      <li>
                        <span>First Floor</span>
                        <label htmlFor="">
                          Rs. 220
                          <input type="radio" />
                        </label>
                      </li>
                      <li>
                        <span>Second Floor</span>
                        <label htmlFor="">
                          Rs. 300
                          <input type="radio" />
                        </label>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div> */}


            <div className="_page">
              <h4>Order Summary</h4>
              <hr />

              <h5>{vendorDetail?.vendorName || "-----"}</h5>
              <p>
                <b>Charges*</b>
              </p>
              <ul>
                <li>
                  <span>Taxable Amount</span>
                  <span>{vendorDetail?.taxableAmount || "------"}</span>
                </li>
                <li>
                  {vendorDetail?.gst ? (
                    <span>GST Amount ({vendorDetail?.gst}%)</span>
                  ) : (
                    <span>GST Amount ("------")</span>
                  )}
                  <span>{vendorDetail?.gst_Amount || "-----"}</span>
                </li>
                <li>
                  <b>Total Amount</b>
                  <b>{vendorDetail?.totalAmount || "-----"}</b>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <CustomModal
        show={payNowModal}
        handleClose={() => setPayNowModal}
        className={"payNowModal"}
      >
        <div className="top_content">
          <img src={colorWalletImg} alt="" />
          <p>
            <b>₹113</b>
            <span>Wallet Balance</span>
          </p>
        </div>
        <ul className="listing">
          <li>
            <p>
              <b>₹1185</b>
              <span>More ways to make payment.</span>
            </p>
            <Button>Add</Button>
            <span className="error_text">
              You need to add ₹113 in your wallet to pay for this order.{" "}
            </span>
          </li>
          <li>
            <p>
              <b>₹1185</b>
              <span>More ways to make payment.</span>
            </p>
            <Button>Continue</Button>
          </li>
        </ul>
      </CustomModal>
    </>
  );
};

export default OrderSummary;
