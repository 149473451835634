import { Instance as axios, setToken } from "../instance";

// get bank list API function
export const bankListingApi = async () => {
  return axios.get(`api/fetchBankDetails`, setToken());
};

// add Bank Details API function
export const createBankApi = async (data) => {
  return axios.post(`api/addBankDetails`, data, setToken());
};

// Update Bank Details API function
export const updateBankApi = async (data) => {
  return axios.put(`api/updateBankDetails`, data, setToken());
};

// Delete Bank Details APi Function
export const deleteBankAPI = async (recordId) => {
  return axios.delete(`api/deleteBankDetails/${recordId}`, setToken());
};

// Update Bank Status APi Function
export const bankStatusTogglerAPI = async (recordId) => {
  return axios.put(`api/enableBankDetails/${recordId}`,{}, setToken());
};
