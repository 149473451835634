import { toast } from "react-toastify";
import React, { useState } from "react";
import { MdOutlineModeEdit } from "react-icons/md";
import { cameraImg } from "../../../../../../../assets/images";
import {
  Button,
  LoadingButton,
  Select,
} from "../../../../../../../components/form";
import {
  individualKYCUpdateAPI,
  individualKYCUploadAPI,
} from "../../../../../../../services/user-controllers/kycCont";

const IndividualKYC = ({ nextStep, loadUploadedKycPage, kycDetailsData }) => {
  //***************************** Handle all state **************************//

  const [aadharCardImage, setAadharCardImage] = useState({
    backImage: [],
    frontImage: [],
    previewBackImage: "",
    previewFrontImage: "",
  });

  const [otherDocImage, setOtherDocImage] = useState({
    backImage: [],
    frontImage: [],
    previewBackImage: "",
    previewFrontImage: "",
  });

  const [selectedBack, setSelectedBack] = useState("");
  const [selectedFront, setSelectedFront] = useState("");

  const documentPairs = {
    "": "",
    panFront: "panBack",
    dlFront: "dlBack",
    passportFront: "passportBack",
  };

  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  //****************************** Handle all Functions *************************//

  // Error Handler
  const errorHandler = () => {
    let formIsValid = true;

    if (!aadharCardImage.frontImage?.name) {
      formIsValid = false;
      toast.error("Please Upload Aadhar Front Image");
      return formIsValid;
    }

    if (!aadharCardImage.backImage?.name) {
      formIsValid = false;
      toast.error("Please Upload Aadhar Back Image");
      return formIsValid;
    }

    if (!otherDocImage.frontImage?.name) {
      formIsValid = false;
      toast.error("Please Upload Selected Document's Front Image");
      return formIsValid;
    }

    if (!otherDocImage.backImage?.name) {
      formIsValid = false;
      toast.error("Please Upload Selected Document's Back Image");
      return formIsValid;
    }

    return formIsValid;
  };

  // onChangeHandler
  const onChangeHandler = (e, name) => {
    const data = e.target.files[0];
    let url = URL.createObjectURL(data);
    if (name === "aadharFront") {
      setAadharCardImage({
        ...aadharCardImage,
        frontImage: data,
        previewFrontImage: url,
      });
    }
    if (name === "aadharBack") {
      setAadharCardImage({
        ...aadharCardImage,
        backImage: data,
        previewBackImage: url,
      });
    }

    if (name === "otherDocFront") {
      setOtherDocImage({
        ...otherDocImage,
        frontImage: data,
        previewFrontImage: url,
      });
    }

    if (name === "otherDocBack") {
      setOtherDocImage({
        ...otherDocImage,
        backImage: data,
        previewBackImage: url,
      });
    }
  };

  const handleFrontChange = (event) => {
    const selectedFrontValue = event.target.value;
    setSelectedFront(selectedFrontValue);
    setSelectedBack(documentPairs[selectedFrontValue]);
    setOtherDocImage({
      backImage: [],
      frontImage: [],
      previewBackImage: "",
      previewFrontImage: "",
    });
  };

  const handleBackChange = (event) => {
    const selectedBackValue = event.target.value;
    const frontValue = Object.keys(documentPairs).find(
      (key) => documentPairs[key] === selectedBackValue
    );
    setSelectedBack(selectedBackValue);
    setSelectedFront(frontValue);
    setOtherDocImage({
      backImage: [],
      frontImage: [],
      previewBackImage: "",
      previewFrontImage: "",
    });
  };

  // onSubmitHandler
  const onSubmitHandler = async () => {
    if (errorHandler()) {
      try {
        setSubmitButtonLoading(true);
        let sendData = new FormData();
        sendData.append("businessType", "individual");
        sendData.append("document1Type", "aadhar");
        sendData.append("document1FrontSide", aadharCardImage?.frontImage);
        sendData.append("document1BackSide", aadharCardImage?.backImage);
        sendData.append("document2Type", selectedFront);
        sendData.append("document2FrontSide", otherDocImage?.frontImage);
        sendData.append("document2BackSide", otherDocImage?.backImage);
        const { data } = await individualKYCUploadAPI(sendData);
        if (data?.code === 200) {
          toast.success(data?.message);
          loadUploadedKycPage(true);
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitButtonLoading(false);
      }
    }
  };

  // update KYC handler
  const updateKYCHandler = async () => {
    if (errorHandler()) {
      try {
        setSubmitButtonLoading(true);
        let sendData = new FormData();
        sendData.append("businessType", "individual");
        sendData.append("document1Type", "aadhar");
        sendData.append("document1FrontSide", aadharCardImage?.frontImage);
        sendData.append("document1BackSide", aadharCardImage?.backImage);
        sendData.append("document2Type", selectedFront);
        sendData.append("document2FrontSide", otherDocImage?.frontImage);
        sendData.append("document2BackSide", otherDocImage?.backImage);
        const { data } = await individualKYCUpdateAPI(sendData);
        if (data?.code === 200) {
          toast.success(data?.message);
          loadUploadedKycPage(true);
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitButtonLoading(false);
      }
    }
  };


  //***************************** Handle HTML CODE **************************//
  return (
    <>
      <h5>KYC</h5>
      <p>Enter KYC Details</p>
      <form className="upload_doc">
        {/*-- Aadhar card --*/}
        <div className="form_field_wrap">
          <div className="form_field">
            <label className="label">Upload Aadhar Card</label>
          </div>
          <div className="form_field"></div>
        </div>

        <div className="form_field_wrap">
          <div className="form_field file_upload">
            <input
              type="file"
              id="AadharImg"
              onChange={(e) => {
                onChangeHandler(e, "aadharFront");
              }}
              accept="image/png, image/gif, image/jpeg"
            />
            {!aadharCardImage?.frontImage?.name ? (
              <label htmlFor="AadharImg" className="upload_label">
                <img src={cameraImg} alt="" />
                <h5>Front Image</h5>
              </label>
            ) : (
              <div className="preview">
                <label htmlFor="AadharImg" className="edit_doc">
                  <MdOutlineModeEdit />
                </label>
                <img src={aadharCardImage?.previewFrontImage} alt="" />
              </div>
            )}
          </div>

          <div className="form_field file_upload">
            <input
              type="file"
              id="aadharBackImg"
              onChange={(e) => {
                onChangeHandler(e, "aadharBack");
              }}
              accept="image/png, image/gif, image/jpeg"
            />

            {!aadharCardImage?.backImage?.name ? (
              <label htmlFor="aadharBackImg" className="upload_label">
                <img src={cameraImg} alt="" />
                <h5>Back Image</h5>
              </label>
            ) : (
              <div className="preview">
                <label htmlFor="aadharBackImg" className="edit_doc">
                  <MdOutlineModeEdit />
                </label>
                <img src={aadharCardImage?.previewBackImage} alt="" />
              </div>
            )}
          </div>
        </div>

        {/* Select any doc */}
        <div className="form_field_wrap">
          <div className="form_field">
            <Select
              label="Select Document*"
              value={selectedFront}
              onChange={handleFrontChange}
            >
              <option value="">Select Any one Pan/Dl/Passport (Front)</option>
              <option value="panFront">Pan Card (Front)</option>
              <option value="passportFront">Passport (Front)</option>
              <option value="dlFront">Driving License (Front)</option>
            </Select>
          </div>

          <div className="form_field">
            <Select
              label="Select Document*"
              value={selectedBack}
              onChange={handleBackChange}
            >
              <option value="">Select Any one Pan/Dl/Passport (Back)</option>
              <option value="panBack">Pan Card (Back)</option>
              <option value="passportBack">Passport (Back)</option>
              <option value="dlBack">Driving License (Back)</option>
            </Select>
          </div>
        </div>

        {/* Other Doc image */}

        <div className="form_field_wrap">
          <div className="form_field file_upload">
            <input
              type="file"
              id="otherDocImg"
              onChange={(e) => {
                onChangeHandler(e, "otherDocFront");
              }}
              accept="image/png, image/gif, image/jpeg"
              disabled={!selectedFront || !selectedBack ? true : false}
              className={!selectedFront || !selectedBack ? "disabled" : ""}
            />

            {!otherDocImage?.frontImage?.name ? (
              <label
                htmlFor="otherDocImg"
                className={
                  !selectedFront || !selectedBack
                    ? "upload_label disabled"
                    : "upload_label"
                }
              >
                <img src={cameraImg} alt="" />
                <h5>Front Image</h5>
              </label>
            ) : (
              <div className="preview">
                <label htmlFor="otherDocImg" className="edit_doc">
                  <MdOutlineModeEdit />
                </label>
                <img src={otherDocImage?.previewFrontImage} alt="" />
              </div>
            )}
          </div>

          <div className="form_field file_upload">
            <input
              type="file"
              id="otherDocBackImg"
              onChange={(e) => {
                onChangeHandler(e, "otherDocBack");
              }}
              accept="image/png, image/gif, image/jpeg"
              disabled={!selectedFront || !selectedBack ? true : false}
              className={!selectedFront || !selectedBack ? "disabled" : ""}
            />

            {!otherDocImage?.backImage?.name ? (
              <label
                htmlFor="otherDocBackImg"
                className={
                  !selectedFront || !selectedBack
                    ? "upload_label disabled"
                    : "upload_label"
                }
              >
                <img src={cameraImg} alt="" />
                <h5>Back Image</h5>
              </label>
            ) : (
              <div className="preview">
                <label htmlFor="otherDocBackImg" className="edit_doc">
                  <MdOutlineModeEdit />
                </label>
                <img src={otherDocImage?.previewBackImage} alt="" />
              </div>
            )}
          </div>
        </div>

        <div className="button_wrap button_right">
          {!submitButtonLoading ? (
            !kycDetailsData ? (
              <Button type="button" onClick={onSubmitHandler}>
                Submit
              </Button>
            ) : (
              <Button type="button" onClick={updateKYCHandler}>
                Update
              </Button>
            )
          ) : (
            <LoadingButton
              disabled
              className={"disabled"}
              loading={submitButtonLoading}
            />
          )}

          {/* <LoadingButton type="button" onClick={nextStep}>
            Next
          </LoadingButton> */}
        </div>
      </form>
    </>
  );
};

export default IndividualKYC;
