import React from "react";
import { Button } from "../../../../../../../components/form";
import { businessTypeImg } from "../../../../../../../assets/images";

const SelectKYCType = ({ nextStep, userType, setUserType }) => {
  //**************************** Handle all state **************************//

  console.log("userType", userType);

  //**************************** Handle HTML CODE **************************//
  return (
    <>
      <h5>KYC</h5>
      <p>Enter KYC Details</p>
      <div className="business_type">
        {/* Individual Type */}
        <div className="business_type_card">
          <input
            type="radio"
            id="individual"
            value={userType}
            name="businessType"
            checked={userType === "individual"}
            onChange={() => setUserType("individual")}
          />
          <label htmlFor="individual">
            <img src={businessTypeImg} alt="" />
            <h4>Individual</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              iaculis
            </p>
          </label>
        </div>

        {/* Company Type */}

        <div className="business_type_card">
          <input
            type="radio"
            id="Company"
            value={userType}
            name="businessType"
            checked={userType === "company"}
            onChange={() => setUserType("company")}
          />
          <label htmlFor="Company">
            <img src={businessTypeImg} alt="" />
            <h4>Company</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
              iaculis
            </p>
          </label>
        </div>
      </div>
      <div className="button_wrap button_right">
        {userType?.length > 0 ? (
          <Button onClick={nextStep}>Next</Button>
        ) : (
          <Button isDisabled={true}>Next</Button>
        )}
      </div>
    </>
  );
};

export default SelectKYCType;
