import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { logoImg } from "../../../assets/images";
import { sideNavData } from "../../../config/utils";

const UserNav = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const navigations = () => {
    document.body.classList.remove("innerside_toogle");
  };
  return (
    <>
      <nav>
        <div className="logo_image">
          <img src={logoImg} alt="MV loader" />
        </div>
        <ul onClick={navigations}>
          {sideNavData?.map((item) => (
            <li key={item?.id}>
              <NavLink to={item?.route} className={item?.itemClass}>
                <img src={item?.icon} alt="" />
                {item?.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default UserNav;
