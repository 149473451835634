import { createContext, useEffect, useState } from "react";

export const GlobalOrderContext = createContext();

const OrderContext = ({ children }) => {
  const [vendorDetail, setVendorDetail] = useState({});
  const [orderDetailsForm, setOrderDetailsForm] = useState(undefined);
  const [orderSearchFieldData, setOrderSearchFieldData] = useState({});
  const [orderDetailsForPayment, setOrderDetailsForPayment] = useState({});

  const [orderIdForLabelDownload, setOrderIdForLabelDownload] = useState("");

  //*********************** Handle HTML CODE *********************//
  return (
    <GlobalOrderContext.Provider
      value={{
        vendorDetail,
        setVendorDetail,
        orderDetailsForm,
        setOrderDetailsForm,
        orderSearchFieldData,
        orderDetailsForPayment,
        setOrderSearchFieldData,
        setOrderDetailsForPayment,
      }}
    >
      {children}
    </GlobalOrderContext.Provider>
  );
};

export default OrderContext;
