import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const AreaChart = ({ seriesName, dataArray }) => {
  console.log("I am Series Name", seriesName);
  console.log("I am Array", dataArray);

  const chartData = {
    series: [
      {
        name: seriesName,
        data: [...dataArray],
      },
    ],

    options: {
      chart: {
        type: "line",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      markers: {
        size: 8,
        colors: "white",
        strokeColors: "#AE8FF7",
        strokeWidth: 3,
        strokeOpacity: 0.6,
        shape: "circle",
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: 9,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#5BC4FF","#FF5BEF"],
      },

      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#AE8FF7"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      xaxis: {
        categories: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEPT",
          "OCT",
          "NOV",
          "DEC",
        ],
      },
      tooltip: {
        x: {
          format: "MMM", // Display month in the tooltip
        },
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default AreaChart;
