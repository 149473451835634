import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  // baseURL: `${process.env.REACT_APP_API_LOCAL_URL}`,
});

export const setHeaderWithImage = () => {
  const localData = localStorage.getItem("authDetail");
  const parseLocalData = JSON.parse(localData);
  const { token } = parseLocalData;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
};

export const setToken = () => {
  const localData = localStorage.getItem("authDetail");
  const parseLocalData = JSON.parse(localData);
  const { token } = parseLocalData;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const resInterceptor = (response) => {
      console.log("I AM RESPONSE FROM INTERCEPTOR  @@@@@@@@@@----->", response);
      if (response.data.status === 401 || response.data.status === 404) {
        localStorage.clear();
        navigate("/");
      }
      return response;
    };

    const errInterceptor = (error) => {
      console.log(error);
      return error;
    };

    const interceptor = Instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    setIsSet(true);

    return () => Instance.interceptors.response.eject(interceptor);
    // eslint-disable-next-line
  }, []);

  return isSet && children;
};
