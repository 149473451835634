import React, { Children } from "react";
import "./Drawer.scss";
import { FaTimes } from "react-icons/fa";
import { Offcanvas } from "react-bootstrap";

const CustomDrawer = ({
  show,
  handleClose,
  children,
  direction,
  className,
}) => {
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={direction}
        className={`customDrawer ${className}`}
      >
        <div className="drawer_content">
          <button type="button" className="close_button" onClick={handleClose}>
            <FaTimes />
          </button>
          {children}
        </div>
      </Offcanvas>
    </>
  );
};

export default CustomDrawer;
