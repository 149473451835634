import { CompanyLogoImg } from "assets/images";
import { RatingStar } from "components/form";
import moment from "moment";
import React from "react";

const MyOrderSummery = (props) => {
  const { vendor } = props?.vendorDetail;
  const { orderDetail } = props?.vendorDetail;

  return (
    <>
      <div className="order_summery _card">
        <div className="head">
          {!vendor?.image ? (
            <img src={CompanyLogoImg} alt="Not Available" />
          ) : (
            <img src={vendor?.image} alt="Not-found" />
          )}

          <div className="content">
            <h4>{vendor?.name || "N/A"}</h4>
            <RatingStar user={"4 Rating"} initialRating={4} readonly />
          </div>
        </div>

        <ul>
          <li>
            <span>Order No.</span>
            <span>{orderDetail?.Order_id || "------"}</span>
          </li>
          <li>
            <span>Inserted Date & Time.</span>
            <span>{orderDetail?.createdAt || "------"}</span>
          </li>
          <li>
            <span>From Pin</span>
            <span>{orderDetail?.Frompincode || "------"}</span>
          </li>
          <li>
            <span>To Pin</span>
            <span>{orderDetail?.Topincode || "------"}</span>
          </li>
          <li>
            <span>Taxable Amount</span>
            <span>{orderDetail?.taxableAmount || "------"}</span>
          </li>
          <li>
            <span>GST Amount</span>
            <span>{orderDetail?.gst_Amount || "------"}</span>
          </li>
          <li>
            <span>Total Amount</span>
            <span>{orderDetail?.totalAmount || "------"}</span>
          </li>
          <li>
            <span>Order Status</span>
            <span>{orderDetail?.status || "------"}</span>
          </li>
          <li>
            <span>ETA</span>
            <span>{orderDetail?.ExpectedDelivery || "------"}</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MyOrderSummery;
