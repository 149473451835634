import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  LoadingButton,
  Select,
} from "../../../../../../components/form";
import {
  cityListingApi,
  createAddressAPI,
  editAddressAPI,
  stateListingApi,
} from "../../../../../../services/user-controllers/addressCont";
import { toast } from "react-toastify";

const AddressHandler = ({
  title,
  callRole,
  addressList,
  addressDetail,
  closeAddressModal,
}) => {
  //**----------------------------- Handle all States ---------------------**//

  const [cityListing, setCityListing] = useState([]);
  const [stateListing, setStateListing] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [isCityDisable, setIsCityDisable] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [addressFields, setAddressFields] = useState({
    name: "",
    fullAddress: "",
    state: "",
    city: "",
    pincode: "",
    mobile: "",
    country: "India",
  });

  const [addressFieldsValidationError, setAddressFieldValidationError] =
    useState({
      name: "",
      fullAddress: "",
      state: "",
      city: "",
      pincode: "",
      mobile: "",
      country: "",
    });

  const [extraErrorField, setExtraErrorField] = useState(false);

  //*********************************** Handle all functions **********************************//
  // useEffect for get edit address details
  useEffect(() => {
    if (callRole === "edit") {
      setAddressFields(addressDetail);
      setIsCityDisable(false);
      setSelectedState(addressDetail?.state);
    } else {
      setAddressFields({
        name: "",
        fullAddress: "",
        state: "",
        city: "",
        pincode: "",
        mobile: "",
        country: "India",
      });
    }
  }, [callRole]);

  // get state listing handler
  const getStateListingHandler = async () => {
    try {
      const { data } = await stateListingApi();
      if (data?.code === 200) {
        setStateListing(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // get city listing handler
  const cityListingHandler = async () => {
    try {
      const { data } = await cityListingApi(selectedState);
      if (data?.code === 200) {
        setCityListing(data?.data);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect for get state,city listing
  useEffect(() => {
    if (selectedState?.length > 0) {
      cityListingHandler();
    } else {
      getStateListingHandler();
    }
  }, [selectedState]);

  // ErrorHandler
  const errorHandler = () => {
    let errorField = {};
    let extraError = {
      mobile: false,
    };

    let formIsValid = true;
    // let numberFormat = /^(\+\d{1,3}\s)?\d{12}$/;
    let numberFormat = /^\d{1,12}$/;

    if (!addressFields.name) {
      formIsValid = false;
      errorField["name"] = "Please Enter Address Name*";
    }

    if (!addressFields.fullAddress) {
      formIsValid = false;
      errorField["fullAddress"] = "Please Enter Full Address* ";
    }

    if (!addressFields.state) {
      formIsValid = false;
      errorField["state"] = "Please Select State*";
    }

    if (!addressFields.city) {
      formIsValid = false;
      errorField["city"] = "Please Select City* ";
    }

    if (!addressFields.pincode) {
      formIsValid = false;
      errorField["pincode"] = "Please Enter Pincode *";
    }

    if (!addressFields?.mobile) {
      formIsValid = false;
      errorField["mobile"] = "Please Enter Mobile Number*";
    }
    //  else if (!String(addressFields?.mobile)?.match(numberFormat)) {
    //   formIsValid = false;
    //   errorField["mobile"] = "Invalid Mobile";
    //   extraError["mobile"] = true;
    // }

    setExtraErrorField(extraError?.mobile);
    setAddressFieldValidationError(errorField);

    return formIsValid;
  };

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "pincode") {
      const zipCodeValue = value.replace(/[^0-9]/g, "");
      setAddressFields({ ...addressFields, pincode: zipCodeValue });
    } else if (name === "mobile") {
      const mobileNumericValue = value.replace(/[^0-9]/g, "");
      setAddressFields({ ...addressFields, mobile: mobileNumericValue });
    } else {
      setAddressFields({ ...addressFields, [name]: value });
    }
  };

  // onSubmitHandler
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (errorHandler()) {
      try {
        setButtonLoading(true);
        const sendData = addressFields;
        console.log("DATA FOR SEND", sendData);

        const apiCall = callRole === "add" ? createAddressAPI : editAddressAPI;
        const { data } = await apiCall(sendData);

        if (data?.code === 200) {
          closeAddressModal();
          addressList();
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setButtonLoading(false);
      }
    }
  };

  //**---------------------------- Handle HTML CODE -----------------------**//
  return (
    <div>
      <h3>{title}</h3>

      <div className="form_field">
        <Input
          type="text"
          label="Address Name*"
          placeholder="Enter Address Name"
          name="name"
          value={addressFields?.name}
          onChange={onChangeHandler}
          error={addressFieldsValidationError?.name}
        />
      </div>
      <div className="form_field">
        <Input
          type="text"
          name="fullAddress"
          placeholder="Enter Full Address"
          label="Full Address*"
          onChange={onChangeHandler}
          value={addressFields?.fullAddress}
          error={addressFieldsValidationError?.fullAddress}
        />
      </div>
      <div className="form_field_wrap">
        <div className="form_field">
          <Input
            type="text"
            readOnly={true}
            placeholder="Enter Country"
            label="Country*"
            value={addressFields?.country}
          />
        </div>

        <div className="form_field">
          <Select
            label="State*"
            value={addressFields?.state}
            error={addressFieldsValidationError?.state}
            onChange={(e) => {
              setSelectedState(e?.target?.value);
              setAddressFields({ ...addressFields, state: e.target.value });
              setIsCityDisable(false);
            }}
          >
            <option value="">Select state</option>
            {stateListing?.length > 0 &&
              stateListing?.map((el, i) => (
                <option key={i}>{el?.state}</option>
              ))}
          </Select>
        </div>
      </div>

      <div className="form_field_wrap">
        <div className="form_field">
          <Select
            label="City*"
            disabled={isCityDisable}
            value={addressFields?.city}
            onChange={(e) => {
              setSelectedCity(e.target.value);
              setAddressFields({ ...addressFields, city: e.target.value });
            }}
            className={!addressFieldsValidationError?.city ? "" : "is-invalid"}
          >
            {cityListing?.length <= 0 ? (
              <option>Please select state first</option>
            ) : (
              <>
                <option value="">Select City</option>
                {cityListing?.map((el, i) => (
                  <option key={i}>{el?.city}</option>
                ))}
              </>
            )}
          </Select>

          {addressFieldsValidationError?.city?.length > 0 && (
            <span className={`invalid-feedback`}>
              {addressFieldsValidationError?.city}
            </span>
          )}
        </div>

        <div className="form_field">
          <Input
            type="text"
            maxLength={6}
            name="pincode"
            label="Pincode*"
            placeholder="Enter Pincode"
            onChange={onChangeHandler}
            value={addressFields?.pincode}
            error={addressFieldsValidationError?.pincode}
          />
        </div>
      </div>
      <div className="form_field_wrap">
        <div className="form_field">
          <Input
            type="text"
            name="mobile"
            maxLength={10}
            placeholder="Enter Mobile No."
            label="Mobile No*"
            onChange={onChangeHandler}
            extraError={extraErrorField}
            value={addressFields?.mobile}
            error={addressFieldsValidationError?.mobile}
          />
        </div>
      </div>

      <div className="button_wrap">
        {!buttonLoading ? (
          <Button className={"w-100"} onClick={onSubmitHandler}>
            {callRole === "add" ? `Add` : `Update`}
          </Button>
        ) : (
          <LoadingButton
            disabled
            className={"w-100 disabled"}
            loading={buttonLoading}
          />
        )}
      </div>
    </div>
  );
};

export default AddressHandler;
