import React from "react";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";

const RazorPayPayment = () => {
  const { error, isLoading, Razorpay } = useRazorpay();

  const displayRazorpay = () => {
    const options = {
      key: "rzp_test_7J1g2JqQnwPok8",
      amount: 50000, // Amount in paise
      currency: "INR",
      name: "Test Company",
      description: "Test Transaction",
      callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",

      //   order_id: "order_9A33XWu170gUtm", // Generate order_id on server

      prefill: {
        name: "John Doe",
        email: "john.doe@example.com",
        contact: "9999999999",
        method: "card|upi|wallet", //card|upi|wallet
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#F37254",
      },
    };

    const razorpayInstance = new Razorpay(options);
    razorpayInstance.open();
  };

  return (
    <div className="App">
      <h1>Payment Page</h1>
      {/* {isLoading && <p>Loading Razorpay...</p>}
      {error && <p>Error loading Razorpay: {error}</p>} */}
      <button onClick={displayRazorpay}>Pay Now</button>
    </div>
  );
};

export default RazorPayPayment;
