import {
  bookingsIcon,
  creditCardIcon,
  downloadIcon,
  filterIcon,
  invoiceIcon,
  shortingIcon,
  tableShortingIcon,
} from "assets/icons";
import { Button, DatePicker, Select } from "components/form";
import CustomModal from "components/modals/CustomModal";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { CgArrowsExchangeV } from "react-icons/cg";
import { Link } from "react-router-dom";
import { IoMdEyeOff } from "react-icons/io";
import useDebounce from "hooks/useDebounce";
import {
  filterOperatorDropdown,
  invoiceListingFilterColumns,
} from "./invoice-config/invoice-config";
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { toast } from "react-toastify";
import moment from "moment";
import {
  downloadOrderLabelAPI,
  exportInvoiceAPI,
  getInvoiceListAPI,
} from "services/user-controllers/invoiceController";
import ColumnFilterDropdown from "components/filter/ColumnFilterDropdown";
import TableHead from "components/table/TableHead";
import TableBody from "components/table/TableBody";
import Pagination from "react-js-pagination";
import FilterModal from "components/filter/FilterModal";

const InvoicesLIsting = () => {
  const [labelId, setLabelId] = useState("");
  const [downloadLabelState, setDownloadLabelState] = useState({
    id: "",
    status: false,
  });

  //----- invoice list column array --------//
  const invoiceListColumnFilterArray = [
    {
      key: "srNo",
      name: "S. No",
      enable: true,
      formatHandler: (item, col, i) => {
        return <td>{i + 1}</td>;
      },
    },
    {
      enable: true,
      sort: "DESC",
      icon: <FaArrowUp />,
      name: "Invoice No.",
      key: "invoiceNumber",
    },
    {
      enable: true,
      sort: "DESC",
      key: "createdAt",
      icon: <FaArrowUp />,
      name: "Invoice Date",
      formatHandler: (item, col) => {
        return <td>{moment(item[col?.key]).format("DD MMM, YYYY")}</td>;
      },
    },
    {
      sort: "DESC",
      enable: true,
      key: "serviceFrom",
      icon: <FaArrowUp />,
      name: "Service Form",
      actionKey: "vendor?.name",
      formatHandler: (item, col) => {
        return <td>{item?.vendorold?.name}</td>;
      },
    },
    {
      sort: "DESC",
      enable: true,
      key: "paymentTo",
      name: "Payment to",
      icon: <FaArrowUp />,
      formatHandler: () => {
        return <td>M-Vikas</td>;
      },
    },
    {
      sort: "DESC",
      enable: true,
      key: "taxableAmount",
      icon: <FaArrowUp />,
      name: "Taxable Amount",
    },
    {
      name: "GST",
      sort: "DESC",
      enable: true,
      key: "gst_Amount",
      icon: <FaArrowUp />,
    },
    {
      sort: "DESC",
      enable: true,
      key: "totalAmount",
      icon: <FaArrowUp />,
      name: "Total Amount",
    },
    {
      enable: true,
      sort: "DESC",
      name: "Status",
      key: "paymentStatus",
      icon: <FaArrowUp />,
      formatHandler: (item, col) => {
        if (item?.paymentMode === "Paylater") {
          return (
            <td key={col.key} className="nowrap">
              <span className="tag">un-paid</span>
            </td>
          );
        } else {
          return (
            <td key={col.key} className="nowrap">
              <span className="tag green">Paid</span>
            </td>
          );
        }
      },
    },
    {
      key: "id",
      enable: true,
      name: "Download",
      formatHandler: function (item, col) {
        if (
          item[col?.key] === downloadLabelState?.id &&
          downloadLabelState?.status
        ) {
          return (
            <td key={col.key} className="nowrap">
              Please wait...
            </td>
          );
        } else {
          return (
            <td key={col.key} className="nowrap">
              <button
                type="button"
                onClick={(e) => {
                  setLabelId(item?.id);
                  setDownloadLabelState({
                    status: true,
                    id: item[col?.key],
                  });
                }}
              >
                <img src={downloadIcon} alt="" />
              </button>
            </td>
          );
        }
      },
    },
  ].filter((el) => el.enable);

  //***************************** Handle all state *****************************//

  const [totalRecord, setTotalRecord] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [invoiceRecordListing, setInvoiceRecordListing] = useState([]);

  const [processedColumnsArray, setProcessedColumnsArray] = useState(
    invoiceListColumnFilterArray
  );
  const [togglerSwitch, setTogglerSwitch] = useState(false);

  //---- state for handle sorting----//
  const [sortingToggler, setSortingToggler] = useState(false);
  const [sortingFilterField, setSortingFilterField] = useState({
    isSortApply: 0,
    sortColumnName: "",
    sortingOrder: "",
  });

  //------- handle all filter state ---------//
  const [limitRow, setLimitRow] = useState(5);
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [startDateFilter, setStartDate] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [endDateFilter, setEndDateFilter] = useState("");
  const [filterModalFields, setFilterModalFields] = useState({
    columnFilterName: "",
    operatorFilterName: "",
    filterValue: "",
    isApplied: false,
  });

  const searchDebounceValue = useDebounce(searchValue, 500);
  const endDateDebounceValue = useDebounce(endDateFilter, 500);
  const startDateDebounceValue = useDebounce(startDateFilter, 500);

  //***************************** Handle all function ***************************//

  // reset-filter handler
  const resetFilterHandler = () => {
    if (filterModalFields?.columnFilterName?.length > 0) {
      setFilterModalFields({
        columnFilterName: "",
        operatorFilterName: "",
        filterValue: "",
      });
    }
  };

  // pageChangeHandler for pagination
  const handlePageChange = (event) => {
    console.log(event);
    setActivePage(event);
  };

  //------------------ Functions for handle filter andy dynamic table columns --------------------//
  // toggleColumnVisibility handler for set array of hidden column name
  const toggleColumnVisibility = (key) => {
    if (!hiddenColumns?.length) {
      let updateHidden = {
        sort: "DESC",
        icon: <FaArrowUp />,
        hiddenValue: key,
      };

      let hiddenArr = [updateHidden];
      setHiddenColumns(hiddenArr);
    } else {
      if (hiddenColumns.some((el) => el.hiddenValue === key)) {
        let updatedHiddenArray = hiddenColumns.filter(
          (el) => el.hiddenValue !== key
        );
        setHiddenColumns(updatedHiddenArray);
      } else {
        let clone = [...hiddenColumns];
        let updatedHiddenCol = {
          sort: "DESC",
          icon: <FaArrowUp />,
          hiddenValue: key,
        };
        clone = [...clone, { ...updatedHiddenCol }];
        setHiddenColumns(clone);
      }
    }
    setTogglerSwitch(!togglerSwitch);
  };

  // hideAllColumnsHandler for hide all columns of table
  const hideAllColumns = () => {
    setHiddenColumns(
      invoiceListColumnFilterArray.map((col) => {
        return {
          sort: col.sort,
          icon: col.icon,
          hiddenValue: col?.key,
        };
      })
    );
    setTogglerSwitch(!togglerSwitch);
  };

  // Show all columns handler for show all table's data
  const showAllColumns = () => {
    setHiddenColumns([]);
    setTogglerSwitch(!togglerSwitch);
  };

  // sort toggle Handler
  const toggleSortHandler = (keyValue, col) => {
    let index = 0;
    let updatedCol;
    let isApplied = 0;
    let sortFields = {};
    let cloneProcessedArray = processedColumnsArray?.map((el) => el);

    if (cloneProcessedArray?.length > 0) {
      updatedCol = processedColumnsArray.find((el) => el.key === keyValue);
      index = cloneProcessedArray.findIndex((obj) => obj.key === keyValue);
      if (updatedCol?.sort === "DESC") {
        updatedCol = {
          ...updatedCol,
          sort: "ASC",
          icon: <FaArrowDown />,
          isHidden: hiddenColumns.some((el) => el?.hiddenValue === keyValue),
        };
        isApplied = 1;
      } else {
        updatedCol = {
          ...updatedCol,
          sort: "DESC",
          icon: <FaArrowUp />,
          isHidden: hiddenColumns.some((el) => el?.hiddenValue === keyValue),
        };
        isApplied = 2;
      }
    }
    cloneProcessedArray[index] = updatedCol;
    setProcessedColumnsArray(cloneProcessedArray);

    if (isApplied === 1 || isApplied === 2) {
      sortFields = {
        isSortApply: isApplied,
        sortColumnName: keyValue,
        sortingIcon: updatedCol?.icon,
        sortingOrder: updatedCol?.sort,
      };
    }
    setSortingFilterField({ ...sortFields });
    setSortingToggler(!sortingToggler);
  };
  //------------------ Close Functions for handle filter andy dynamic table columns --------------------//

  // get order list handler for get order data
  const getInvoiceListHandler = async (
    page = activePage,
    search = searchDebounceValue,
    status = statusValue,
    fromDate = startDateDebounceValue
      ? moment(startDateDebounceValue).format("YYYY-MM-DD")
      : "",
    toDate = endDateDebounceValue
      ? moment(endDateDebounceValue).format("YYYY-MM-DD")
      : "",
    limit = limitRow,

    isSort = sortingFilterField?.isSortApply === 1 ||
    sortingFilterField?.isSortApply === 2
      ? sortingFilterField?.isSortApply
      : 0,
    sortColumnName = sortingFilterField?.isSortApply === 1 ||
    sortingFilterField?.isSortApply === 2
      ? sortingFilterField?.sortColumnName
      : "",
    sortingOrder = sortingFilterField?.isSortApply === 1 ||
    sortingFilterField?.isSortApply === 2
      ? sortingFilterField?.sortingOrder
      : "",

    column = filterModalFields?.columnFilterName,
    operator = filterModalFields?.operatorFilterName,
    value = filterModalFields?.filterValue
  ) => {
    try {
      const { data } = await getInvoiceListAPI(
        page,
        search,
        status,
        fromDate,
        toDate,
        limit,
        isSort,
        sortColumnName,
        sortingOrder,
        column,
        operator,
        value
      );

      if (data?.code === 200) {
        setTotalRecord(data?.data?.count || 0);
        setInvoiceRecordListing(data?.data?.list);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Export Payment Listing Handler
  const exportInvoiceListHandler = async (
    e,
    page = activePage,
    limit = limitRow,
    search = searchValue,
    fromDatE = startDateFilter
      ? moment(startDateFilter).format("YYYY-MM-DD")
      : "",
    toDatE = endDateFilter ? moment(endDateFilter).format("YYYY-MM-DD") : "",
    column = filterModalFields?.columnFilterName,
    operator = filterModalFields?.operatorFilterName,
    value = filterModalFields?.filterValue
  ) => {
    try {
      const response = await exportInvoiceAPI(
        page,
        limit,
        search,
        fromDatE,
        toDatE,
        column,
        operator,
        value
      );
      const { data, headers } = response;

      if (response.status !== 200) {
        return toast.error("Failed to download the file.");
      }

      // Create a blob from the response data
      const blob = new Blob([data], { type: headers["content-type"] });

      // Create a link element to download the file
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute with a filename
      const filename =
        headers["content-disposition"]?.split("filename=")[1] || "invoice.xlsx";
      link.download = filename.replace(/"/g, "");

      // Append the link to the body, click it to trigger the download, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log("EXPORT ORDER LISTING RESPONSE", data);
    } catch (err) {
      console.error(err);
      toast.error("Error occurred while downloading the file.");
    }
  };

  // Function for download invoice label
  const downloadLabelHandler = async (e, itemId) => {
    let cloneProcessed = invoiceListColumnFilterArray.map((col) => {
      return {
        ...col,
        isHidden: hiddenColumns.some((el) => el?.hiddenValue === col.key),
      };
    });
    setProcessedColumnsArray(cloneProcessed);
    try {
      const { data } = await downloadOrderLabelAPI(
        labelId
        // downloadLabelState?.id
      );
      if (data?.code === 200) {
        const orderLabelUrl = data.data.url;
        setTogglerSwitch(!togglerSwitch);
        window.open(orderLabelUrl, "_blank");
      } else {
        toast?.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTogglerSwitch(!togglerSwitch);
      setDownloadLabelState({ id: "", status: false });
    }
  };

  // useEffect for get order listing and handle filter
  useEffect(() => {
    getInvoiceListHandler();

    // eslint-disable-next-line
  }, [
    activePage,
    searchDebounceValue,
    statusValue,
    startDateDebounceValue,
    endDateDebounceValue,
    limitRow,
    sortingFilterField.isSortApply,
    sortingToggler,
    filterModalFields?.isApplied,
  ]);

  // useEffect for download label of sales report
  useEffect(() => {
    if (downloadLabelState?.status) {
      downloadLabelHandler();
    }
  }, [downloadLabelState?.status]);

  // useEffect for handle processed column
  useEffect(() => {
    const processedColumnHandler = () => {
      let cloneProcessed = invoiceListColumnFilterArray.map((col) => {
        return {
          ...col,
          isHidden: hiddenColumns.some((el) => el?.hiddenValue === col.key),
        };
      });

      setProcessedColumnsArray(cloneProcessed);
    };

    processedColumnHandler();
  }, [togglerSwitch]);

  //********************************************* Handle HTML CODE **************************************************//

  return (
    <>
      <div className="my_orders_list">
        <div class="dashboard_title">
          <h4>Invoice</h4>
        </div>

        <div className="button_section">
          <div className="form_field">
            <div className="search_box">
              <IoSearchOutline />
              <input
                type="search"
                value={searchValue}
                placeholder="Search"
                className="from-control input"
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
          <div className="form_field has_icon">
            <img src={invoiceIcon} alt="" className="icon" />
            <Select
              value={statusValue}
              onChange={(e) => setStatusValue(e.target.value)}
            >
              <option value={""}>All</option>
              <option value="success">Paid</option>
              <option value="Pending">Unpaid</option>
            </Select>
          </div>
          <div className="form_field">
            <DatePicker
              placeholder="From"
              value={startDateFilter}
              onChange={(date) => setStartDate(date)}
              isClearable={startDateFilter ? true : false}
            />
          </div>
          <div className="form_field">
            <DatePicker
              placeholder="To"
              value={endDateDebounceValue}
              onChange={(date) => setEndDateFilter(date)}
              isClearable={endDateDebounceValue ? true : false}
            />
          </div>

          {/* <Button>Search</Button> */}
        </div>

        {/*----------- Handle Filter and Column filter -------------*/}
        <div className="table_filter_wrap">
          <div className="filter_buttons">
            {/* handle filters dropdown */}
            <Dropdown>
              <Dropdown.Toggle onClick={() => setFilterModal(true)}>
                <img src={filterIcon} alt="" />
                <span>Filters</span>
              </Dropdown.Toggle>
            </Dropdown>

            {/* handle column filter dropdown */}
            <ColumnFilterDropdown
              columnsArray={processedColumnsArray}
              onToggleColumn={toggleColumnVisibility}
              hideAllColumns={hideAllColumns}
              showAllColumns={showAllColumns}
            />

            {/* Reset Filter */}
            {filterModalFields?.isApplied && (
              <Button onClick={resetFilterHandler}>Reset Filter</Button>
            )}
          </div>

          <Button onClick={(e) => exportInvoiceListHandler(e)}>
            <img src={downloadIcon} alt="" /> Export
          </Button>
        </div>

        <div className="table_wrap">
          <table className="table">
            {/* <thead>
              <tr>
                <th className="nowrap">
                  S. No.
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
                <th className="nowrap">
                  Invoice No.
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
                <th className="nowrap">
                  Invoice Date
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
                <th className="nowrap">
                  Service From
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
                <th className="nowrap">
                  Payment to
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
                <th className="nowrap">
                  Taxable Amount
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
                <th className="nowrap">
                  GST
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
                <th className="nowrap">
                  Total Amount
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
                <th className="nowrap">
                  Status
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
                <th className="nowrap">
                  Download
                  <button type="button">
                    <img src={tableShortingIcon} alt="" />
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>2024/LF/9587</td>
                <td>26-06-2024</td>
                <td>DP World</td>
                <td>SupportMvikas</td>
                <td>710.5</td>
                <td>127.89</td>
                <td>838.39</td>
                <td>
                  <span className="tag green">Paid</span>
                </td>
                <td>
                  <button type="button">
                    <img src={downloadIcon} alt="" />
                  </button>
                </td>
              </tr>
              <tr>
                <td>1.</td>
                <td>2024/LF/9587</td>
                <td>26-06-2024</td>
                <td>DP World</td>
                <td>SupportMvikas</td>
                <td>710.5</td>
                <td>127.89</td>
                <td>838.39</td>
                <td>
                  <span className="tag green">Paid</span>
                </td>
                <td>
                  <button type="button">
                    <img src={downloadIcon} alt="" />
                  </button>
                </td>
              </tr>
            </tbody> */}
            <TableHead
              sortHandler={toggleSortHandler}
              columnsArray={processedColumnsArray}
            />
            <TableBody
              listingData={invoiceRecordListing}
              columnsArray={processedColumnsArray}
            />
          </table>
        </div>

        {/*-------- Handle Pagination ---------*/}
        <div className="pagination_wrapper">
          <div className="pages_count">
            <p>
              <span>Rows per pages</span>{" "}
              <Select
                value={limitRow}
                onChange={(e) => setLimitRow(e.target.value)}
              >
                <option value={5}>05</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </Select>
            </p>
            {/* <span className="page_tag">1-6 of 6</span> */}
          </div>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            pageRangeDisplayed={5}
            activePage={activePage}
            // prevPageText={"Prev"}
            // nextPageText={"Next"}
            // itemClassPrev={"prev"}
            // itemClassNext={"next"}
            onChange={handlePageChange}
            itemsCountPerPage={limitRow}
            totalItemsCount={totalRecord}
            itemClassLast={"last_page next"}
            itemClassFirst={"first_page prev"}
          />
        </div>
      </div>

      {/* filter dropdown modal */}
      <CustomModal
        show={filterModal}
        className={"filterModal"}
        handleClose={() => setFilterModal(false)}
      >
        <FilterModal
          filterFields={filterModalFields}
          setModalVisibility={setFilterModal}
          setFilterFields={setFilterModalFields}
          operatorArray={filterOperatorDropdown}
          columnArray={invoiceListingFilterColumns}
        />
      </CustomModal>
    </>
  );
};

export default InvoicesLIsting;
