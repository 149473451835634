import React, { useEffect, useState } from "react";
import {
  cardIcon,
  darkBankIcon,
  darkCalendarIcon,
  darkPaymentIcon,
  darkSecurityIcon,
  dashIcon,
  docIcon,
  dollarIcon,
  locationDIcon,
} from "../../../../assets/icons";
import "./DashboardHome.scss";
import { reportImg, reportImg2 } from "../../../../assets/images";
import { getDataToLocal } from "../../../../config/utils";
import { getDashboardDetailsAPI } from "services/user-controllers/masterController";
import { toast } from "react-toastify";
import AreaChart from "components/charts/AreaChart";
import PaiChart from "components/charts/PaiChart";
import { Link, useNavigate } from "react-router-dom";

const DashboardHomeUser = () => {
  const navigate = useNavigate();

  //**************************** Handle all state ***********************//
  const [orderChartArray, setOrderChartArray] = useState([]);
  const [reportChartArray, setReportChartArray] = useState([]);
  const [dashboardDataRecord, setDashboardDataRecord] = useState({});

  //************************** Handle all functions *********************//

  // useEffect for get data from local
  useEffect(() => {
    getDataToLocal("authDetail");
  }, []);

  // get dashboard details handler
  const getDashboardDetailsHandler = async () => {
    try {
      const { data } = await getDashboardDetailsAPI();
      console.log("i am dashboard details data", data);
      if (data?.code === 200) {
        const { orderChart, reportChart, ...rest } = data?.data;
        setDashboardDataRecord(rest);
        setOrderChartArray(orderChart);
        setReportChartArray(reportChart);
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect for get dashboard details
  useEffect(() => {
    getDashboardDetailsHandler();

    // eslint-disable-next-line
  }, []);

  //********************************* Handle HTML CODE ******************************//
  return (
    <>
      <div className="dashboard_home">
        {dashboardDataRecord?.accountType === "postpaid" && (
          <div className="card_wrap method">
            <div className="item">
              <div className="_card">
                <img src={darkBankIcon} alt="" />
                <p>
                  <span>A/C Type:</span>
                  <b>
                    {dashboardDataRecord?.accountType === "postpaid"
                      ? "POSTPAID"
                      : "PREPAID"}
                  </b>
                </p>
              </div>
            </div>

            <div className="item">
              <div className="_card">
                <img src={darkPaymentIcon} alt="" />
                <p>
                  <span>Your Credit Limit:</span>
                  <b>{dashboardDataRecord?.creditLimit || "----"}</b>
                </p>
              </div>
            </div>

            <div className="item">
              <div className="_card">
                <img src={darkSecurityIcon} alt="" />
                <p>
                  <span>Available Limit:</span>
                  <b>{dashboardDataRecord?.availableLimit || "----"}</b>
                </p>
              </div>
            </div>

            <div className="item">
              <div className="_card">
                <img src={darkCalendarIcon} alt="" />
                <p>
                  <span>Your Next Billing Is On:</span>
                  <b>{dashboardDataRecord?.nextBillingDate || "----"}</b>
                </p>
              </div>
            </div>

            <div className="item">
              <div className="_card">
                <img src={darkCalendarIcon} alt="" />
                <p>
                  <span>Your Payment Is Due On:</span>
                  <b>{dashboardDataRecord?.paymentDueDate || "----"}</b>
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="card_wrap data_card mt-3">
          <div
            className="item"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/user/dashboard/my-orders");
            }}
          >
            <div className="_card">
              <p>
                <span>No. of Orders</span>
                <b>{dashboardDataRecord?.noOfOrder || "----"}</b>
                <strong>Last 30 days</strong>
              </p>

              <img src={dashIcon} alt="" />
            </div>
          </div>

          <div
            className="item"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/user/dashboard/my-orders");
            }}
          >
            <div className="_card">
              <p>
                <span>Total Order Value</span>
                <b>{dashboardDataRecord?.totalOrderValue || "----"}</b>
                <strong>Last 30 days</strong>
              </p>

              <img src={dollarIcon} alt="" />
            </div>
          </div>
          <div
            className="item"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/user/dashboard/my-orders");
            }}
          >
            <div className="_card">
              <p>
                <span>Total Payment Pending</span>
                <b>{dashboardDataRecord?.totalPaymentPending || "----"}</b>
                <strong>Last 30 days</strong>
              </p>

              <img src={cardIcon} alt="" />
            </div>
          </div>
          <div
            className="item"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/user/dashboard/my-orders");
            }}
          >
            <div className="_card">
              <p>
                <span>Total No. Invoices</span>
                <b>{dashboardDataRecord?.noOfInvoice || "----"}</b>
                <strong>Last 30 days</strong>
              </p>

              <img src={docIcon} alt="" />
            </div>
          </div>
        </div>

        <div className="graph_section">
          <div className="char_left">
            <ul>
              <li
                className="item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/user/dashboard/my-orders");
                }}
              >
                <div className="_card">
                  <b>{dashboardDataRecord?.booked || "----"}</b>
                  <span>Booked</span>
                </div>
              </li>
              <li
                className="item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/user/dashboard/my-orders");
                }}
              >
                <div className="_card">
                  <b>{dashboardDataRecord?.pickedUp || "----"}</b>
                  <span>PICKED UP</span>
                </div>
              </li>
              <li
                className="item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/user/dashboard/my-orders");
                }}
              >
                <div className="_card">
                  <b>{dashboardDataRecord?.transit || "----"}</b>
                  <span>In-Transit</span>
                </div>
              </li>
              <li
                className="item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/user/dashboard/my-orders");
                }}
              >
                <div className="_card">
                  <b>{dashboardDataRecord?.rto || "----"}</b>
                  <span>RTO</span>
                </div>
              </li>
              <li
                className="item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/user/dashboard/my-orders");
                }}
              >
                <div className="_card">
                  <b>{dashboardDataRecord?.delivered || "----"}</b>
                  <span>Delivered</span>
                </div>
              </li>
              <li
                className="item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/user/dashboard/my-orders");
                }}
              >
                <div className="_card">
                  <b>{dashboardDataRecord?.cancelled || "----"}</b>
                  <span>Cancelled</span>
                </div>
              </li>
            </ul>

            <div className="image_wrap">
              {/* <img src={reportImg} alt="" /> */}

              <AreaChart
                seriesName={"Report Chart"}
                dataArray={reportChartArray}
              />
            </div>
          </div>
          <div className="chart_right">
            <div className="image_wrap">
              {/* <img src={reportImg2} alt="" /> */}
              <PaiChart dataArray={orderChartArray} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHomeUser;
