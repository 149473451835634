import {
  Button,
  LoadingButton,
  RatingStar,
  Select,
  TextArea,
} from "components/form";
import React from "react";

const OrderReview = (props) => {
  //**************************** Handle HTML CODE ***************************//
  return (
    <>
      <div className="order_review _card">
        <h4>Write a Review</h4>
        <p>
          Rate your purchases and tell us what you thought about our service
        </p>

        <div className="form_field">
          <label className="label">
            <b>My Rate ({props.ratingInitial}/5)</b>
            <RatingStar
              initialRating={props?.ratingInitial}
              onChange={(rate) => props?.onChangeHandle(rate, "Rating")}
            />
          </label>
          <Select
            name="reviewType"
            onChange={props?.onChangeHandle}
            value={props?.reviewField?.reviewType}
          >
            <option value={""}>Select review type</option>
            {props?.reviewTypeOptions?.length > 0 &&
              props?.reviewTypeOptions?.map((el) => (
                <option key={el?.id} value={el?.id}>
                  {el?.name}
                </option>
              ))}
          </Select>
        </div>
        <div className="form_field">
          <label className="label">Describe your experience</label>
          <TextArea
            name="reviewDesc"
            placeholder="Write review"
            onChange={props?.onChangeHandle}
            value={props?.reviewField?.reviewDesc}
          />
        </div>
        <div className="button_wrap">
          <LoadingButton
            loading={props.loading}
            disabled={props.loading}
            onClick={props.onSubmitHandle}
            className={props.loading ? "disabled" : ""}
          >
            Submit
          </LoadingButton>
        </div>
      </div>
    </>
  );
};
export default OrderReview;
