import { Instance as axios, setToken } from "services/instance";

// get Invoice listing api
export const getInvoiceListAPI = (
    page = 1,
    search = "",
    status = "",
    fromDate = "",
    toDate = "",
    limit = 10,
    isSortApply = 0,
    sortColumnName = "",
    sortingOrder = "",
    column = "",
    operator = "",
    value = ""
  ) => {
    if (isSortApply === 1 || isSortApply === 2) {
      return axios.get(
        `api/user/invoice/list?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&sortByColumn=${sortColumnName}&sortBy=${sortingOrder}&column=${column}&operator=${operator}&value=${value}`,
        setToken()
      );
    } else {
      return axios.get(
        `api/user/invoice/list?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&column=${column}&operator=${operator}&value=${value}`,
        setToken()
      );
    }
  };


  // Export Invoice List API
export const exportInvoiceAPI = async (
    page,
    limit,
    search,
    fromDate,
    toDate,
    column,
    operator,
    value
  ) => {
    return axios.get(
      `api/user/invoice/export?page=${page}&limit=${limit}&search=${search}&fromDate=${fromDate}&toDate=${toDate}&column=${column}&operator=${operator}&value=${value}`,
      {
        ...setToken(), // Assuming setToken adds your auth headers
        responseType: "blob", // This is important to get the response as a blob
      }
    );
  };


  // Function for download the label file api
export const downloadOrderLabelAPI = async (id) => {
    return axios.get(`api/user/invoice/download?orderId=${id}`, setToken());
  };
  