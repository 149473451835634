import { Instance as axios, setToken } from "services/instance";

// get payment listing api
export const getPaymentListAPI = (
  page = 1,
  search = "",
  status = "",
  fromDate = "",
  toDate = "",
  limit = 10,
  isSortApply = 0,
  sortColumnName = "",
  sortingOrder = "",
  column = "",
  operator = "",
  value = ""
) => {
  if (isSortApply === 1 || isSortApply === 2) {
    return axios.get(
      `api/user/payment/list?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&sortByColumn=${sortColumnName}&sortBy=${sortingOrder}&column=${column}&operator=${operator}&value=${value}`,
      setToken()
    );
  } else {
    return axios.get(
      `api/user/payment/list?page=${page}&limit=${limit}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&column=${column}&operator=${operator}&value=${value}`,
      setToken()
    );
  }
};

// Export Payment List API
export const exportPaymentAPI = async () => {
  return axios.get(`api/user/payment/export`, {
    ...setToken(), // Assuming setToken adds your auth headers
    responseType: "blob", // This is important to get the response as a blob
  });
};

// Function for download the label file api
export const downloadPaymentLabelAPI = async (id) => {
  return axios.get(`api/user/payment/invoice?orderId=${id}`, setToken());
};
