import "../auth.scss";
import React, { useState } from "react";
import { lockIcon } from "../../../assets/icons";
import { authImg } from "../../../assets/images";
import usePasswordToggle from "../../../hooks/usePasswordToggle";
import { Button, Input, LoadingButton } from "../../../components/form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { resetPasswordAPI } from "../../../services/auth-controllers/authCont";
import { toast } from "react-toastify";

const ResetPassword = () => {
  //************************** Handle all Hooks ************************//
  const location = useLocation();
  const navigate = useNavigate();
  const { search: token } = location;

  //********************************* Handle all states *****************************//

  const [passwordType, showIcon] = usePasswordToggle();
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [resetPassFields, setResetPassFields] = useState({
    password: "",
    confirmPassword: "",
  });

  const [resetPassFieldsValidationError, setResetPassFieldsValidationError] =
    useState({
      password: "",
      confirmPassword: "",
    });

  const [resetPassExtraError, setResetPassExtraError] = useState({
    password: false,
    confirmPassword: false,
  });

  //************************ Handle all functions *********************//

  // onChangeHandler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setResetPassFields({ ...resetPassFields, [name]: value });
  };

  // Error Handler
  const errorHandler = () => {
    let errorField = {};
    let extraErrorFiled = {
      password: false,
      confirmPassword: false,
    };
    let formIsValid = true;
    let passwordFormat =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%*~^&+=!]).{8,}$/;

    if (!resetPassFields.password) {
      formIsValid = false;
      errorField["password"] = "Required *";
    } else if (!resetPassFields.password.match(passwordFormat)) {
      formIsValid = false;
      errorField["password"] =
        "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number, and 1 special character in (!@#$%^&*)";
      extraErrorFiled["password"] = true;
    }

    if (!resetPassFields?.confirmPassword) {
      formIsValid = false;
      errorField["confirmPassword"] = "Required *";
    } else if (resetPassFields.confirmPassword !== resetPassFields?.password) {
      formIsValid = false;
      errorField["confirmPassword"] =
        "Confirm password should be equal to password!";
      extraErrorFiled["confirmPassword"] = true;
    }

    setResetPassExtraError(extraErrorFiled);
    setResetPassFieldsValidationError(errorField);
    return formIsValid;
  };

  // onSubmitHandler
  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (errorHandler()) {
      try {
        setSubmitButtonLoading(true);
        const sendToken = token?.split("=")[1];
        const sendData = { password: resetPassFields?.password };
        const { data } = await resetPasswordAPI(sendToken, sendData);
        if (data.code === 200) {
          navigate("/");
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitButtonLoading(false);
      }
    }
  };

  //***************************** Handle HTML CODE ****************************//
  return (
    <>
      <div className="resetPassword">
        <div className="auth login">
          <div className="image_Wrap">
            <img src={authImg} alt="" />
          </div>
          <div className="form_wrap">
            <h3>Create New Password</h3>
            <p>Enter the pin code ro secure your password</p>
            <form>
              <div className="form_field">
                <Input
                  name="password"
                  type={passwordType}
                  placeholder="New Password"
                  onChange={onChangeHandler}
                  value={resetPassFields?.password}
                  className="form-control input password"
                  extraError={resetPassExtraError?.password}
                  error={resetPassFieldsValidationError?.password}
                />

                <img src={lockIcon} alt="" className="icon" />
                <span className="password_icons">{showIcon}</span>
              </div>

              <div className="form_field">
                <Input
                  type={passwordType}
                  name="confirmPassword"
                  onChange={onChangeHandler}
                  placeholder="Confirm New Password"
                  className="form-control input password"
                  value={resetPassFields?.confirmPassword}
                  extraError={resetPassExtraError?.confirmPassword}
                  error={resetPassFieldsValidationError?.confirmPassword}
                />

                <img src={lockIcon} alt="" className="icon" />
                <span className="password_icons">{showIcon}</span>
              </div>
              <div className="button_wrap">
                {submitButtonLoading ? (
                  <LoadingButton
                    disabled
                    className={"disabled"}
                    loading={submitButtonLoading}
                  />
                ) : (
                  <Button className={"w-100"} onClick={onSubmitHandler}>
                    Set Password
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
