import React, { useContext, useEffect, useState } from "react";
import {
  avatarIcon,
  bellIcon,
  pLogoutIcon,
  pProfileIcon,
  pSwitchIcon,
  walletIcon,
} from "../../../assets/icons";
import { Dropdown } from "react-bootstrap";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Button, Input, LoadingButton } from "../../../components/form";
import { redirect, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteModal from "../../../components/modals/DeleteModal";
import { encryptDataHandler, getDataToLocal } from "../../../config/utils";
import moment from "moment";
import { GlobalUserContext } from "../../../context/GlobalContext";
import CustomDrawer from "components/drawer/Drawer";
import NotificationList from "./NotificationList";
import {
  getGateWayIdAPI,
  getWalletDetailsAPI,
  switchToVendorAPI,
} from "services/user-controllers/masterController";
import RazorPayPayment from "../pages/bookOrders/razorpay-payment/RazorPayPayment";
import CustomModal from "components/modals/CustomModal";
import { useRazorpay } from "react-razorpay";
import { GlobalOrderContext } from "context/OrderContext";
import { IoReorderThreeOutline } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";

const UserHeader = () => {
  const {
    setOrderWalletToggle,
    orderWalletToggle,
    isLoginModalOpen,
    setIsLoginModalOpen,
  } = useContext(GlobalUserContext);

  const reDirect = useNavigate();
  const [userData, setUserData] = useState({});
  const [dataToLocal, setDataToLocal] = useState({});
  const [logoutModal, setLogoutModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [notificationDrawer, setNotificationDrawer] = useState(false);

  const [walletMoney, setWalletMoney] = useState(0);
  const [addMoneyAmount, setAddMoneyAmount] = useState();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [toggleSwitch, setToggleSwitch] = useState(false);

  const [razorPayDetailsForWallet, setRazorPayDetailsForWallet] = useState({
    gatewayOrderId: "",
    amount: 0,
    currency: "",
  });
  const [addMoneyButtonLoading, setAddMoneyButtonLoading] = useState(false);

  const [isSwitchToVendorLoading, setIsSwitchToVendorLoading] = useState(false);

  // Handler hooks

  const { error, isLoading, Razorpay } = useRazorpay();
  const { userProfileData, setUserprofileData } = useContext(GlobalUserContext);

  //***************************** Handle ALL Function ************************//

  const logoutHandler = () => {
    try {
      setButtonLoading(false);
      localStorage.clear();
      toast.success("Logged Out successfully!");
      reDirect("/");
    } catch (err) {
      console.error(err);
    } finally {
      setButtonLoading(false);
    }
  };

  // Get Wallet details Handler
  const getWalletDetailsHandler = async () => {
    try {
      const { data } = await getWalletDetailsAPI();
      if (data?.code === 200) {
        setWalletMoney(Number(data?.data?.availableWalletAmount));
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const displayRazorpay = async (data) => {
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY,
      amount: data?.amount,
      currency: data?.currency,
      name: "Test Company",
      description: "Test Transaction",
      order_id: data?.gatewayOrderId,

      prefill: {
        name: "John Doe",
        email: "john.doe@example.com",
        contact: "9999999999",
        method: "card|upi|wallet", //card|upi|wallet
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#F37254",
      },

      handler: function (response) {
        // Hit the API to create the order in the backend
        setAddMoneyButtonLoading(false);
        setIsPaymentModalOpen(false);
        setToggleSwitch(!toggleSwitch);
        toast.success("Money added successfully !");
      },
    };

    const razorpayInstance = new Razorpay(options);
    razorpayInstance.open();
  };

  // get gateWayOrderIdForRazorPayHandler
  const getGatewayOrderIdHandler = async () => {
    try {
      if (!addMoneyAmount) {
        toast.error("Please Enter Amount*");
        return;
      } else {
        setAddMoneyButtonLoading(true);
        const { data } = await getGateWayIdAPI({ totalAmount: addMoneyAmount });
        if (data?.code === 200) {
          setRazorPayDetailsForWallet({
            gatewayOrderId: data?.data?.gatewayOrderId,
            amount: data?.data?.amount,
            currency: data?.data?.currency,
          });
          if (data?.data?.gatewayOrderId?.length) {
            await displayRazorpay(data?.data);
          }
        } else {
          toast.error(data?.message);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // function for get user data from Local storage
  const getUserDetails = () => {
    const data = getDataToLocal("authDetail");
    setUserprofileData(data?.userDetails);
    setUserData(data?.userDetails);
  };

  useEffect(() => {
    getUserDetails();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getWalletDetailsHandler();

    // eslint-disable-next-line
  }, [toggleSwitch, orderWalletToggle]);

  // Function for handle switch to vendor
  const switchToVendorHandler = async (e, callType) => {
    console.log("I am Call Type", callType);
    e.preventDefault();
    setIsSwitchToVendorLoading(true);
    try {
      const sendData = {
        userType: callType,
      };
      const { data } = await switchToVendorAPI(sendData);
      console.log("SWITCH RESPONSE", data);
      if (data.code === 200) {
        localStorage.clear();

        // if (loginMethod === "email") {
        const dataForLocal = {
          token: data?.data?.token,
          personType: {
            isUser: data?.data?.user?.isUser,
            isVendor: data?.data?.user?.isVender,
          },
          userDetails: {
            userId: data?.data?.user?.id,
            name: data?.data?.user?.name,
            email: data?.data?.user?.email,
            lastLogin: data?.data?.user?.lastLoginAt,
          },
        };
        if (!data?.data?.user?.isVendor) {
          setDataToLocal("authDetail", dataForLocal);
        } else {
          setDataToLocal("vendorAuthDetail", dataForLocal);
        }

        if (!data?.data?.user?.isVendor) {
          reDirect(`/user/dashboard/home`);
          toast.success(data?.message);
        } else {
          //------------ Code For Local ------------//
          alert("HELLO I AM HIT From VENDOR");

          const encryptedData = encodeURIComponent(
            encryptDataHandler(dataForLocal)
          );

          window.open(
            `${process.env.REACT_APP_PROVIDER_URL}home?home=${encryptedData}`
          );

          // window.open(
          //   `${process.env.REACT_APP_PROVIDER_URL}home?home=${encryptedData}`,
          //   "_blank"
          // );

          //------------ Code For Build ------------//
          // window.open(`${process.env.REACT_APP_PROVIDER_URL}home`, "_blank");
          toast.success(data?.message);
          window.location.reload();
        }
        // } else {
        //   // setIsOTP(true);
        //   toast.success(data.message, { id: "signUp" });
        // }
      } else {
        toast.error(data.message, { id: "signUp" });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsSwitchToVendorLoading(false);
    }

    // setDataToLocal("vendorAuthDetail", dataForLocal);
  };

  //*************************** Handle HTML CODE ***********************//

  const navigation = () => {
    document.body.classList.add("innerside_toogle");
  };
  const navigations = () => {
    document.body.classList.remove("innerside_toogle");
  };
  return (
    <>
      <div className="header_content">
        <div className="wrap_toogle">
          <IoReorderThreeOutline className="three_line" onClick={navigation} />
          <RxCross2 className="cross_icon" onClick={navigations} />
          <h4 className="show_mobile">
            {userProfileData?.name?.length > 8
              ? `${userProfileData?.name.substr(0, 7)}..`
              : userProfileData?.name || "-------"}
          </h4>
          <h4 className="show_desktop">{userProfileData?.name || "-------"}</h4>
        </div>

        <div className="right_content">
          <p className="time_status">
            Your last login 
            {moment(userProfileData?.lastLogin).format("ddd MMM DD,YYYY")}{" "}
          </p>

          <Dropdown className={"customDropdown"}>
            <Dropdown.Toggle
              id="dropdown-basic"
              onClick={() => setNotificationDrawer(true)}
            >
              <img src={bellIcon} alt="" />
            </Dropdown.Toggle>

            {/* <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            </Dropdown.Menu> */}
          </Dropdown>
          <Dropdown className={"customDropdown wallet"}>
            <Dropdown.Toggle id="dropdown-basic">
              <img src={walletIcon} alt="" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <img src={walletIcon} alt="" />
              <p>Available Balance</p>
              <b>₹{walletMoney}</b>
              <Dropdown.Item as={"span"}>
                <Button
                  className={"has_icon"}
                  onClick={() => setIsPaymentModalOpen(true)}
                >
                  <IoMdAddCircleOutline />
                  ADD MONEY
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className={"customDropdown profile_dropdown"}>
            <Dropdown.Toggle id="dropdown-basic">
              <img src={avatarIcon} alt="" />
              <p>
                <b>{userProfileData?.name || "-----"}</b> <span>User</span>
              </p>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={"span"}
                onClick={() => reDirect(`/user/dashboard/settings`)}
              >
                <img src={pProfileIcon} alt="" /> Edit Profile
              </Dropdown.Item>
              <Dropdown.Item
                as={"span"}
                // onClick={(e) => {
                //   switchToVendorHandler(e, "vendor");
                // }}
              >
                <img src={pSwitchIcon} alt="" /> Switch to Vendor
              </Dropdown.Item>
              <Dropdown.Item as={"span"} onClick={() => setLogoutModal(true)}>
                <img src={pLogoutIcon} alt="" />
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {/*----- payment modal -----*/}
        <CustomModal
          show={isPaymentModalOpen}
          className={"payLaterModal"}
          handleClose={() => setIsPaymentModalOpen(false)}
        >
          <>
            <h4>Enter Money For Wallet</h4>
            <div className="form_field">
              <Input
                type="number"
                value={addMoneyAmount}
                Placeholder="Enter Amount*"
                onChange={(e) => setAddMoneyAmount(e.target.value)}
              />
            </div>

            <div className="button_wrap">
              <LoadingButton
                loading={addMoneyButtonLoading}
                disabled={addMoneyButtonLoading}
                onClick={getGatewayOrderIdHandler}
                className={addMoneyButtonLoading ? "w-100 disabled" : "w-100"}
              >
                Pay Now
              </LoadingButton>
            </div>
          </>
        </CustomModal>

        {/* Logout Modal */}
        <DeleteModal
          className={"logOutModal"}
          type="logout"
          show={logoutModal}
          handleSubmit={logoutHandler}
          buttonLoading={buttonLoading}
          handleClose={() => setLogoutModal(false)}
          content={"Are you sure you want to logout ?"}
        />
      </div>

      {/* Notification Drawer */}

      <CustomDrawer
        direction={"end"}
        show={notificationDrawer}
        className={"notification_list"}
        handleClose={() => setNotificationDrawer(false)}
      >
        <NotificationList />
      </CustomDrawer>
    </>
  );
};
export default UserHeader;
